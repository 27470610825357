export class ControlANSI{


    constructor(
        public id: number,
        public reference: string,
        public type: string,
        public title: string,
        public description: string
    ){}
}
