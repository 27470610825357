import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secincident',
  templateUrl: './secincident.component.html',
  styleUrls: ['./secincident.component.scss']
})
export class SecincidentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
