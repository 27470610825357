import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset } from 'src/app/_models/asset';
import { Audit } from 'src/app/_models/audit';
import { ControlANSI } from 'src/app/_models/controlANSI';
import { Risk } from 'src/app/_models/risk';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalService } from 'src/app/_services/local.service';
import {RiskService} from '../../../../_services/risque-management-services/risk.service';
import {AssignControlService} from '../../../../_services/risque-management-services/assign-control.service';
import {AuditService} from '../../../../_services/audit.service';

@Component({
  selector: 'app-assign-control',
  templateUrl: './assign-control.component.html',
  styleUrls: ['./assign-control.component.scss']
})
export class AssignControlComponent implements OnInit {
  controls: boolean = true;
  custodian: string;
  controlList: Array<ControlANSI> = [];
  // testvariable: ControlANSI;
  type: string = "";
  dateToday: number = Date.now();
  assignControlByType: ControlANSI[];
  constructor(
    public auditService: AuditService,
    public adminService: AdminService,
    public assignControlService: AssignControlService,
    private datePipe: DatePipe,
    private riskService: RiskService,
    private localStorage: LocalService,
    public dialogRef: MatDialogRef<AssignControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      listOfApplicableAssets: Array<Asset>,
      riskName: string,
      level: string,
      impactXlikelihood: number,
    },
  ) {}

  ngOnInit(): void {
    this.getAllAnsiControls();
  }

  getAllAnsiControls() {
    this.assignControlService.getANSIControls().subscribe(data => {
      this.assignControlByType = data;
    });
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onSubmite() {
    for (const iterator of this.controlList) {
      for (let index = 0; index < this.data.listOfApplicableAssets.length; index++) {
        const nameOfApplicableAsset = this.data.listOfApplicableAssets[index]['name'];
        const idNumber = this.randomNumber(5, 1000);
        const risk = new Risk("Risk-" + idNumber.toString(), nameOfApplicableAsset, this.data.riskName, "ownerOfApplicableAsset", this.data.impactXlikelihood, this.data.level, iterator['title'], iterator['description'], "", "", "Evaluated", 0, this.datePipe.transform(this.dateToday, "MMM d, y, h:mm:ss a"), this.localStorage.getData("id"), "idUser", "idOrg");
        this.riskService.addRisk(risk).subscribe(data => {
          console.log("risk-list:");
          console.log(data);
        });
        // this.adminService.risks.push(risk);
        const audit = new Audit("Risk-" + idNumber.toString(), 'On Going', this.data.riskName, iterator['reference'], iterator['title'], iterator['description'], "N/A", null, this.data.impactXlikelihood.toString(), null, this.datePipe.transform(this.dateToday, "MMM d, y, h:mm:ss a"), null, null, this.localStorage.getData("id"), "idUser", "idOrg");
        this.auditService.addAudit(audit).subscribe(data => {
          console.log("audit");
          console.log(data);
        });
      }

    }
    this.dialogRef.close();
  }
  randomNumber(min, max) {
    return Number(Math.random() * (max - min) + min) | 0;
  }

  addtest(ref: string, type: string, title: string, description: string) {
    const cntrl = new ControlANSI(0, ref, type, title, description);
    this.controlList.push(cntrl);
  }
  activeOrganisationalControls() {
    this.type = 'organisationalControls';
    this.assignControlService.getANSIControlsByType(this.type).subscribe( data => {
      this.assignControlByType = data;
    });
  }
  activeTechnicalControls() {
    this.type = 'technicalControls';
    this.assignControlService.getANSIControlsByType(this.type).subscribe(data => {
      this.assignControlByType = data;
    });
  }
  activePhysicalControls() {
    this.type = 'physicalControls';
    this.assignControlService.getANSIControlsByType(this.type).subscribe(data => {
      this.assignControlByType = data;
    });
  }

}
