<div class="container-fluid page">
  <div class="d-flex page__box p-4 mt-1" i18n>ISMS Scope and boundaries</div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header" style="background-color: #06375e;">
        <h4 i18n>Scope & Boundaries</h4>
        <p i18n>This section simply described the scope and boundaries of your program.</p>
      </div>
      <div class="card-body">

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                  <form class="d-flex">
                    <input class="form-control mt-0 me-2 " type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success mt-0 " type="submit" i18n>Search</button>
                    <span><button class="btn btn-outline-primary mt-0" i18n>Add item</button></span>
                  </form>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table mat-elevation-z8 table-spacing">
                        <thead>
                          <tr>
                            <th i18n>Presentation of organization</th>
                            <th i18n>Organization chart</th>
                            <th i18n>ISMS Objectives</th>
                            <th i18n>ISMS Scope</th>
                            <th i18n>Certification Scope</th>
                            <th i18n>Included entities of the ISMS scope</th>
                            <th i18n>Interested parties</th>
                            <th i18n>Legal requirements</th>
                            <th i18n>Status</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>org</td>
                            <td>...</td>
                            <td>objectives</td>
                            <td>scope</td>
                            <td>User</td>
                            <td>org</td>
                            <td>...</td>
                            <td>scope</td>
                            <td>Current</td>
                          </tr>
                          <tr>
                            <td>org</td>
                            <td>...</td>
                            <td>objectives</td>
                            <td>scope</td>
                            <td>User</td>
                            <td>org</td>
                            <td>...</td>
                            <td>scope</td>
                            <td>Draft</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
