import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService implements CanActivate {
    constructor(private jwtHelper: JwtHelperService, private router: Router) {
    }
    canActivate() {
        const token = localStorage.getItem("jwt");

        if (token && !this.jwtHelper.isTokenExpired(token)) {
            console.log("is Token expired?   " + this.jwtHelper.isTokenExpired(token))
            return true;
        }
        this.router.navigate(["login"]);
        return false;
    }

    
}