import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/_globals/Globals';
import { User } from 'src/app/_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = Globals.apiUrl;
  constructor(private http: HttpClient) { }

  addUser(user: User): Observable<any> {
    return this.http.post(this.url + '/User/', user)
  }

  delUser(id: number): Observable<any> {
    return this.http.delete(this.url + '/User/' + id);
  }

  updateUser(user: any): Observable<any> {
    return this.http.put(this.url + '/User/' + user.id, user);
  }

  getAllUsers(idAdmin: string): Observable<any>  {
    return this.http.get<User[]>(this.url + '/User');
  }

  getInfoUserById(id: any) {
    return this.http.get<User[]>(this.url + '/User/' + id);
  }

  verifyHashedPassword(userId: any, password: any): Observable<boolean> {
    const url = `${this.url}/User/verifyPassword?userId=${userId}&password=${password}`;
    return this.http.get<boolean>(url);
  }

  verifyIsEmailExisting(email: any) {
    const url = `${this.url}/User/validate-email?email=${email}`;
    return this.http.get<boolean>(url);
  }

  changePassword(userId: any, newPassword: string): Observable<any> {
    const requestBody = { userId, newPassword };
    return this.http.put<any>(`${this.url}/User/changePassword`, requestBody);
  }
  //TODO: How to overload in TS?
  changePasswordWithoutId(newPassword: string): Observable<any> {
    const requestBody = { newPassword };
    return this.http.put<any>(`${this.url}/User/changePassword`, requestBody);
  }
  getOnlineUsers() {
    return this.http.get<User[]>(this.url + '/User/' + 'getOnlineUsers');
  }
}
