<div class="container-fluid page">
  <div class="d-flex page__box p-4 mt-1" i18n>{{'Applicative Log'|translate}}</div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 i18n>List of applicative Log</h4>
        <p i18n>This section is used to view the navigation logs of all users in the organization.</p>
      </div>
      <div class="card-body">
        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-danger">
                  </div>

                  <form class="d-flex">
                    <input class="form-control mt-0 me-2 " type="search" placeholder="{{'Search'|translate}}"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="searchTerm" (input)="filterItems()"
                    />
                  </form>

                  <div class="card-body">
                    <div class="table-responsive">
                      <div class="main-content">
                        <table class="table mat-elevation-z8 table-spacing" mat-table [dataSource]="dataSource">
                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef>Id</th>
                            <td mat-cell *matCellDef="let item">{{ item.id }}</td>
                          </ng-container>
                          <ng-container matColumnDef="username">
                            <th mat-header-cell *matHeaderCellDef>Email</th>
                            <td mat-cell *matCellDef="let item">{{ item.username }}</td>
                          </ng-container>
                          <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let item">{{ item.action }}</td>
                          </ng-container>
                          <ng-container matColumnDef="timestamp">
                            <th mat-header-cell *matHeaderCellDef>Date</th>
                            <td mat-cell *matCellDef="let item">{{ item.timestamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <!-- Paginator -->
                        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
