<mat-nav-list>
  <div class="logo py-4">
    <img src="../../../assets/logo.png" alt="Company Logo" />
  </div>
  <mat-divider></mat-divider>
  <h2 matSubheader class="mt-2" style="color: white;font-family: Verdana, Geneva, Tahoma, sans-serif;">Trust Bulletin
  </h2>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/home" (click)="activate()">
    <mat-icon>home</mat-icon> Users

  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
    <mat-icon>dashboard</mat-icon> Dashboard
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/myorganization">
    <mat-icon>edit</mat-icon> My organizations<span>
      <!-- <mat-icon class="icon1">keyboard_arrow_down</mat-icon> -->
    </span>
  </a>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/organization-context">
    <mat-icon>edit</mat-icon> Organization Context<span>
      <!-- <mat-icon class="icon1" style="align-self:flex-end;">keyboard_arrow_down</mat-icon> -->
    </span>
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/isms">
    <mat-icon>edit</mat-icon> ISMS Scope and Boundaries
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/gapAnalysis">
    <mat-icon>edit</mat-icon> GAP Analysis
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/inventory-of-assets">
    <mat-icon>edit</mat-icon> Inventory of assets
  </a>

  <a mat-list-item> Risk assessment & Risk treatment </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/riskAssessment">
    <mat-icon>edit</mat-icon> Asset Risks
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/reviews">
    <mat-icon>edit</mat-icon> Reviews
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/controls">
    <mat-icon>edit</mat-icon> Controls
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/audits">
    <mat-icon>edit</mat-icon> Audits
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/issues">
    <mat-icon>edit</mat-icon> Issues
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/maintenance">
    <mat-icon>edit</mat-icon> Maintenance
  </a>


  <a mat-list-item routerLinkActive="list-item-active" routerLink="/SoA">
    <mat-icon>edit</mat-icon> SoA
  </a>
  <a mat-list-item>Security Operations</a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/securityIncident">
    <mat-icon>edit</mat-icon> Security incident
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/vulnerabilityMangement">
    <mat-icon>edit</mat-icon> Vulnerability Mangement
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/internalAudit">
    <mat-icon>edit</mat-icon> Internal Audit
  </a>

  <a mat-list-item (click)="activate()">
    <mat-icon>edit</mat-icon> Link 4
    <span>
      <mat-icon class="icon1" *ngIf="test;else second">keyboard_arrow_right</mat-icon>
    </span>
    <ng-template #second>
      <span>
        <mat-icon class="icon1" >keyboard_arrow_down</mat-icon>
      </span>  </ng-template>
  </a>
  <a mat-list-item *ngIf="test">
    <mat-icon>edit</mat-icon> Link 5
  </a>
  <a mat-list-item *ngIf="test">
    <mat-icon>edit</mat-icon> Link 6
  </a>
</mat-nav-list>
