import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LogService} from '../_services/administration-services/log.service';;

@Injectable({
  providedIn: 'root',
})
export class LogGuard implements CanActivate {
  constructor(private pileLogService: LogService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check conditions for logging (e.g., user is logged in, specific route, etc.)
    const shouldLog = true; // Replace with your condition
    const user: any = JSON.parse(localStorage.getItem('user'));
    if (shouldLog) {
      const userName = user.email; // Replace with actual user ID
      const action = `Navigated to ${state.url}`;
      this.pileLogService.logAction(userName, action);
    }

    // Allow navigation
    return true;
  }
}
