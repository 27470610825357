<h2 i18n>Risk Treatment</h2>
<ul class="nav nav-tabs nav-fill">
  <li class="nav-item" (click)="setActiveTab('Risk Identification')">
    <button class="nav-link active" type="button" aria-selected="true" [class.active]="activeTab === 'Risk Identification'">
      <span [ngStyle]="{'color': activeTab === 'Risk Identification' ? 'blue' : 'black'}" i18n>Risk Identification</span>
    </button>
  </li>
  <li class="nav-item" (click)="setActiveTab('Inherent Risk')">
    <button class="nav-link active" type="button" aria-selected="true" [disabled]="disabledTab2" [class.active]="activeTab === 'Inherent Risk'">
      <span [ngStyle]="{'color': activeTab === 'Inherent Risk' ? 'blue' : 'black'}" i18n>Inherent Risk</span>
    </button>
  </li>
  <li class="nav-item" (click)="setActiveTab('Risk Treatment')">
    <button class="nav-link active" type="button" aria-selected="true" [disabled]="disabledTab3" [class.active]="activeTab === 'Risk Treatment'">
      <span [ngStyle]="{'color': activeTab === 'Risk Treatment' ? 'blue' : 'black'}" i18n>Risk Treatment</span>
    </button>
  </li>
</ul>


<div *ngIf="activeTab === 'Risk Identification'">

  <div class="center">
    <div class="form-check-inline ">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="showThreats('confidentiality')" checked>
      <label class="form-check-label" for="flexRadioDefault1" i18n>
        Confidentiality
      </label>
    </div>
    <div class="form-check-inline">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (change)="showThreats('integrity')">
      <label class="form-check-label" for="flexRadioDefault2" i18n>
        Integrity
      </label>
    </div>
    <div class="form-check-inline">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" (click)="showThreats('availability')">
      <label class="form-check-label" for="flexRadioDefault3" i18n>
        Availability
      </label>
    </div>
  </div>
  <div>
  </div>

  <div class="scroll">
    <table class="table ">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" i18n>Threats</th>
          <th scope="col" i18n>Vulnerabilities</th>
          <th scope="col" i18n>Incident Scenario</th>
        </tr>
      </thead>
      <tbody *ngIf="threats === 'confidentiality'">
        <tr *ngFor="let incident of this.incidents">
          <td scope="row">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [checked]="incident.checked" (change)="addToList($event, incident)">
            </div>
          </td>
          <td>{{incident.threat}}</td>
          <td>{{incident.vulnerability}}</td>
          <td>{{incident.incidentScenario}}</td>
        </tr>

      </tbody>
      <tbody *ngIf="threats=== 'integrity'">
        <tr *ngFor="let incident of this.incidents">
          <td scope="row">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [checked]="incident.checked" (change)="addToList($event,incident)" value="" id="flexCheckDefault">
            </div>
          </td>
          <td>integrity threats (not completed)</td>
          <td>{{incident.vulnerability}}</td>
          <td>{{incident.incidentScenario}}</td>
        </tr>

      </tbody>
      <tbody *ngIf="threats=== 'availability'">
        <tr *ngFor="let incident of this.incidents">
          <td scope="row">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [checked]="incident.checked" (change)="addToList($event,incident)" value="" id="flexCheckDefault">
            </div>
          </td>
          <td>availability threats (not completed)</td>
          <td>{{incident.vulnerability}}</td>
          <td>{{incident.incidentScenario}}</td>
        </tr>

      </tbody>
    </table>
  </div>


  <div *ngIf="listIsEmpty">
    <app-error-alert [errorMessage]="currentMessage"> </app-error-alert>
  </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-bs-dismiss="modal" i18n="close|close">Close</button>
        <button type="button" class="btn btn-primary" (click)="onNext2()" i18n="next">Next</button>
      </div>

</div>
<div *ngIf="activeTab === 'Inherent Risk'">


    <div>

      <table class="styled-table">
        <thead>
          <tr>
            <th scope="col" i18n>Threats</th>
            <th scope="col" i18n>Vulnerabilities</th>
            <th scope="col" i18n>Incident Scenario</th>
            <th scope="col" style="white-space: nowrap;">
              <span i18n>Likelihood</span>
              <i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="help" (click)="dialogLikelihood()"></i>
            </th>
            <th scope="col" style="white-space: nowrap;">
              <span i18n>Impact</span>
              <i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="help" (click)="dialogImpact()"></i>
            </th>
            <th i18n>Level</th>
          </tr>
        </thead>
        <tbody>
          <tr  *ngFor="let incident of levelList">
            <td>{{incident.threat}}</td>
            <td>{{incident.vulnerability}}</td>
            <td>{{incident.incidentScenario}}</td>
            <td><div class="col-auto">
              <select class="form-select" aria-label="Default select example" (change)="onSelect('probabilty', $event.target.value)" id="inputGroupSelect01">
                <option *ngFor="let option of generateOptionsImpactAndProba(matrixSize)" [value]="option">{{ option }}</option>
              </select>
            </div>
            </td>
            <td>
              <div class="col-auto">
                <select class="form-select" aria-label="Default select example" (change)="onSelect('impact', $event.target.value)" id="inputGroupSelect01">
                  <option value=0 disabled selected hidden> ..</option>
                  <option *ngFor="let option of generateOptionsImpactAndProba(matrixSize)" [value]="option">{{ option }}</option>

                </select>
              </div>
            </td>
            <div *ngIf="result">
              <p style="margin-block-start: 16px" class="{{ getBadgeClass() }}">{{ result }}</p>
            </div>
          </tr>
        </tbody>
      </table>
      <div>
      </div>
  </div>

  <div *ngIf="valueNotCompleted">
    <app-error-alert [errorMessage]="currentMessage"> </app-error-alert>
  </div>

    <div class="modal-footer" >
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="riskLevel(this.likelihoodValue,this.impactValue)">Test</button>
        <button type="button" class="btn btn-primary" (click)="onNext1()" i18n="previous">Previous</button>
        <button type="button" class="btn btn-primary" (click)="onNext3()" i18n="next">Next</button>
      </div>
</div>


<div *ngIf="activeTab === 'Risk Treatment'">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" i18n>Risk</th>
        <th scope="col" i18n>Level</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody >
      <tr *ngFor="let incident of levelList">
        <td scope="row">{{incident.incidentScenario}}</td>
        <td scope="row">
          <span class="badge bg-warning">{{incident.param3}}</span>
        </td>
        <td>
          <button type="button" class="btn btn-info"
                  (click)="dialogControl(incident.incidentScenario,result,likelihoodValue,impactValue)"
                  i18n>Assign Control
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"  (click)= "closeModal()"  i18n="close|close">Close</button>
    <button type="button" class="btn btn-primary" (click)="onNext2()" i18n="close|close">Previous</button>
    <button type="button" class="btn btn-primary"  (click)= "onSubmit()" i18n="submit" >Submit</button>
</div>
</div>
