<form>
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="validationServer01">Name</label>
        <input type="text" class="form-control is-valid" id="validationServer01" placeholder="Username"  required>
        
        
      </div>
      <div class="col-md-6 mb-3">
        <label for="validationServer02">Password</label>
        <input type="text" class="form-control is-valid" id="validationServer02" placeholder="Password" value="Otto" required>
       
      </div>
      <div class="col-md-10 mb-3">
        <label for="validationServerUsername">Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupPrepend3">@</span>
          </div>
          <input type="text" class="form-control is-invalid" id="validationServerUsername" placeholder="Email" aria-describedby="inputGroupPrepend3" required>
          <div class="invalid-feedback">
            Please put your email.
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="validationServer03">Country</label>
        <input type="text" class="form-control is-invalid" id="validationServer03" placeholder="Country" required>
        <div class="invalid-feedback">
          Please provide a valid city.
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="validationServer04">State</label>
        <input type="text" class="form-control is-invalid" id="validationServer04" placeholder="State" required>
        <div class="invalid-feedback">
          Please provide a valid state.
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <label for="validationServer05">Zip</label>
        <input type="text" class="form-control is-invalid" id="validationServer05" placeholder="Zip" required>
        <div class="invalid-feedback">
          Please provide a valid zip.
        </div>
        
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input is-invalid" type="checkbox" value="" id="invalidCheck3" required>
        <label class="form-check-label" for="invalidCheck3">
          Agree to terms and conditions
        </label>
        <div class="invalid-feedback">
          You must agree before submitting.
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">Submit form</button>
  </form>


  