import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {Document} from '../../_models/document';
import {Paragraph} from '../../_models/paragraph';

@Injectable({
  providedIn: 'root'
})
export class ParagraphService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient) {
  }


  addParagraph(paragraph: Paragraph[]): Observable<any> {
    return this.http.post(Globals.apiUrl + '/Paragraph', paragraph);
  }
  delParagraph(id: number) {
    return this.http.delete(Globals.apiUrl + '/Paragraph' + '/' + id);
  }
  updateParagraph(idDocument, paragraph: any): Observable<any> {
    return this.http.put(Globals.apiUrl + '/Paragraph' + '/' + idDocument, paragraph);
  }

  getAllParagraphs() {
    return this.http.get<Paragraph[]>(Globals.apiUrl + '/Paragraph');

  }
  getParagraphOfDocument(idDocument: number) {
    return this.http.get<Paragraph[]>(Globals.apiUrl + '/Paragraph' + '/GetParagraphOfDocument?id_document=' + idDocument);

  }

  deleteParagraphWithDocumentId(idDocument: number) {
    return this.http.delete(Globals.apiUrl + '/Paragraph' + '/DeleteParagraphsByDocumentId?id_document=' + idDocument);
  }
}
