<div class="container-fluid page">
  <div class="d-flex page__box p-4 mt-1" i18n>Risk Assessment & Treatment</div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header" style="background-color: #06375e;">
        <h4 i18n>Asset Risks</h4>
        <p i18n>Manage Asset based Risks lifecycle.</p>
      </div>
      <div class="card-body">

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                  <form class="d-flex">
                    <input class="form-control mt-0 me-2 " type="search" placeholder="{{'Search'|translate}}"
                           [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="searchTerm" (input)="filterItems()"
                    />
                    <button class="btn btn-outline-success mt-0 " type="submit">Search</button>
                    <ng-container *ngIf="canAdd()">
                      <span><a class="btn btn-outline-primary mt-0" (click)="openDialog()">Add risk</a></span>
                    </ng-container>
                  </form>
                  <div class="card-body">
                    <div class="table-responsive scroll">
                      <table class="table mat-elevation-z8 table-spacing">
                        <thead>
                          <tr>
                            <th i18n>Status</th>
                            <th i18n>Reviews</th>
                            <th i18n>Review Date</th>
                            <th i18n>Applicable Asset</th>
                            <th i18n>Risk</th>
                            <th i18n>Risk Owner</th>
                            <th i18n>level</th>
                            <th i18n>Control Title</th>
                            <th class="largeTH" i18n>Control Description</th>
                            <th i18n>Residual Risk</th>
                            <th i18n>Action Plan</th>
                            <ng-container *ngIf="canEdit()">
                              <th i18n>Actions</th>
                            </ng-container>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let risk of risk">
                            <td>
                              <div *ngIf="risk.status=='Treated';else current">
                                <span class="badge bg-success">{{risk.status}}</span>
                              </div>
                              <ng-template #current>
                                <span class="badge bg-danger">{{risk.status}}</span>
                              </ng-template>
                            </td>
                            <td>{{risk.reviews}}</td>
                            <td><span class="badge bg-secondary">{{risk.reviewDate}}</span></td>
                            <td>{{risk.applicableAsset}}</td>
                            <td><span>Malicious code</span></td>
                            <td>Owner(To complete)</td>
                            <td>{{risk.level}}</td>
                            <td>{{risk.controlTitle}}</td>
                            <td style="width: 200px;">{{risk.controlDescription}}</td>
                            <td>
                              <div *ngIf="risk.risidualRisk=='';else pending">
                                <span class="badge rounded-pill bg-warning text-dark">Pending</span>
                              </div>
                              <ng-template #pending><span
                                  class="badge rounded-pill bg-info text-dark">{{risk.risidualRisk}}</span>
                              </ng-template>
                            </td>
                            <td>{{risk.actionPlan}}</td>
                            <td>
                              <ng-container *ngIf="canEdit()">
                              <mat-icon class="edit-icon">edit</mat-icon>
                              <mat-icon class="delete-icon">delete</mat-icon>
                            </ng-container>
                            </td>
                          </tr>


                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
