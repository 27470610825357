import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Audit} from '../_models/audit';
import {Risk} from '../_models/risk';
import {Globals} from '../_globals/Globals';
import {GapAnalysis} from '../_models/gapanalysis';

@Injectable({
  providedIn: 'root'
})
export class GapAnalysisService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient) {
  }

  getGapAnalysisByReference(reference: string) {
    return this.http.get<GapAnalysis[]>(Globals.apiUrl + '/GapAnalysis' + '/' + reference);
  }

  getGapAnalysis() {
    return this.http.get(Globals.apiUrl + '/GapAnalysis');
  }

  addGapAnalysis(gapAnalysis: any) {
    return this.http.post(Globals.apiUrl + '/GapAnalysis', gapAnalysis);
  }

  updateGapAnalysis(gapAnalysis: any) {
    return this.http.put(Globals.apiUrl + '/GapAnalysis' + '/' + gapAnalysis.id, gapAnalysis);
  }
}
