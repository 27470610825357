import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TestComponent } from './test/test.component';
import { AdminService } from './_services/admin.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorAlertComponent } from './Views/Helpers/error-alert/error-alert.component';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { JwtModule } from "@auth0/angular-jwt";
import { AuthService } from './_services/auth.service';
import { ValidationDialogComponent } from './Views/Helpers/validation-dialog/validation-dialog.component';
import { HeaderComponent } from './_shared/header/header.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { SidenavComponent } from './_shared/sidenav/sidenav.component';
import { ScopeandboundariesComponent } from './Views/ClientAdmin/scopeandboundaries/scopeandboundaries.component';
import { GapanalysisComponent } from './features/gapanalysis/gapanalysis.component';
import { RiskComponent } from './features/risque-management/risk-assement/risk-list/risk.component';
import { SoaComponent } from './Views/ClientAdmin/soa/soa.component';
import { SecincidentComponent } from './Views/ClientAdmin/secincident/secincident.component';
import { VulmanagementComponent } from './Views/ClientAdmin/vulmanagement/vulmanagement.component';
import { InternalauditComponent } from './Views/ClientAdmin/internalaudit/internalaudit.component';
import { ToolbarsupComponent } from './Views/Helpers/toolbarsup/toolbarsup.component';
import { SuperadminComponent } from './Views/SuperAdmin/Clients/superadmin.component';
import { AddclientComponent } from './Views/SuperAdmin/addclient/addclient.component';
import { FooterComponent } from './_shared/footer/footer.component';
import { RiskreviewComponent } from './features/risque-management/risk-assement/riskreview/riskreview.component';
import { IssueComponent } from './Views/ClientAdmin/issue/issue.component';
import { MaintenanceComponent } from './Views/ClientAdmin/maintenance/maintenance.component';
import { RiskassessmentComponent } from './features/risque-management/risk-assement/risk-assessment/riskassessment.component';
import { InfoCIAComponent } from './features/risque-management/inventory-assets/info-cia/info-cia.component';
import { LikelihoodComponent } from './features/risque-management/risk-assement/likelihood/likelihood.component';
import { ImpactComponent } from './features/risque-management/risk-assement/impact/impact.component';
import { AssignControlComponent } from './features/risque-management/risk-assement/assign-control/assign-control.component';
import { RisktreatmentComponent } from './features/risque-management/risk-assement/risk-treatment/risktreatment.component';
import { StandardComponent } from './Views/SuperAdmin/standard/standard.component';
import { SupDashboardComponent } from './Views/SuperAdmin/sup-dashboard/sup-dashboard.component';
import { AddStandardComponent } from './Views/SuperAdmin/add-standard/add-standard.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NZ_I18N } from 'ng-zorro-antd/i18n';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { ExcelService } from './_services/excel.service';
import { AddClientComponent } from './Views/SuperAdmin/add-client/add-client.component';
import { SuperAdminService } from './_services/super-admin.service';
import { MandatoryDocumentComponent } from './features/document-mandatory/mandatory-document/mandatory-document.component';
import { PoliciesAndProceduresComponent } from './Views/ClientAdmin/policies-and-procedures/policies-and-procedures.component';
import { ViewDocumentComponent } from './features/document-mandatory/view-document/view-document.component';
import { ReporDocumentComponent } from './Views/ClientAdmin/repor-document/repor-document.component';
import { AddMandatoryDocumentComponent } from './features/document-mandatory/add-mandatory-document/add-mandatory-document.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { HttpClient } from '@angular/common/http';
import { NzInputModule } from 'ng-zorro-antd/input';
import {MatButtonModule} from "@angular/material/button";
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatInputModule} from "@angular/material/input";
import {AccountComponent} from "./features/Layouts/account/account.component";
import {MatTabsModule} from "@angular/material/tabs";
import {ConfirmDialogComponent} from "./_shared/confirm-dialog/confirm-dialog.component";
import {ApplicatifLogComponent} from "./features/Administration/applicatif-log/applicatif-log.component";
import {MatExpansionModule} from "@angular/material/expansion";


export function HttpLoaderFactory(http: HttpClient) {

    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

export function tokenGetter() {
  return localStorage.getItem("jwt");
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    SidenavComponent,
    ScopeandboundariesComponent,
    GapanalysisComponent,
    RiskComponent,
    SoaComponent,
    SecincidentComponent,
    VulmanagementComponent,
    InternalauditComponent,
    ToolbarsupComponent,
    SuperadminComponent,
    AddclientComponent,
    TestComponent,
    FooterComponent,
    RiskreviewComponent,
    IssueComponent,
    MaintenanceComponent,
    RiskassessmentComponent,
    InfoCIAComponent,
    LikelihoodComponent,
    ImpactComponent,
    AssignControlComponent,
    RisktreatmentComponent,
    ErrorAlertComponent,
    ValidationDialogComponent,
    StandardComponent,
    SupDashboardComponent,
    AddStandardComponent,
    AddClientComponent,
    MandatoryDocumentComponent,
    PoliciesAndProceduresComponent,
    ViewDocumentComponent,
    ReporDocumentComponent,
    AddMandatoryDocumentComponent,
    AccountComponent,
    ApplicatifLogComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    // * MATERIAL IMPORTS
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    HttpClientModule,
    ChartsModule,
    MatAutocompleteModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,

      }
    }),
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzAutocompleteModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatCardModule,
    MatTooltipModule,
    NzInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,

  ],
  providers: [
    AdminService,
    SuperAdminService,
    DatePipe,
    ExcelService,
    AuthService,
    Ng2SearchPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  bootstrap: [AppComponent],
  exports: [
    FooterComponent
  ]
})
export class AppModule { }
