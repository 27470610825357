<form class="row g-3 needs-validation">
  <div class="col-md-4">
    <label for="validationCustom01" class="form-label">First name</label>
    <input type="text" class="form-control" id="validationCustom01" value="Mark" required>
    <div class="valid-feedback">
      Looks good!
    </div>
  </div>
  <div class="col-md-4">
    <label for="validationCustom02" class="form-label">Last name</label>
    <input type="text" class="form-control" id="validationCustom02" value="Otto" required>
    <div class="valid-feedback">
      Looks good!
    </div>
  </div>
  <div class="col-md-4">
    <label for="validationCustomUsername" class="form-label">Username</label>
    <div class="input-group has-validation">
      <span class="input-group-text" id="inputGroupPrepend">@</span>
      <input type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required>
      <div class="invalid-feedback">
        Please choose a username.
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <label for="validationCustom03" class="form-label">City</label>
    <input type="text" class="form-control" id="validationCustom03" required>
    <div class="invalid-feedback">
      Please provide a valid city.
    </div>
  </div>
  <div class="col-md-3">
    <label for="validationCustom04" class="form-label">State</label>
    <select class="form-select" id="validationCustom04" required>
      <option selected disabled value="">Choose...</option>
      <option>...</option>
    </select>
    <div class="invalid-feedback">
      Please select a valid state.
    </div>
  </div>
  <div class="col-md-3">
    <label for="validationCustom05" class="form-label">Zip</label>
    <input type="text" class="form-control" id="validationCustom05" required>
    <div class="invalid-feedback">
      Please provide a valid zip.
    </div>
  </div>
  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required>
      <label class="form-check-label" for="invalidCheck">
        Agree to terms and conditions
      </label>
      <div class="invalid-feedback">
        You must agree before submitting.
      </div>
    </div>
  </div>
  <div class="col-12">
    <button class="btn btn-primary" type="submit" (click)="onsubmitValidation()">Submit form</button>
  </div>
</form>
<div>
  <input type="text" [(ngModel)]="searchQuery" (input)="search()" />

  <span *ngIf="searching">
    <i class="fas fa-spinner fa-spin"></i> <!-- Replace with your loading icon -->
    Searching...
  </span>
</div>

<div class="example-input">
  <input
    placeholder='try to type "b"'
    nz-input
    [(ngModel)]="inputValue"
    (ngModelChange)="onChange($event)"
    [nzAutocomplete]="auto"
  />
  <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
</div>

<div class="CPE">
  <button (click)="this.getLength()">get length</button>

  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label" i18n>Search </label>
    <input  placeholder="Search vendor" class="form-control"  nz-input [(ngModel)]="inputValue1" (ngModelChange)="onSearch($event)"
      [nzAutocomplete]="auto" />
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>
  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label" i18n>Vendor </label>
    <input  placeholder="Search vendor" class="form-control"  (keyup)="writeVendor($event)"  nz-input [(ngModel)]="inputValue1" (ngModelChange)="onChangeVendor($event)"
      [nzAutocomplete]="auto" />
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>
  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label"  i18n>Product </label>
    <input  class="form-control" placeholder="Product" (keyup)="writeProduct ($event)"  nz-input [(ngModel)]="inputValue2" (ngModelChange)="onChangeProduct($event)"
    [nzAutocomplete]="auto" />
    
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>
  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label" i18n>Version </label>
    <input class="form-control" placeholder="Version" (keyup)="writeVersion($event)"  nz-input [(ngModel)]="inputValue3" (ngModelChange)="onChangeVersion($event)"
    [nzAutocomplete]="auto" />
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>
  <div class="mb-3">
    <label for="exampleFormControlInput1" class="form-label" i18n>CPE </label>
    <input  [attr.disabled]="true" type="text" class="form-control" id="exampleFormControlInput1" placeholder="Common Platform Enumeration"
       value={{this.adminService.listCPE[0]}}>
    <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
  </div>
  </div>
<table class="table table-bordered ">
  <thead>
    <tr id="first">
      <th id="table-header">
        <font color="red">No.toDelete</font>
      </th>
      <th id="table-header">
        <font color="red">No.</font>
      </th>
      <th id="table-header">
        <font color="red">Responsible team </font>
      </th>
      <th id="table-header">
        <font color="red">Task name</font>
      </th>
      <th id="table-header">
        <font color="red">Task description </font>
      </th>
    </tr>
  </thead>
<div class="row">  <mat-icon style="align-self:flex-end; color: #000;">edit</mat-icon>
</div>
  <tbody *ngFor="let i of daTA(); let index = index">
    <tr>{{ i.data[0]["id"] }}</tr>

    <tr>
      <td>{{ index + 1 }}</td>
      <td>{{ i.name }}</td>
      <td>
    <tr *ngFor="let j of i.data">
      <td>{{ j[1] }}</td>
    </tr>
    </td>
    <td>
      <tr *ngFor="let j of i.data">
        <td>{{ j[2] }}</td>
      </tr>
    </td>

    </tr>
  </tbody>
</table>
<button (click)="this.localService.saveData('key','ok')">save to localSorage</button>
<button (click)="this.getStorage()">get from localSorage</button>
<div></div>
<div>test get Vuln</div>
<button (click)="this.adminService.getVulnerabilities('cpeID','cpe:2.3:a:microsoft:antispyware:1.0.509:*:*:*:*:*:*:*')">Get Vuln</button>
<div>I18N Test</div>
<section>
  <article>
    <h1 i18n="Card Header|Title for the under construction card@@constructionHeader">Under Construction!</h1>
    <p i18n="Card Descritpion|A description for the under construction card.@@constructionDescription">This page is under construction.</p>
    <h5 i18n="someShit| some shit to test the page">Shiiiiiit</h5>
  </article>
</section>
<button mat-raised-button matTooltip={{this.tooltip}}
  aria-label="Button that displays a tooltip when focused or hovered over">
  Action
</button>

<div class="element" matTooltip={{this.tooltip}} matTooltipClass="example-tooltip-red">aaa</div>

<div class="col-md-4 text-right">
  <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
</div>
<button (click)="onClick()">Split</button>

<div class="col-xl-4 grid-margin ">
  <div class="card rcorners2 border-dark shadow">
    <div class="card-body">
      <h6 class="card-title">Degree of alignement with ISO27001:2022</h6>

      <div style="display: block;">
        <canvas baseChart [chartType]="lineType" [labels]="lineLabels" [datasets]="lineData" [options]="lineOption">
        </canvas>
      </div>

    </div>
  </div>
</div>
<div> nbr of assets : {{this.nbrOfAssets}} </div>
<div *ngIf="this.result" class="row" style="margin-left: 70px;">
  cpe 0: {{this.result.result.cpes[0]['cpe23Uri']}}
</div>
<div *ngIf="this.result" class="row" style="margin-left: 70px;">
  cpe List: {{this.result.result.cpes}}
</div>
<div *ngIf="this.result" class="row" style="margin-left: 70px;">
  cpe Listaa: {{this.listaa }}
</div>
<form action="#" method="post" style="padding-left: 40%; padding-top: 70px;
">

  <div class="row" style="height: 50px;"></div>
  <div class="row" style="margin-top: 50px;">

    <h2>Put an ip address</h2>
  </div>
  <div class="row" style="margin-top: 20px;">
    <input id="idInput" type="text" class="form-control col-3" style="width: 100px;" (keyup)="onApishodan($event)"
      placeholder="IPV4 || IPV6">
  </div>
  <div class="row" style="margin-top: 20px;">

    <!-- <button type="submit" class="btn-outline-dark  btn col-1" (click)="onSubmit('microsoft')" style="height: 20;">
      <h6>Submit</h6>
    </button> -->

  </div>
</form>
<div class="example-input">
  <input placeholder='try to type "b"' nz-input [(ngModel)]="inputValue" (ngModelChange)="onChange($event)"
    [nzAutocomplete]="auto" />
  <nz-autocomplete [nzDataSource]="filteredOptions" #auto></nz-autocomplete>
</div>
<div>{{options[0]}}</div>

<p>
  Exporting an excel file of type .xslx !!!
</p>
<p>Export as XLSX by clicking the below button</p>
<button>test</button>
<input #box (click)="test()">
<p>{{clickMessage}}</p>

<button (click)="exportAsXLSX()"><i class="fa fa-file-excel-o" style="font-size:48px;color:blue"></i></button>


<!--import file -->
<h1>import File</h1>
<input type="file" (change)="onFileChange($event)" multiple="false" />
<table class="sjs-table">
  <tbody>
    <tr *ngFor="let row of data">
      <td *ngFor="let val of row">
        {{val}}
      </td>
    </tr>
  </tbody>
</table>

<h1>Download Text File</h1>
<form (ngSubmit)="onSubmit()" #myForm="ngForm">
  <textarea name="myText" ngModel></textarea><br />
  <input name="fileName" ngModel>
  <button type="submit">Download</button>
</form>
<!-- 
  <tbody *ngFor="let i of data(); let index = index">
    <tr >
      <td>{{ i.data[0][2] }}</td>
      <td>{{ index + 1 }}</td>
      <td>{{ i.name }}</td>
      <td>
    <tr *ngFor="let j of i.data">
      <td>{{ j[1] }}</td>
    </tr>
    </td>
    <td>
      <tr *ngFor="let j of i.data">
        <td>{{ j[2] }}</td>
      </tr>
    </td>
    
    </tr>
  </tbody>
-->
<div>New test</div>
<button (click)="onExport()">Export PDF</button>






<div id="content" class="test2">
  <div class="info" class="test1">
    <div class="left">
      <p>Company Name: Favie Tech</p>
      <p>Company Address: 26 Huynh Khuong Ninh, DaKao, Q1, HCM</p>
    </div>
    <div>
      Company Siret: Favie Tech
    </div>
  </div>

  <h2 class="centre">Journal de caisse MyOrders du 01/01/2020</h2>

  <div class="header">
    <span>No.</span>

    <span>Dai</span>
  </div>
</div>

<h2>tva repartition on PAYGREEN payment</h2>

<div class="header">
  <span>No.</span>
  <span>FirstName</span>
  <span>Last Name</span>
</div>

<div class="header">
  <span>No.</span>
  <span>FirstName</span>
  <span>Last Name</span>
</div>

<h1>Selecting Number</h1>
<select [(ngModel)]="levelNum" (ngModelChange)="toNumber()">
  <option *ngFor="let level of levels" [ngValue]="level.num">{{level.name}}</option>
</select>
{{levelNum}}
<hr>
<mat-form-field>
  <mat-select [(value)]="selected">
    <mat-option *ngFor="let level of levels" value={{level.num}}>{{level.name}}</mat-option>
    <mat-option value="option1">Option 1</mat-option>
    <mat-option value="option2">Option 2</mat-option>
    <mat-option value="option3">Option 3</mat-option>
  </mat-select>
</mat-form-field>

<p>You selected: {{selected}}</p>




<nav class="navbar navbar-expand navbar-light bg-secondary d-flex justify-content-between">
  <div class="navbar-brand mb-0 h1 text-white">ONLINE RESUME</div>

</nav>
<br>
<div class="container-fluid">
  <form #resumeForm="ngForm">
    <div class="row">
      <div class="col-md-8">


        <br>


      </div>

      <div class="col-md-4">
        <div class="shadow-sm card action-buttons">
          <button (click)="resumeForm.valid ? generatePdf() : ''"
            class="btn btn-secondary d-flex align-items-center justify-content-center mb-1">
            <i class="material-icons">
              picture_as_pdf
            </i> <span>&nbsp;Open PDF</span></button>

        </div>


      </div>
    </div>
  </form>
</div>