import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-sup-dashboard',
  templateUrl: './sup-dashboard.component.html',
  styleUrls: ['./sup-dashboard.component.scss']
})
export class SupDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  doughnutChartType: ChartType = 'doughnut';

  doughnutChartLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  doughnutChartData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],
    }
  ];

  doughnutChartOption: ChartOptions = {}

  lineType: ChartType = 'polarArea';

  lineLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  lineData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],
    }
  ];

  lineOption: ChartOptions = {}
  lineType2: ChartType = 'line';

  lineLabels2: Label[] = ["1500","1600","1700","1750","1800","1850","1900","1950","1999","2050"];

  lineData2: ChartDataSets[] = [
    { 
      data: [86,114,106,106,107,111,133,221,783,2478],
      label: "Parameter 1",
      fill: false,
      rotation: 5,
      
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }, { 
      data: [282,350,411,502,635,809,947,1402,3700,5267],
      label: "Parameter 2",
      backgroundColor: "transparent",
      fill: true,
     
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }
  ];

  lineOption2: ChartOptions = {}

  doughnutChartType1: ChartType = 'doughnut';

  doughnutChartLabels1: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  doughnutChartData1: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, 
  ];

  doughnutChartOption1: ChartOptions = {}

  lineType1: ChartType = 'polarArea';

  lineLabels1: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  lineData1: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, 
  ];

  lineOption1: ChartOptions = {}

  areaType: ChartType = 'line';

areaLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

areaData: ChartDataSets[] = [
  {
    label: 'Dataset A',
    data: [33, 21, 9, 9, 11, 13, 5],
  }, {
    label: 'Dataset B',
    data: [35, 20, 10, 10, 15, 15, 10],
  }, {
    label: 'Dataset C',
    data: [40, 15, 5, 15, 20, 5, 20],
  }
];

areaOption: ChartOptions = {}

pieType: ChartType = 'pie';

pieLabels: Label[] = ["Africa", "Asia", "Europe"];

pieData: ChartDataSets[] = [
  {
    label: 'Dataset A',
    data: [2478,4267,1334],
    

  }, 
];

pieOption: ChartOptions = {}


radarType: ChartType = 'radar';

radarLabels: Label[] = ["Africa", "Asia", "Europe", "Latin America", "North America"];

radarData: ChartDataSets[] = [
  {
    label: "1950",
    fill: true,
    backgroundColor: "rgba(255,51,102,.3)",
    
    pointBorderWidth: 2,
    pointHoverBorderWidth: 3,
    data: [8.77,55.61,21.69,6.62,6.82]
  }, {
    label: "2050",
    fill: true,
    backgroundColor: "rgba(102,209,209,.3)",
   
    pointBorderWidth: 2,
    pointHoverBorderWidth: 3,
    data: [25.48,54.16,7.61,8.06,4.45]
  }
];

radarOption: ChartOptions = {}


polarType: ChartType = 'polarArea';

polarLabels: Label[] = ["Africa", "Asia", "Europe", "Latin America"];

polarData: ChartDataSets[] = [
  {
    label: "Population (millions)",
   
    data: [3578,5000,1034,2034]
  }
];

polarOption: ChartOptions = {}


groupedBarType: ChartType = 'bar';

groupedBarLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

groupedBarData: ChartDataSets[] = [
  {
    label: "Africa",
    data: [133,221,783,2478]
  }, {
    label: "Europe",
    data: [408,547,675,734]
  }
];

groupedBarOption: ChartOptions = {}

mixedType: ChartType = 'bar';

mixedLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

mixedData: ChartDataSets[] = [
  {
    label: "Europe",
    type: "line",
    backgroundColor: "transparent",
    data: [408,547,675,734],
    fill: false,
    pointBorderWidth: 2,
    pointHoverBorderWidth: 3,
  }, {
    label: "Africa",
    type: "line",
    backgroundColor: "transparent",
    data: [133,221,783,2478],
    fill: false,
    pointBorderWidth: 2,
    pointHoverBorderWidth: 3,
  }, {
    label: "Europe",
    type: "bar",
    borderColor: '',
    data: [408,547,675,734],
  }, {
    label: "Africa",
    type: "bar",
    borderColor: '',
    data: [133,221,783,2478]
  }
];

mixedOption: ChartOptions = {}

bubbleType: ChartType = 'bubble';

bubbleLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

bubbleData: ChartDataSets[] = [
  {
    label: "China",
    backgroundColor: 'rgba(102,209,209,.3)',
    hoverBackgroundColor: 'rgba(102,209,209,.3)',
    data: [{
      x: 21269017,
      y: 5.245,
      r: 15
    }]
  }, {
    label: "Denmark",
    backgroundColor: "rgba(255,51,102,.3)",
    hoverBackgroundColor: "rgba(255,51,102,.3)",
    data: [{
      x: 258702,
      y: 7.526,
      r: 10
    }]
  }, {
    label: "Germany",
    backgroundColor: "rgba(101,113,255,.3)",
    hoverBackgroundColor: "rgba(101,113,255,.3)",
    data: [{
      x: 3979083,
      y: 6.994,
      r: 15
    }]
  }, {
    label: "Japan",
    backgroundColor: "rgba(251,188,6,.3)",
    hoverBackgroundColor: "rgba(251,188,6,.3)",
    data: [{
      x: 4931877,
      y: 5.921,
      r: 15
    }]
  }
];

bubbleOption: ChartOptions = {}



}
