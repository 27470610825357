<div class="container">
  <h2>My account</h2>

  <mat-tab-group (selectedIndexChange)="onTabChange($event)">
    <!-- Tab for Informations du Profil -->
    <mat-tab label="Account information">
      <!-- Form for profile information -->
      <form [formGroup]="profileForm">
        <div class="form-group">
          <label for="firstName">First name:</label>
          <input type="text" id="firstName" formControlName="firstName" class="form-control" />
        </div>

        <div class="form-group">
          <label for="lastName">Last name:</label>
          <input type="text" id="lastName" formControlName="lastName" class="form-control" />
        </div>

        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" formControlName="email" class="form-control" />
        </div>

        <div class="form-group">
          <label for="birthDate">Birthdate:</label>
          <input type="date" id="birthDate" formControlName="birthDate" class="form-control" />
        </div>

        <div class="form-group">
          <label for="role" class="form-label" i18n>Role</label>
          <select class="form-select" id="role" formControlName="role" required>
            <option value="" disabled selected hidden i18n>N/A</option>
            <option *ngFor="let role of roleValues" [value]="role" i18n>{{ role }}</option>
          </select>
        </div>

        <button *ngIf="activeTabIndex === 0 && !isEditMode" type="button" class="btn btn-primary" (click)="toggleEditMode()">
          Edit Profile
        </button>
        <button *ngIf="activeTabIndex === 0 && isEditMode" type="button" class="btn btn-primary" (click)="saveProfil()">
          Save
        </button>
      </form>
    </mat-tab>

    <!-- Tab for Mot de Passe -->
    <mat-tab label="Change password">
      <div>
        <form [formGroup]="passwordForm">
          <div *ngIf="!showNewPasswordFields">
            <div class="form-group">
              <label for="password">Current Password:</label>
              <input type="password" id="password" formControlName="password" class="form-control" placeholder="Please enter your actual password"/>
              <div *ngIf="passwordForm.hasError('invalidPassword', 'password')" class="text-red-500">
                Invalid password.
              </div>
            </div>

            <button *ngIf="!isPasswordValid" type="button" class="btn btn-primary" (click)="verifyPassword()">
              Verify Password
            </button>
          </div>

          <div *ngIf="showNewPasswordFields">
            <div class="form-group">
              <label for="newPassword">New Password:</label>
              <input type="password" id="newPassword" formControlName="newPassword" class="form-control"/>

              <div class="text-red-400 text-sm" *ngIf="passwordForm.get('newPassword')?.dirty">
                <ul>
                  <li [ngClass]="{'text-primary': passwordForm.get('newPassword').value.match('^(?=.*[A-Z])')}">At least one uppercase letter.</li>
                  <li [ngClass]="{'text-primary': passwordForm.get('newPassword').value.match('(?=.*[a-z])')}">At least one lowercase letter.</li>
                  <li [ngClass]="{'text-primary': passwordForm.get('newPassword').value.match('(.*[0-9].*)')}">At least one digit.</li>
                  <li [ngClass]="{'text-primary': passwordForm.get('newPassword').value.match('(?=.*[!@#$%^&*])')}">At least one special character.</li>
                  <li [ngClass]="{'text-primary': passwordForm.get('newPassword').value.match('.{8,}')}">At least 8 characters long.</li>
                </ul>
              </div>

            </div>

            <div class="form-group">
              <label for="confirmNewPassword">Confirm New Password</label>
              <input type="password" id="confirmNewPassword" formControlName="confirmNewPassword" class="form-control"/>
            </div>
            <div *ngIf="passwordForm.hasError('passwordMismatch') && passwordForm.get('confirmNewPassword').touched" class="text-red-500">
              Passwords do not match!
            </div>

            <button *ngIf="activeTabIndex === 1" [disabled]="!isChangePasswordButtonEnabled()" type="button" class="btn btn-primary" (click)="changePassword()">
              Save password
            </button>

          </div>
        </form>

      </div>
    </mat-tab>
  </mat-tab-group>
</div>
