<div class="container-fluid page">
  <div class="d-flex page__box p-3 mt-2" i18n>Gap Analysis</div>
<div class="col-lg-12">
  <div class="card" >
    <div class="card-header" style="background-color: #06375e;">
      <h4 i18n> {{'Gap analysis'| translate}}</h4>
      <p i18n>{{'Import gap analysis excel file and export it.'|translate}}</p>
    </div>
    <div class="card-body">

      <div class="main-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">


                <form class="d-flex">
                  <input style="height: 53px" class="form-control mt-0 me-2 " type="search" placeholder="{{'Search by control type'|translate}}" [ngModelOptions]="{standalone: true}"   [(ngModel)]="searchTerm" (input)="filterItems()"  />

                  <div style="margin-block-start: 9px">
                    <mat-form-field appearance="fill">
                      <mat-label>Select Reference</mat-label>
                      <mat-select [(ngModel)]="selectedReferences" (selectionChange)="applyReferenceFilter()" name="selectedReference" multiple>
                        <mat-option *ngFor="let reference of references" [value]="reference">
                          {{ reference }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <ng-container *ngIf="isUserAllowed()">
                    <button type="button" class="btn btn-outline-primary mt-0 d-flex align-items-center" style="cursor: pointer; height: 51px"
                           (click)="openImportDialog()"><span>
                        <mat-icon style="color: grey; font-size: 15px;">cloud_upload</mat-icon>
                    </span>Import Data</button>
                  </ng-container>
                  <span><a class="btn btn-outline-primary mt-0 d-flex align-items-center" style="cursor: pointer; ; height: 51px"
                           (click)="exportData($event)"><span>
                        <mat-icon style="color: grey; font-size: 15px;">download</mat-icon>
                  </span>Export</a></span>
                </form>

                <div class="loading-container" *ngIf="isLoading">
                  <div class="loader"></div>
                  <p>Loading...</p>
                </div>



                 <div class="card-body" *ngIf="!isLoading">
                   <div class="table-responsive">
                     <div class="main-content" *ngIf="!isLoading">
                       <table class="table mat-elevation-z8 table-spacing" mat-table [dataSource]="dataSource">
                         <ng-container matColumnDef="idReference">
                           <th mat-header-cell *matHeaderCellDef>No</th>
                           <td mat-cell *matCellDef="let item">{{ item.idReference }}</td>
                         </ng-container>
                         <ng-container matColumnDef="reference">
                           <th mat-header-cell *matHeaderCellDef>Reference</th>
                           <td mat-cell *matCellDef="let item">{{ item.reference }}</td>
                         </ng-container>
                         <ng-container matColumnDef="controlType">
                           <th mat-header-cell *matHeaderCellDef>Control types</th>
                           <td mat-cell *matCellDef="let item">{{ item.controlType }}</td>
                         </ng-container>
                         <ng-container matColumnDef="requirement">
                           <th mat-header-cell *matHeaderCellDef>Requirement</th>
                           <td mat-cell *matCellDef="let item" [style.width.px]="400">{{ item.requirement }}</td>
                         </ng-container>
                         <ng-container matColumnDef="currentState">
                           <th mat-header-cell *matHeaderCellDef>Current State</th>
                           <td mat-cell *matCellDef="let item">
                             <mat-form-field>
                               <mat-select  [disabled]="!isUserAllowed()" placeholder="Choose a state" [(ngModel)]="item.currentState" (selectionChange)="saveChanges(item)">
                                 <mat-option *ngFor="let state of states" [value]="state.value">
                                   {{ state.viewValue }}
                                 </mat-option>
                               </mat-select>
                             </mat-form-field>
                           </td>
                         </ng-container>

                         <ng-container matColumnDef="comment">
                           <th mat-header-cell *matHeaderCellDef>Commentaire</th>
                           <td mat-cell *matCellDef="let item">
                             <mat-form-field>
                               <input  [disabled]="!isUserAllowed()" matInput [(ngModel)]="item.comment" placeholder="Comment" (focusout)="saveChanges(item)">
                             </mat-form-field>
                           </td>
                         </ng-container>

                         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                       </table>

                       <!-- Paginator -->
                       <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                     </div>

                   </div>
                 </div>
               </div>
           </div>
         </div>
       </div>
   </div>
 </div>
 </div>
 <app-footer></app-footer>
 </div>


<ng-template #importDialogTemplate let-data="data">
  <mat-dialog-content>

    <h2 mat-dialog-title>Enter a reference</h2>
    <mat-form-field appearance="fill">
      <mat-label>Enter reference</mat-label>
      <input matInput placeholder="Reference" [(ngModel)]="referenceInput">
    </mat-form-field>

    <h2 mat-dialog-title>Select control type</h2>
    <mat-form-field appearance="fill">
      <mat-label>Select Control Type</mat-label>
      <mat-select [(value)]="selectedOption">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" class="btn btn-outline-primary mt-0 " (click)="fileInput.click()">
                  <span>
                    <mat-icon style="color: grey; font-size: 15px;" i18n>attach_file</mat-icon>
                  </span>
      <span i18n>Import File</span>
      <input #fileInput type="file" (change)="onFileChange($event)" multiple="false"
             style="display:none;" />
    </button>
    <button type="button" class="btn btn-outline-danger mt-0 " (click)="closeImportOptionsDialog()">Cancel</button>
  </mat-dialog-actions>
</ng-template>
