import { Component, OnInit, ViewChild } from '@angular/core';
import { LogService } from '../../../_services/administration-services/log.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-applicatif-log',
  templateUrl: './applicatif-log.component.html',
  styleUrls: ['./applicatif-log.component.scss'],
})
export class ApplicatifLogComponent implements OnInit {
  logs: any;
  searchTerm: string;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['id', 'username', 'action', 'timestamp'];
  constructor(public logsService: LogService) {}

  ngOnInit(): void {
    this.getLogs();
  }

  getLogs() {
    this.logsService.getLogs().subscribe((data) => {
      this.logs = data;
      this.dataSource = new MatTableDataSource(this.logs);
      this.dataSource.paginator = this.paginator;

      // Set an initial page size based on the number of items you want to display initially
      const initialPageSize = 5; // Set your desired initial page size
      this.paginator.pageSize = initialPageSize;
      this.dataSource.paginator.pageSize = initialPageSize;

      // Calculate dynamic page size options based on data length
      const pageSizeOptions = [5, 10, 25, 50, Math.ceil(this.logs.length / 2), this.logs.length];
      this.paginator.pageSizeOptions = pageSizeOptions;
      this.paginator.length = this.logs.length;
    });
  }

  filterItems() {
    if (this.searchTerm !== '') {
      this.dataSource.data = this.logs.filter((res) => {
        return res.username.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
      });
    } else if (this.searchTerm === '') {
      this.getLogs();
    }
  }
}
