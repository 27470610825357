import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from './_services/admin.service';
import { LocalService } from './_services/local.service';
import {UserService} from './_services/administration-services/user.service';
import {MatDialog} from "@angular/material/dialog";
import {RiskService} from "./_services/risque-management-services/risk.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  selectedLanguage: string;
  user: User;
  countRiskPending: number;

  constructor(public router: Router,
              private riskService: RiskService,
              public adminService: AdminService,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private localService: LocalService,
              private dialog: MatDialog // Inject MatDialog
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.adminService.setLanguage(this.selectedLanguage);
  }

  isCollapsed = true;
  loggedIn = true;

  ngOnInit() {
    this.getPendingRisks();
  }


  getPendingRisks() {
    this.riskService.getPendingRisks().subscribe( data => {
      this.countRiskPending = data.length;
    });
  }
  logOut() {
    this.authenticationService.logout();

  }

  switchlang(lang) {
    this.selectedLanguage = lang
    this.adminService.switchlanguage(lang);
    this.adminService.setLanguage(lang)
  }
}


