import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policies-and-procedures',
  templateUrl: './policies-and-procedures.component.html',
  styleUrls: ['./policies-and-procedures.component.scss']
})
export class PoliciesAndProceduresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
