<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">

<mat-card  *ngIf="this.data.action==='Add'">
  <mat-card-content>
    <mat-accordion style="overflow-y: auto;">
      <!-- Document informations -->
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-between align-items-center">
            <span>General document informations</span>
            <mat-icon>date_range</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">

          <form [formGroup]="documentForm" class="needs-validation">
            <div class="row">
              <div class="col">
                <label for="exampleFormControlInput1" class="form-label mt-5" i18n="title"> Title</label>
                <input type="text" class="form-control" placeholder="Document title" formControlName="title" required>
                <div class="invalid-feedback" i18n="fillTheField">Please fill out this field.</div>

              </div>
              <div class="col">
                <label for="exampleFormControlInput1" class="form-label mt-5">Reference</label>
                <input type="text" class="form-control" placeholder="Document Reference" formControlName="reference" required>
                <div class="invalid-feedback" i18n="fillTheField">Please fill out this field.</div>
              </div>
            </div>

            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mt-3" i18n="shortDescription">Short Description</label>
              <textarea class="form-control" rows="3" formControlName="shortDescription"
                        required></textarea>
              <div class="invalid-feedback" i18n="filltheField">Please fill out this field.</div>
            </div>
            <!-- <div class="select">
              <label for="exampleFormControlInput1" class="form-label">Authorized by </label>
              <select class="form-select" aria-label="Default select example" (change)="writeAuthorizedBy($event)" required>
                <option value="" disabled selected hidden>Choose one..</option>
                <option *ngFor="let user of adminService.users" [value]="user.username">{{user.username}}</option>
              </select>
              <div class="invalid-feedback">Please fill out this field.</div>
            </div> -->

            <div class="row mt-4">
              <div class="col">
                <label for="exampleFormControlInput1" class="form-label" i18n="reviewer">Reviewer </label>
                <select class="form-select" aria-label="Default select example" formControlName="reviewer" required>
                  <option value="" disabled selected hidden>Choose one..</option>
                  <option *ngFor="let user of users" [value]="user.id">{{user.firstName}} - {{user.lastName}}</option>
                </select>
              </div>
              <div class="col">
                <label for="exampleFormControlInput1" class="form-label" i18n="nextReviewDate">Next Review Date</label>
                <input type="date" class="form-control" id="date" name="date" formControlName="reviewDate" required
                       [min]="minDate()">
              </div>
            </div>

            <div class="select mt-3 mb-5">
              <label for="exampleFormControlInput1" class="form-label" i18n="classification">Classification </label>
              <select class="form-select" aria-label="Default select example" formControlName="classification" required>
                <option value="" disabled selected hidden>...</option>
                <option value="Public (C0)" i18n="public">Public (C0)</option>
                <option value="Restricted (C1)" i18n="restricted">Restricted (C1)</option>
                <option value="Confidential (C2)" i18n="confidential">Confidential (C2)</option>
                <option value="Secret (C3)" i18n="secret">Secret (C3)</option>
              </select>
            </div>
          </form>

        </div>

        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Previous</button>
          <button mat-button color="primary" (click)="nextStep()">Next</button>
        </mat-action-row>

      </mat-expansion-panel>

      <!-- Document updates -->
      <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-between align-items-center">
            Policy document information
            <mat-icon>date_range</mat-icon>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content">

          <form [formGroup]="documentForm" class="needs-validation2 panel-scrollable">
            <div class="select">
              <label for="exampleFormControlInput1" class="form-label mt-4" i18n="documentType">Document type </label>
              <select class="form-select" aria-label="Default select example" formControlName="type" required>
                <option value="" disabled selected hidden>... </option>
                <option value="Policy" i18n="policy">Policy</option>
                <option value="Procedure" i18n="procedure">Procedure</option>
                <option value="Guideline" i18n="guideline">Guideline</option>
                <option value="Checklist" i18n="checklist">Checklist</option>
                <option value="Template" i18n="template">Template</option>
              </select>
            </div>
            <p class="text-sm-left" i18n="docTypeDesc">The document type is used to categorise the type of document you are creating in the
              Policy Portal page. Some documents are a mix of policies, standards and procedures and a single option does not
              represent accurately the type of document, in such cases we recommend you choosing the label that you find more
              appropiate.
            </p>
            <div class="select">
              <label for="exampleFormControlInput1" class="form-label mt-2" i18n="documentContent">Document content </label>
              <select class="form-select" aria-label="Default select example" (change)="writeDocumentBody($event)">
                <option value="content" i18n="useContent">Use content</option>
                <option value="attachment" i18n="useAttachment">Use Attachment</option>
                <option value="url" i18n="useURL">Use URL</option>
              </select>
            </div>
            <p i18n="storeDoc">Select where you want to store the actual document: </p>
            <p i18n="content">- Use Content: you can use the content editor below to write and maintain your policies.</p>
            <p i18n="attachment">- Use Attachments: you will attach PDF, Word Files, Etc to this policy once you have saved it.</p>
            <p i18n="url">- Use URL: if your policies are on Sharepoints, Wikis, Etc.</p>

            <div *ngIf="useContent">
              <div class="paragraph">
                <div class="paragraph-container" *ngFor="let addedParagraph of paragraphsFormArray; let i = index">
                  <div class="paragraph-card" style="margin-bottom: 20px;">
                    <div class="paragraph-header">
                      <i class="fas fa-times delete-icon" (click)="deleteParagraph(i)"></i>
                    </div>

                    <form [formGroup]="addedParagraph" class="needs-validation2">
                      <div class="paragraph-content">
                        <label class="form-label" i18n>Title</label>
                        <input type="text" class="form-control" formControlName="title">
                        <label class="form-label" i18n>Subtitle</label>
                        <input type="text" class="form-control" formControlName="subtitle">
                        <label class="form-label" i18n>Requirement</label>
                        <div class="select">
                          <select class="form-select" formControlName="idDocumentControl" (change)="onRequirementChange($event.target.value, i)">
                            <option *ngFor="let cntrl of documentControl" [value]="cntrl.id" [selected]="cntrl.id == addedParagraph.get('idDocumentControl').value">
                              {{ cntrl.requirement }}
                            </option>
                          </select>
                        </div>
                        <label class="form-label" i18n>Interpretation</label>
                        <textarea class="form-control" rows="3" formControlName="control"></textarea>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
              <div class="button-container">
                <button type="button" (click)="addNewParagraph()" class="btn btn-primary">
                  Add new paragraph
                </button>
              </div>
            </div>
            <div *ngIf="useAttachment">
              <div class="form-group">
                <label for="file" i18n>Choose File</label>
                <input type="file" id="file" (change)="handleFileInput($event.target.files)">
              </div>
            </div>
            <div *ngIf="useURL">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon3">https://example.com/users/</span>
                </div>
                <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3">
              </div>
            </div>
          </form>

        </div>

        <mat-action-row>
          <button mat-button color="warn" (click)="prevStep()">Previous</button>
        </mat-action-row>

      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>

  <!-- Card footer -->
  <mat-card-actions class="d-flex justify-content-end">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onClose()" i18n="close|close">Close</button>
    <button [disabled]="!documentForm.valid" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="submitForm('Add')" i18n="close|close">Save</button>
  </mat-card-actions>
</mat-card>



<mat-card  *ngIf="this.data.action==='Edit'">
    <div>
      <div class="card-header">
        <div class="title" i18n>
          ORGANIZATION NAME
        </div>

        <div class="title" i18n>
          DOCUMENT NAME <span class="info">{{this.document?.title}}</span>
        </div>
        <div class="title mb-2" i18n>
          DESCRIPTION <span class="info">{{this.document?.shortDescription}}</span>
        </div>
        <div>
        </div>

        <mat-divider [inset]="false"></mat-divider>

        <form>
          <div class="row mt-2">
            <div class="col title" i18n>
              CREATED BY<span class="info"> {{this.document?.authBy}}</span>
            </div>
            <div class="col title" i18n>
              REVIEWER<span class="info">{{this.document?.reviewer}}</span>
            </div>
            <div class="col title" i18n>
              REFERENCE<span class="info"> {{this.document?.reference}}</span>
            </div>
          </div>
        </form>
        <form>
          <div class="row mt-0 mb-2">
            <div class="col title" i18n>
              REVIEWER <span class="info">{{this.document?.reviewer}}</span>
            </div>
            <div class="col title" i18n>
              REVIEW DATE<span class="info">{{this.document?.reviewDate}}</span>
            </div>
            <div class="col title" i18n>
              CLASSIFICATION <span class="info">{{this.document?.classification}}</span>
            </div>
          </div>
        </form>

      </div>
      <mat-divider [inset]="false"></mat-divider>
    </div>
    <div class="card-body scroll">
      <form [formGroup]="documentUpdateForm" class="needs-update-validation">

        <div class="row mt-4">
          <div class="col select mt-3 mb-5">
            <label class="form-label" i18n>Update Level</label>
            <select class="form-select" aria-label="Default select example" formControlName="updateLevel" (change)="upgradeVerion($event.target.value)"
                    required>
              <option value="" disabled selected hidden>...</option>
              <option value="major" i18n>Major</option>
              <option value="minor" i18n>Minor</option>

            </select>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label mt-3" i18n>Update notes</label>
          <textarea value="No changes needed" class="form-control" rows="3" formControlName="updateNotes"
                    required></textarea>
          <div class="invalid-feedback" i18n>Please fill out this field.</div>
        </div>


        <div class="paragraph-container" *ngFor="let addedParagraph of paragraphsFormArray; let i = index">
          <div class="paragraph-card" style="margin-bottom: 20px;">
            <div class="paragraph-header">
              <i class="fas fa-times delete-icon" (click)="deleteParagraph(i)"></i>
            </div>

            <form [formGroup]="addedParagraph" class="needs-validation2">
              <div class="paragraph-content">
                <label class="form-label" i18n>Title</label>
                <input type="text" class="form-control" formControlName="title">
                <label class="form-label" i18n>Subtitle</label>
                <input type="text" class="form-control" formControlName="subtitle">
                <label class="form-label" i18n>Requirement</label>
                <div class="select">
                  <select class="form-select" formControlName="idDocumentControl" (change)="onRequirementChange($event.target.value, i)">
                    <option *ngFor="let cntrl of documentControl" [value]="cntrl.id" [selected]="cntrl.id == addedParagraph.get('idDocumentControl').value">
                      {{ cntrl.requirement }}
                    </option>
                  </select>
                </div>
                <label class="form-label" i18n>Interpretation</label>
                <textarea class="form-control" rows="3" formControlName="control"></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="button-container">
          <button type="button" (click)="addNewParagraph()" class="btn btn-primary">
            Add new paragraph
          </button>
        </div>


      </form>
    </div>
  <mat-card-actions class="d-flex justify-content-end">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onClose()" i18n="close|close">Close</button>
    <button [disabled]="!documentUpdateForm.valid" type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="submitForm('Edit')" i18n="close|close">Save</button>
  </mat-card-actions>
</mat-card>

