import { Component, OnInit } from '@angular/core';
import { Asset } from 'src/app/_models/asset';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalService } from 'src/app/_services/local.service';
import { RisktreatmentComponent } from '../risk-treatment/risktreatment.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {RiskService} from '../../../../_services/risque-management-services/risk.service';
import {InventoryAssetsService} from "../../../../_services/risque-management-services/inventory-assets.service";


@Component({
  selector: 'app-risk-list-assessment',
  templateUrl: './riskassessment.component.html',
  styleUrls: ['./riskassessment.component.scss']
})
export class RiskassessmentComponent implements OnInit {
  nbrOfAssetsVeryHigh: any;
  nbrOfAssetsHigh: any;
  nbrOfAssetsModerate: any;
  nbrOfAssetsLow: any;
  nbrOfAssetsNegligible: any;
  nbrOfAssets: any;
  style1: string = '';
  threats: string = '';
  level: string = '';
  empList: Array<Asset> = [];
  currentMessage ="You didn't select any asset. ";
  listIsEmpty: boolean ;
  criticalAssets: Asset[];


  constructor(private dialog: MatDialogRef<any>,
              public adminService: AdminService,
              private localStorage: LocalService,
              private riskService: RiskService,
              private assetService: InventoryAssetsService,
              private dialogre: MatDialog) {
    this.assetService.getAllAssets(this.localStorage.getData("id"));
    this.riskService.getIncidents();

  }

  ngOnInit(): void {
    this.getCriticalAssets(this.level, this.localStorage.getData('id'));
    this.getNbrOfAssetsHigh('High');
    this.getNbrOfAssetsModerate('Moderate');
    this.getNbrOfAssetsVeryHigh('Very High');
    this.getNbrOfAssetsLow( 'Low');
    this.getNbrOfAssetsNegligible('Negligible');
    this.getNbrOfAssets();
  }

  getNbrOfAssets() {
    this.riskService.getNbrOfAssets(this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssets = data;
    });
  }

  getNbrOfAssetsVeryHigh(level){
    this.riskService.getNbrOfAssetsWihLevel(level, this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssetsVeryHigh = data;
    });
  }

  getNbrOfAssetsHigh(level){
    this.riskService.getNbrOfAssetsWihLevel(level, this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssetsHigh = data;
    });
  }

  getNbrOfAssetsModerate(level){
    this.riskService.getNbrOfAssetsWihLevel(level, this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssetsModerate = data;
    });
  }

  getNbrOfAssetsLow(level){
    this.riskService.getNbrOfAssetsWihLevel(level, this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssetsLow = data;
    });
  }

  getNbrOfAssetsNegligible(level){
    this.riskService.getNbrOfAssetsWihLevel(level, this.localStorage.getData("id")).subscribe(data => {
      this.nbrOfAssetsNegligible = data;
    });
  }

  getCriticalAssets(level, id) {
    this.riskService.getCriticalAssets(level, id).subscribe(data => {
      this.criticalAssets = data;
    });
  }

  closeModal() {
    this.dialog.close();
  }

  setActiveLevel(level: string, style: string) {
    this.level = level;
    this.style1 = style;
    this.getCriticalAssets(this.level, this.localStorage.getData("id"));
  }

  openDialog() {
    if (this.empList.length == 0){
      this.listIsEmpty = true;
    }else {
      this.dialogre.open(RisktreatmentComponent, {
        height: '600px',
        width: '990px',
        data: {
          test:"true",
          list: this.empList,
        }
      }
      );
    }
  }
  addToList(event, item: any) {
    if (event.target.checked) {
      this.empList.push(item);
    } else if ( this.empList.includes(item) && !(event.target.checked)) {
      this.empList.splice(this.empList.indexOf(item),1);
    }
  }
}
