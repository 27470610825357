export class Cve {
    constructor(
        public id: string,
        public idAsset: string,
        public assetName: string,
        public assigner: string,
        public referenceData: string,
        public description: string,
        public severityV2: string,
        public severityV3: string,
        public publishedDate: string,
        public lastModifiedDate: string,
        public isPatched: boolean,
        public isPatchVerified: boolean,
        public idUser: string,
        public idOrg: string,
        public idAdmin: string,
    ) { }
}
