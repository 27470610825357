import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType, plugins } from 'chart.js';
import { Label } from 'ng2-charts';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-soa',
  templateUrl: './soa.component.html',
  styleUrls: ['./soa.component.scss']
})
export class SoaComponent implements OnInit {
  sideBarOpen = true;

  constructor(public adminService: AdminService) {
    //this.adminService.getAllAssets();
  }

  ngOnInit(): void {
  }
  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
  doughnutChartType: ChartType = 'doughnut';

  doughnutChartLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  doughnutChartData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],
    }
  ];

  doughnutChartOption: ChartOptions = {
    responsive:true,
    responsiveAnimationDuration:10000,


    legend:{
      position:'right',
      labels:{
        usePointStyle:true,
      },
    },

  }
  onSelect(selectedItem: any) {
    console.log("Selected item Id: ", selectedItem.id); // You get the Id of the selected item here
  }
}
