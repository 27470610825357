export class Audit{
    constructor(
        public id:string,
        public status: string,
        public risk: string,
        public controlRef: string,
        public controlTitle: string,
        public controlDescription: string,
        public controlLevel: string,
        public controlValue: string,
        public impactXlikelihood: string,
        public recommendedActions: string,
        public dateRecommAction: string,
        public isValidated: boolean,
        public dateValidation: string,
        public idAdmin: string,
        public idUser: string,
        public idOrg: string,
        ){}
}
