<div class="container-fluid page"  style="background: #1e293b;">

    <div class="d-flex page__box p-4 mt-1"  style="color: white;fontSize: 36px;">security incident</div>
</div>
<div class="col-lg-12">
    <div class="card">
      <div class="card-header"  style="background-color: #06375e;">
        <h4 i18n> {{'Goals'| translate}}</h4>
        <p i18n> security incidents, organizations can minimize the impact of incidents, protect sensitive information, maintain business continuity, and strengthen their overall security posture.</p>
      </div>
