import { Component, Inject, ViewChild, OnInit, ElementRef, Directive } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Asset } from 'src/app/_models/asset';
import { Cve } from 'src/app/_models/cve';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalService } from 'src/app/_services/local.service';
import { InfoCIAComponent } from '../info-cia/info-cia.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { itemList } from '../../../../../assets/data';
import { title } from 'process';
import {InventoryAssetsService} from '../../../../_services/risque-management-services/inventory-assets.service';
import {User} from "../../../../_models/user";
import {UserService} from "../../../../_services/administration-services/user.service";
@Component({
  selector: 'app-addasset',
  templateUrl: './addasset.component.html',
  styleUrls: ['./addasset.component.scss']
})
export class AddassetComponent implements OnInit {

  assetForm: FormGroup;
  users: User[];
  editForm: FormGroup;
  test1 = true;
  test2 = false;
  var: boolean;
  enabled = true;
  id: number;
  name: string;
  vendor: string;
  product: string;
  version: string;
  status: string;
  reviews: number;
  type: string;
  quantity = 1;
  description: string;
  location: string;
  owner: string;
  custodian: string;
  user: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  criticality: string;
  classification: string;
  category: string;
  filteredOptions1: string[] = [];
  filteredOptions2: string[] = [];
  filteredOptions3: string[] = [];
  filteredOptions: string[] = [];
  cpe: string;
  inputValue1 = '';
  numberOfAssets = 1;
  product_input_disabled = true;
  options = ['Burns Bay Road', 'Downing Street', 'Wall Street'];
  searching = false;
  assetToEdit: Asset;


  constructor(public router: Router,
              public inventoryAssetService: InventoryAssetsService,
              public adminService: AdminService,
              public dialog: MatDialog,
              private fb: FormBuilder,
              private localStorage: LocalService,
              private host: ElementRef,
              public userService: UserService,
              public dialogRef: MatDialogRef<AddassetComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                action: string;
                asset: any;
    }) {
  }

  ngOnInit() {
    this.getAllUsers();
    this.initAssetForm();
  }

  initAssetForm() {
    if (this.data.action === 'Edit' && this.data.asset) {
      // Pre-fill the username field with the user's username in edit mode
      this.assetForm = this.fb.group({
        name: [this.data.asset.name, [Validators.required]],
        status: [this.data.asset.status, [Validators.required]],
        reviews: [this.data.asset.reviews, [Validators.required]],
        type: [this.data.asset.type, [Validators.required]],
        vendor: [this.data.asset.vendor, [Validators.required]],
        quantity: [this.data.asset.quantity, [Validators.required]],
        description: [this.data.asset.description, [Validators.required]],
        location: [this.data.asset.location, [Validators.required]],
        owner: [this.data.asset.owner, [Validators.required]],
        custodian: [this.data.asset.custodian, [Validators.required]],
        user: [this.data.asset.user, [Validators.required]],
        confidentiality: [this.data.asset.confidentiality, [Validators.required]],
        integrity: [this.data.asset.integrity, [Validators.required]],
        availability: [this.data.asset.availability, [Validators.required]],
        criticality: [this.data.asset.criticality, [Validators.required]],
        classification: [this.data.asset.classification, [Validators.required]],
        category: [this.data.asset.category, [Validators.required]],
      });
    } else {
      // Initialize with empty values in add mode
      this.assetForm = this.fb.group({
        name: ['', [Validators.required]],
        status: ['', [Validators.required]],
        reviews: ['', [Validators.required]],
        type: ['', [Validators.required]],
        vendor: ['', [Validators.required]],
        quantity: ['', [Validators.required]],
        description: ['', [Validators.required]],
        location: ['', [Validators.required]],
        owner: ['', [Validators.required]],
        custodian: ['', [Validators.required]],
        user: ['', [Validators.required]],
        confidentiality: ['', [Validators.required]],
        integrity: ['', [Validators.required]],
        availability: ['', [Validators.required]],
        criticality: ['', [Validators.required]],
        classification: ['', [Validators.required]],
        category: ['', [Validators.required]],
      });
    }
  }
  activateTab(tabNumber: number) {
    this.test1 = tabNumber === 1;
    this.test2 = tabNumber === 2;
  }

  onNext() {
    this.activateTab(2);
  }

  onPrevious() {
    this.activateTab(1);
  }

  infoConfidentiality(): void {
    this.dialog.open(InfoCIAComponent, {
      height: '50%',
      width: '70%',
      data: {
        title: 'Confidentiality',
        description: 'A concept that applies to organisation’s information assets that must be held in confidence. It indicates  the level of protection that must be provided to assets to prevent unauthorised disclosure. If the confidentiality of asset is compromised, the impact on the organisation needs to be understood/estimated by asset owner. Based on the sensitivity of the asset, confidentiality characteristic of an asset has to be rated on a scale of 1-5, 1 being minimum impact and 5 being maximum impact.',
        negligible: 'No business impact because of unauthorized disclosure of the asset.',
        low: 'Insignificant impact if the asset is compromised. Typically, this would include information which would not result in a  financial / business loss if made available publicly.',
        moderate: 'Some impact if the asset is compromised. It may result in some level of business / financial loss, if disclosed to unauthorised users.',
        high: 'Significant impact if the asset is compromised. It would probably result in significant business / financial loss ',
        veryhigh: 'Severe / highest impact if the asset is compromised. Would most probably result in very high business / financial loss, which may be disastrous.'
      }
    });
  }
  infoIntegrity(): void {
    this.dialog.open(InfoCIAComponent, {
      height: '50%',
      width: '70%',
      data: {
        title: 'Integrity',
        description: 'A characteristic applying to organisation’s information assets that provides assurance, under all conditions, that the asset has not been altered without autorisation. If the integrity of asset is compromised, the impact on the organisation needs to be understood/estimated by asset owner. Based on the sensitivity of the asset, integrity characteristic of an asset has to be rated on a scale of 1-5, 1 being minimum impact, ie negligible and 5 being Very High impact.',
        negligible: 'No business impact because of unauthorized modification of the asset.',
        low: ' Insignificant impact if the asset is compromised. Typically, this would include information which would not result in a  financial / business loss if made available publicly',
        moderate: 'Some impact if the asset is compromised. It may result in some level of business / financial loss, if disclosed to unauthorised users.',
        high: 'Significant impact if the asset is compromised. It would probably result in significant business / financial loss ',
        veryhigh: 'Severe / highest impact if the asset is compromised. Would most probably result in very high business / financial loss, which may be disastrous.'
      }
    });
  }

  infoAvailability(): void {
    this.dialog.open(InfoCIAComponent, {
      height: '50%',
      width: '70%',
      data: {
        title: 'Availability',
        description: 'A characteristic that ensures organisation’s information assets will be available to authorised users when required. If the asset is not available, the impact on the organisation needs to be understood/estimated by asset owner. Based on the sensitivity of the asset, availability attribute of an asset has to be rated on a scale of 1-5, 1 being minimum impact and 5 being maximum impact. The availability characteristic will depend on the downtime that can be afforded for the asset. ',
        negligible: 'No impact if the asset is not available',
        low: 'Non-availability of asset would have minimal / insignficant impact  For example, assets with Maximum Permissible Downtime greater than 5 working days',
        moderate: 'Non-availability of asset may have some impact if prolonged for a long period of time. For example, assets where Maximum Permissible Downtime is between 1 & 5 working days',
        high: 'Non-availability of asset would result in signficant impact . This would typically apply to assets  where Maximum Permissible Downtime is 1 working day or less. ',
        veryhigh: 'Non-availability of asset will result in very high consequences / severe loss. This would typically apply to assets where Maximum Permissible Downtime is 4 hours or less'
      }
    });
  }


  onSubmit(){
    if (this.data.action === 'Add') {
      const controlValue = this.assetForm.value; // Get the user object from the form
      controlValue.criticality = this.setCriticality(controlValue);
      controlValue.idAdmin = this.localStorage.getData("id");
      controlValue.idOrg = "idOrg";
      controlValue.idUser = "idUser";
      controlValue.classification = "Not mentioned";
      console.log(controlValue);
      this.inventoryAssetService.addAsset(controlValue).subscribe(data => {
        this.dialogRef.close();
      });

    } else if (this.data.action === 'Edit') {
      const controls = { ...this.data.asset, ...this.assetForm.value };
      console.log(controls);
      this.inventoryAssetService.updateAsset(controls).subscribe((data) => {
        this.dialogRef.close();
      });
    }
  }


  onSubmite(action) {
    // console.log(this.data.asset)
    // console.log(this.data.asset)
    // const number = this.randomNumber(5, 1000);
    // var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    // this.setCriticality();
    // if (action == "Add") {
    //   console.log(this.data.action)
    //   const asset = new Asset("Asset-" + number, this.name, this.status, 0, this.type, this.vendor, 2, this.description, this.location, this.owner, this.custodian, this.user, this.confidentiality, this.integrity, this.availability, this.criticality, "Not mentioned", "category", this.localStorage.getData("id"), "idUser", "idOrg");
    //
    //   this.adminService.addAsset(asset).subscribe(data => {
    //     this.router.navigate(['inventoryOfAssets']).then(() => { })
    //   })
    //   this.adminService.assets.push(asset);
    //   this.dialogRef.close();
    //
    // } else if (action == "Edit") {
    //   this.dialogRef.close();
    // }
  }

  getAllUsers() {
    this.userService.getAllUsers(this.localStorage.getData("id")).subscribe(users => {
      this.users = users;
    });
  }

  onChange(value: string): void {
    if (value && value.trim() !== '') { // Check if value is defined and not an empty string
      this.filteredOptions = this.splitString(this.adminService.listCPE).filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      if (this.splitString(this.adminService.listCPE).includes(value)) {
        this.product = this.splitCPE_ToVersion(value, 0);
        this.version = this.splitCPE_ToVersion(value, 1);

      }
    }
  }

  async onChangeVendor(value: string) {
    if (value && value.trim() !== '') { // Check if value is defined and not an empty string
      this.filteredOptions1 = itemList.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      if (itemList.includes(value)) {
        this.searching = true;
        this.vendor = value;
        await this.adminService.getCPE_keywordSearch('https://services.nvd.nist.gov/rest/json/cpes/2.0', this.vendor);
        this.product_input_disabled = false;
        this.filteredOptions = this.splitString(this.adminService.listCPE);
        this.searching = false;
      }
    }

  }
  onChangeProduct(value: string) {

    this.filteredOptions2 = this.adminService.listCPE.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    console.log('oo');
    console.log(this.adminService.listCPE);
  }
  onChangeVersion(value: string): void {
    this.filteredOptions3 = this.adminService.listVersions.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  setCriticality(form) {
    const levels = ['Very High', 'High', 'Moderate', 'Low', 'Negligible'];
    let criticality = null; // Initialisation de criticality à null

    for (const level of levels) {
      if (form.confidentiality === level || form.integrity === level || form.availability === level) {
        criticality = level;
        break; // Sortie de la boucle dès qu'on trouve un niveau correspondant
      }
    }

    return criticality; // Retourne la valeur de criticality
  }
  randomNumber(min, max) {
    return Number(Math.random() * (max - min) + min) | 0;
  }
  splitString(list: string[]): string[] {
    return list.map(item => item.split(':').slice(4, 6).join(' '));
  }
  splitCPE_ToVersion(cpe: string, index: number): string {
    return cpe.split(' ').slice(0)[index];
  }

}
