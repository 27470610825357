<ul class="nav justify-content-center ">

  <li class="nav-item ">
    <a class="title" i18n>Definitions</a>
  </li>

</ul>
<table class="styled-table">
  <thead>
      <tr>
          <th>{{data.title}}</th>
          <th>{{data.description}}</th>

      </tr>
  </thead>
  <tbody>
      <tr>
          <td class="active-row" i18n>Negligeable</td>
          <td>{{data.negligible}}</td>

      </tr>
      <tr>
          <td  class="active-row" i18n>Low</td>
          <td>{{data.low}}</td>

      </tr>
      <tr>
          <td class="active-row" i18n>Moderate </td>
          <td>{{data.moderate}}</td>

      </tr>
      <tr>
          <td class="active-row" i18n>High</td>
          <td>{{data.high}}</td>

      </tr>
      <tr>
        <td class="active-row" i18n>Very High</td>
        <td>{{data.veryhigh}}</td>

    </tr>
      <!-- and so on... -->
  </tbody>
</table>
