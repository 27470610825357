<div class="container-fluid page">
  <div class="d-flex page__box p-4 mt-1" i18n>Required Document</div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header" style="background-color: #06375e;">
        <h4 i18n>Document management</h4>
        <p i18n>Manage your policies, procedures, standards, templates, etc in this section. Documents provide the framework
          for your Internal Controls and are used to mitigate Risks, Compliance Requirements and Data Flows.</p>
      </div>
      <div class="card-body">

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
                  <form class="d-flex">
                    <div class="input-group">
                      <span class="input-group-text" style="margin-top: 10px">
                        <i class="fas fa-search"></i> <!-- Icône de recherche -->
                      </span>
                      <input class="form-control mt-0 me-2" type="search" placeholder="{{ 'Search by Paragraph Interpretation...' | translate }}"
                             [ngModelOptions]="{ standalone: true }" [(ngModel)]="searchTerm" (input)="filterItems()">
                    </div>
                    <span><a mat-button class="btn btn-outline-primary mt-0" (click)="openAddDialog()" i18n>Add New
                        Document</a></span>
                  </form>
                  <div class="card-body">
                    <div class="table-responsive scroll-table">
                      <div class="main-content">
                        <table class="table mat-elevation-z8 table-spacing" mat-table [dataSource]="dataSource">
                          <!-- Définition des colonnes -->
                          <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef i18n>Title</th>
                            <td mat-cell *matCellDef="let document">{{document.title}}</td>
                          </ng-container>

                          <ng-container matColumnDef="reference">
                            <th mat-header-cell *matHeaderCellDef i18n>Reference</th>
                            <td mat-cell *matCellDef="let document">{{document.reference}}</td>
                          </ng-container>

                          <ng-container matColumnDef="shortDescription">
                            <th mat-header-cell *matHeaderCellDef i18n>Short Description</th>
                            <td mat-cell *matCellDef="let document">{{document.shortDescription}}</td>
                          </ng-container>

                          <ng-container matColumnDef="createdBy">
                            <th mat-header-cell *matHeaderCellDef i18n>Created By</th>
                            <td mat-cell *matCellDef="let document">Admin Admin</td>
                          </ng-container>

                          <ng-container matColumnDef="reviewer">
                            <th mat-header-cell *matHeaderCellDef i18n>Reviewer</th>
                            <td mat-cell *matCellDef="let document">{{document.reviewer}}</td>
                          </ng-container>

                          <ng-container matColumnDef="reviewDate">
                            <th mat-header-cell *matHeaderCellDef i18n>Next Review Date</th>
                            <td mat-cell *matCellDef="let document">{{document.reviewDate}}</td>
                          </ng-container>

                          <ng-container matColumnDef="classification">
                            <th mat-header-cell *matHeaderCellDef i18n>Classification</th>
                            <td mat-cell *matCellDef="let document">{{document.classification}}</td>
                          </ng-container>

                          <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef i18n>Document Type</th>
                            <td mat-cell *matCellDef="let document">{{document.type}}</td>
                          </ng-container>

                          <ng-container matColumnDef="version">
                            <th mat-header-cell *matHeaderCellDef i18n>Version</th>
                            <td mat-cell *matCellDef="let document">{{document.version}}</td>
                          </ng-container>

                          <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef i18n>Actions</th>
                            <td mat-cell *matCellDef="let document">
                              <mat-icon mat-button [matMenuTriggerFor]="menu">menu</mat-icon>
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="approveDocument(document.id)" i18n>Approve</button>
                                <button mat-menu-item (click)="viewDocument(document.id)" i18n>View</button>
                                <button mat-menu-item i18n>Comments</button>
                                <ng-container *ngIf="isUserAllowed()">
                                  <button mat-menu-item (click)="openUpdateDialog(document)" i18n>Edit</button>
                                  <button mat-menu-item (click)="deleteDocument(document.id)" i18n>Delete</button>
                                </ng-container>
                              </mat-menu>
                            </td>
                          </ng-container>

                          <!-- Associer les colonnes définies aux données -->
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                      </div>
                      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                    </div>
                  </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
