

<div class="container-fluid page"  style="background: #1e293b;">
   
  <div class="d-flex page__box p-4 mt-1"  style="color: white;fontSize: 36px;">SOA</div>
</div>
<div class="col-lg-12">
  <div class="card">
    <div class="card-header">
      <h4 i18n> {{'Goals'| translate}}</h4>
      <p i18n> SOA plays a crucial role in GRC by providing a framework for designing and integrating services that address governance, risk, and compliance requirements.</p>
    </div>

