import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyOrganization } from 'src/app/_models/myOraganization';
import { LocalService } from '../local.service';
import { Globals } from 'src/app/_globals/Globals';
import {MatrixData} from "../../_models/matrixData";

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
  urlBase = Globals.apiUrl;
  constructor(private http: HttpClient,
              private localStorage: LocalService) { }

  postMatrix(matrixData: any): Observable<any> {
    return this.http
      .post(this.urlBase + '/MatrixData', matrixData);
  }

  updateMatrixData(data: any, id: number): Observable<any> {
    return this.http.put(this.urlBase + '/MatrixData' + '/' + id, data);
  }

  getMatrixData(): Observable<any> {
    return this.http.get<MatrixData[]>(this.urlBase + '/MatrixData');
  }

}
