<div style="text-align: center;">
    <div class="form-check-inline radio">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" (click)="activeOrganisationalControls()">
      <label class="form-check-label" for="flexRadioDefault1" i18n="org">
        Organizational
      </label>
    </div>
    <div class="form-check-inline radio">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" (click)="activeTechnicalControls()">
      <label class="form-check-label" for="flexRadioDefault2" i18n="phy">
        Physical
      </label>
    </div>
    <div class="form-check-inline radio">
      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" (click)="activePhysicalControls()">
      <label class="form-check-label" for="flexRadioDefault3" i18n="tech">
        Technical
      </label>
    </div>
</div>

    <div class="table-responsive scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" i18n>Réf Annexe A (ISO27001) </th>
            <th scope="col" i18n>Titre Domaine/Objectif/Contrôle </th>
            <th scope="col" i18n>Description </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let control of assignControlByType">
            <th scope="row">
              <div class="form-check">
                <input class="form-check-input" type="checkbox"
                       (change)="addtest(control.reference,control.type,control.title,control.description)"
                       value="" id="flexCheckDefault">
              </div>
            </th>
            <td>{{control.reference}} </td>
            <td>{{control.title}} </td>
            <td>{{control.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onNoClick()" i18n="close|close">Close</button>
        <button type="button" class="btn btn-primary" (click)="onSubmite()" i18n="save">Save</button>
    </div>
