
<div class="container-fluid page"  style="background: #1e293b;">
   
    <div class="d-flex page__box p-4 mt-1"  style="color: white;fontSize: 36px;">policies-and-procedures works!</div>
</div>
<div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 i18n> {{'Goals'| translate}}</h4>
        <p i18n>{{'Describe the goals of your program and the method you will use to audit their treatment.'|translate}}</p>
      </div>
     