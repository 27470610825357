<ul class="nav justify-content-center ">
    
    <li class="nav-item ">
      <a class="title" i18n>Impact</a>
    </li>
    
  </ul>
<table class="styled-table">
    <thead>
        <tr>
            <th></th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="active-row" i18n>Financial loss</td>
            <td i18n>> 5% loss of EBITDA </td>
            <td i18n>>10 % loss of EBITDA </td>
            <td i18n>>15% loss of EBITDA </td>
            <td i18n>>20% loss of EBITDA </td>
            <td i18n>>25% loss of EBITDA </td>
            
        </tr>
        <tr>
            <td  class="active-row" i18n>Legal / Regulatory / Compliance</td>
            <td i18n>No impact </td>
            <td i18n>Low impact</td>
            <td i18n>Significant regulatory consequences</td>
            <td i18n>Severe regulatory consequences</td>
            <td i18n>Substantial regulatory consequences - may result in loss of license or litigation / sanction by regulatory body</td>
            
        </tr>
        <tr>
            <td class="active-row" i18n>Strategic</td>
            <td i18n>If the risk occurs, it will have no impact on the achievement of strategic objectives</td>
            <td i18n>If the risk occurs, it will have little/minor impact on the achievement of strategic objectives; no adverse effect on total strategy</td>
            <td i18n>If the risk occurs there is a reasonable chance that strategic objectives will not be achieved. Significant component of strategy not delivered; leading to short term impact</td>
            <td i18n>If the risk occurs, it will be unlikely that strategic objectives will not be achieved. Main component of strategy not delivered; leading to long term impact.</td>
            <td i18n>If the risk occurs it will be very unlikely that strategic objectives will be achieved. Key part of strategy not delivered resulting in loss of opportunities (bankruptcy )</td>
        </tr>
        <tr>
            <td class="active-row" i18n>Brand image</td>
            <td i18n>No impact </td>
            <td i18n>Minor/little adverse publicity ( Occasional complaints)</td>
            <td i18n>Significant nuisance and internal image degradation (Local adverse publicity)</td>
            <td i18n>Severe nuisance and degradation of the image at national level (customers, regulators, partners, etc.) </td>
            <td i18n>Loss of confidence in the company and Degradation of the imageat national and international level /Sustained adverse publicity / headlines</td>
        </tr>
        <tr>
            <td class="active-row" i18n>Operational</td>
            <td i18n>Limited interruptions in a Business Unit</td>
            <td i18n>Minor impact on the achievement of operational objectives</td>
            <td i18n>Significant impact on the achievement of operational objectives</td>
            <td i18n>Severe impact on the achievement of operational objectives</td>
            <td i18n>Major impact on the achievement of operational objectives</td>
        </tr><tr>
            <td class="active-row" v>Political / environment</td>
            <td i18n>Disruptions do not cause impact to business</td>
            <td i18n>Minor disruption to operations</td>
            <td i18n>Evacuation of ex-pat staff; significant disruptions to operations</td>
            <td i18n>Temporary closure of operations while monitoring situation for further developments</td>
            <td i18n>Complete closure</td>
        </tr><tr>
            <td class="active-row" i18n>Human Resources</td>
            <td i18n>No effect on staff welfare</td>
            <td i18n>Minor effect on staff welfare</td>
            <td i18n>Significant effect on staff welfare</td>
            <td i18n>Severe effect on staff welfare</td>
            <td i18n>Major effect on staff welfare</td>
        </tr><tr>
            <td class="active-row" i18n>Technology</td>
            <td i18n>No impact </td>
            <td i18n>Failure to meet a requirement would result in minor/little degradation of system performance / result in minor operational delays</td>
            <td i18n>Failure to meet a requirement would result in significant degradation of system performance / result in operational delays </td>
            <td i18n>Failure to meet a requirement would severely degrade system performance / result in operational delays </td>
            <td i18n>Failure to meet a requirement would degrade system performance where operational objectives are unachievable</td>
        </tr>
        <!-- and so on... -->
    </tbody>
</table>