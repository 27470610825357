<div mat-dialog-content>
    <!-- Contenu du modèle -->

    <h2 i18n>Risk Assessment</h2>

    <div >


      <div class="main-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-danger">
                  <label class="form-label" i18n>List of Assets </label>
                  <p i18n> {{this.nbrOfAssets}} Results - Filter that shows everything</p>

                </div>

                <ul class="nav nav-tabs nav-fill d-flex nav-margin">
                  <button class="nav-link active" type="button" aria-selected="true"
                          (click)="setActiveLevel('', '')"
                          [ngStyle]="{'color': style1==='' ? 'blue' : 'black','font-weight': style1==='' ?  'bold' :'normal' }">
                    All ({{this.nbrOfAssets}})
                  </button>

                  <li class="nav-item">
                    <button class="nav-link active" aria-selected="true"
                            (click)="setActiveLevel('veryHighLevelAssets', 'activeVeryHighLevel')"
                            [ngStyle]="{'color': style1==='activeVeryHighLevel' ? 'blue' : 'black','font-weight': style1==='activeVeryHighLevel' ?  'bold' :'normal' }">
                      Very High ({{this.nbrOfAssetsVeryHigh}})
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link active" aria-current="page"
                            (click)="setActiveLevel('highLevelAssets', 'activeHighLevel')"
                            [ngStyle]="{'color': style1==='activeHighLevel' ? 'blue' : 'black','font-weight': style1==='activeHighLevel' ?  'bold' :'normal' }">
                      High ({{this.nbrOfAssetsHigh}})
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link active" aria-current="page"
                            (click)="setActiveLevel('moderateLevelAssets', 'activeMederateLevel')"
                            [ngStyle]="{'color': style1==='activeMederateLevel' ? 'blue' : 'black','font-weight': style1==='activeMederateLevel' ?  'bold' :'normal' }">
                      Moderate ({{this.nbrOfAssetsModerate}})
                    </button>
                  </li>
                  <li class="nav-item">
                    <button class="nav-link active" aria-current="page"
                            (click)="setActiveLevel('lowLevelAssets', 'activeLowLevel')"
                            [ngStyle]="{'color': style1==='activeLowLevel' ? 'blue' : 'black','font-weight': style1==='activeLowLevel' ?  'bold' :'normal' }">
                      Low ({{this.nbrOfAssetsLow}})
                    </button>
                  </li>
                  <li class="nav-item styleActive">
                    <button class="nav-link active styleActive" aria-current="page"
                            (click)="setActiveLevel('negligibleLevelAssets', 'activeNegligibleLevel')"
                            [ngStyle]="{'color': style1==='activeNegligibleLevel' ? 'blue' : 'black','font-weight': style1==='activeNegligibleLevel' ?  'bold' :'normal' }">
                      Negligible ({{this.nbrOfAssetsNegligible}})
                    </button>
                  </li>
                </ul>


                <div class="card-body">
                  <div class="table-responsive scroll">
                    <table class="table mat-elevation-z8 table-spacing ">
                      <thead>
                        <tr>
                          <th></th>
                          <th i18n>Name</th>
                          <th i18n>Type</th>
                          <th i18n>Criticality</th>
                          <th i18n>Status</th>
                        </tr>
                      </thead>
                      <tbody *ngFor="let asset of  criticalAssets">
                        <tr>
                          <td scope="row">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" (change)="addToList($event,asset)"  value="" id="flexCheckDefault">
                            </div>
                          </td>
                          <td>{{asset.name}}</td>
                          <td>{{asset.type}}</td>
                          <td>
                              <span class="badge bg"
                                [ngClass]="{'bg-secondary' : asset.criticality === 'Low',
                                'bg-danger' : asset.criticality === 'Very High',
                                'bg-warning' : asset.criticality === 'High',
                                'bg-primary' : asset.criticality === 'Moderate',
                                'bg-light text-dark' : asset.criticality === 'Negligible'}">
                              {{asset.criticality}}
                            </span>
                          </td>
                          <td><span class="badge rounded-pill bg-info text-dark">Pending</span></td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    <div *ngIf="listIsEmpty">
      <app-error-alert [errorMessage]="currentMessage"> </app-error-alert>
    </div>
  </div>
</div>
<div mat-dialog-actions class="footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="closeModal()" i18n="close|close">Close</button>
  <button type="button" mat-button class="btn btn-primary" data-bs-dismiss="modal" (click)="openDialog()" i18n>Submit</button>
</div>



