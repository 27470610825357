<div class="container">
    <h2>Add New Client</h2>
    <div class="jumbotron">
        <form class="needs-validation">
            <div class="col-md-12 mb-3">
                <label for="validationServerUsername">Email</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroupPrepend3">@</span>
                    </div>
                    <input [value]="this.data.admin['email']" type="text" class="form-control"
                        id="validationServerUsername" placeholder="Enter Email"
                        aria-describedby="inputGroupPrepend3" (keyup)="writeEmail($event)" required>
                    <div class="invalid-feedback">
                        Please fill out this field.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="uname">Username</label>
                <input [value]="this.data.admin['username']" type="text" class="form-control" id="uname"
                    placeholder="Enter username" name="uname" (keyup)="writeUsername($event)" required>
                <div class="valid-feedback">Valid Username.</div>
                <div class="invalid-feedback">Please fill out this field.</div>
            </div>
            <div class="form-group">
                <label for="pwd1">Password</label>
                <input [value]="this.data.admin['password']" type="password" class="form-control" id="pwd1"
                    placeholder="Enter password" name="pswd" (keyup)="writePassword($event)" required>
                <div class="valid-feedback">Valid Password.</div>
                <div class="invalid-feedback">Please fill out this field.</div>
            </div>
            <div class="form-group">
                <label for="pwd2">Phone</label>
                <input [value]="this.data.admin['phone']" type="text" class="form-control" id="pwd2" placeholder="Enter Date" name="pswd"
                    (keyup)="writePhone($event)" required>
                <div class="valid-feedback">Valid Date.</div>
                <div class="invalid-feedback">Please fill out this field.</div>
            </div>


            



            <button type="button" (click)="onSubmite(this.data.action)" class="btn btn-primary">Submit</button>
        </form>
    </div>
</div>