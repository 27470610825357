import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-standard',
  templateUrl: './add-standard.component.html',
  styleUrls: ['./add-standard.component.scss']
})
export class AddStandardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
