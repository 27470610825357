import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf';
import { AdminService } from 'src/app/_services/admin.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {GeneratePdfDocumentService} from '../../../_services/document-services/generate-pdf-document.service';
import {DocumentService} from '../../../_services/document-services/document.service';
import {Document} from '../../../_models/document';
import {ParagraphService} from '../../../_services/document-services/paragraph.service';
import {Paragraph} from '../../../_models/paragraph';
import {DocumentUpdateService} from '../../../_services/document-services/document-update.service';
import {DocumentUpdate} from '../../../_models/documentUpdate';
import {UserService} from '../../../_services/administration-services/user.service';
import {map, switchMap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {LocalService} from '../../../_services/local.service';
import {DatePipe} from '@angular/common';
import {SnackbarService} from '../../../_services/snack-bar.service';


@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss']
})
export class ViewDocumentComponent implements OnInit {
  list: any[] = [];
  listReviewerUser: any[] = [];
  listApprovalUser: any[] = [];
  listCreateUser: any[] = [];
  document: Document;
  paragraphs: Paragraph[];
  documentUpdates: DocumentUpdate[
];
  constructor(public adminService: AdminService,
              public dialogRef: MatDialogRef<ViewDocumentComponent>,
              public generatePdfDocument: GeneratePdfDocumentService,
              public paragraphService: ParagraphService,
              public documentService: DocumentService,
              public documentUpdatedService: DocumentUpdateService,
              public userService: UserService,
              private localStorage: LocalService,
              private snackBarService: SnackbarService,
              private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public data: {
                idDocument: number;
                isApprove: boolean;
                isView: boolean
              }) {

  }

  ngOnInit(): void {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.getDocumentAndUpdated();
    this.getParagraphByIdDocument();
  }

  getDocumentAndUpdated(): void {
    this.getDocumentById().subscribe(() => {
      this.getUpdatedDocumentById();
    });
  }

  getUpdatedDocumentById() {
    // Create an observable that emits the default line
    const defaultLine: any = of({
      version: "1.0",
      updatedBy: this.document?.authBy,
      updateDate: this.document?.creationDate,
      updateNotes: 'RAS'

      // Add other default properties here
    });

    this.documentUpdatedService.getUpdateOfDocument(this.data.idDocument).pipe(
      switchMap(documentUpdates => {
        // Crée un tableau d'observables pour chaque mise à jour de l'utilisateur
        const observables = documentUpdates.map(documentUpdate =>
          this.getUserNameByUserId(documentUpdate.updatedBy)
            .pipe(
              map(updatedByName => {
                documentUpdate.updatedBy = updatedByName;
                return documentUpdate;
              })
            )
        );
        // Combine the default line observable with observables obtained from getUpdateOfDocument()
        observables.unshift(defaultLine); // Add default line at the beginning
        return forkJoin(observables);
      })
    ).subscribe(updatedDocumentUpdates => {
      this.documentUpdates = updatedDocumentUpdates;
    });
  }

  getUserNameByUserId(idUser: any): Observable<string> {
    return this.userService.getInfoUserById(idUser).pipe(
      map((userData: any) => userData.firstName + ' ' + userData.lastName)
    );
  }

  getDocumentById(): Observable<any> {
    return this.documentService.getDocument(this.data.idDocument).pipe(
      switchMap(document => {
        return forkJoin({
          reviewerName: this.getUserNameByUserId(document.reviewer),
          authByName: this.getUserNameByUserId(document.authBy)
        }).pipe(
          map(({ reviewerName, authByName }) => {
            document.reviewer = reviewerName;
            document.authBy = authByName;
            this.document = document;
            return document;
          })
        );
      })
    );
  }

  getParagraphByIdDocument() {
    this.paragraphService.getParagraphOfDocument(this.data.idDocument).subscribe(paragraph => {
      this.paragraphs = paragraph;
    });
  }

  async generateStringUserNameWithUserId(userId): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getUserNameByUserId(userId).subscribe(fullName => {
        resolve(fullName);
      });
    });
  }

  async  generatePdf() {
    this.listReviewerUser.push(
      { text: 'Creation Date: ' + this.document.creationDate + '\n \n Author: ' + this.document.authBy +
        '\n \nVersion:  1.0\n \nChange Reference:  None', fillColor: '#bfe6ff' }
    );

    if (this.documentUpdates.length !== 1) {
      // suppresion de la version 1.0
      this.documentUpdates.shift();
      // take the last line of array document updates to check the approval information
      const lastDocumentUpdate = this.documentUpdates[this.documentUpdates.length - 1];

      this.listReviewerUser.push(
        ...this.documentUpdates.map(element => ({
          text: 'Change Date: ' + element.updateDate + '\n \n Author: ' + element.updatedBy +
            '\n \nVersion: ' + element.version + '\n \nChange Reference: None',
          fillColor: '#bfe6ff'
        }))
      );

      if (lastDocumentUpdate.approvedBy && lastDocumentUpdate.approvalDate) {
        this.listReviewerUser.push(
          {
            text: 'Approval Date: ' + lastDocumentUpdate.approvalDate +
              '\n \n Author: ' + await this.generateStringUserNameWithUserId(lastDocumentUpdate.approvedBy) +
              '\n \n Version: ' + lastDocumentUpdate.version +
              '\n \n Change Reference: None',
            fillColor: '#bfe6ff'
          }
        );
      }
    }

    this.listReviewerUser = this.listReviewerUser.map(element => [element]);

    this.paragraphs.forEach(element => {
      this.list.push({ ["text"]: element.title, ["style"]: "paragraphTitle" });
      this.list.push({ ["text"]: element.documentControl.interpretation, ["style"]: "paragraphBody" });
    });

    const documentDefinition = this.generatePdfDocument.getDocumentDefinition(this.document.title, this.list,
      this.listReviewerUser);
    pdfMake.createPdf(documentDefinition).open();
  }

  approveDocument() {
    let documentUpdate =  {
      approvedBy : this.localStorage.getData("id"),
      approvalDate : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      id: this.data.idDocument
    };
    this.documentUpdatedService.updateDocumentUpdate(documentUpdate).subscribe(data => {
      this.snackBarService.openSnackBar('The document is successfully approved',
        'Close', 'blue', 'white');
      this.onClose();
    });
  }
  onClose() {
    this.dialogRef.close();
  }

}
