import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {Observable} from "rxjs";
import {User} from "../../_models/user";

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private logPile: { Username: string; Action: string }[] = [];
  private maxPileSize = 1; // Adjust the size according to your requirements

  constructor(private http: HttpClient) {}

  logAction(Username: string, Action: string): void {
    this.logPile.push({ Username, Action});

    // Check if the log pile has reached the maximum size
    if (this.logPile.length === this.maxPileSize) {
      this.sendLogs();
    }
  }

  private sendLogs(): void {

    // Send logs to the server
    this.http.post(Globals.apiUrl + '/Admin/log', this.logPile).subscribe(
      () => console.log('Log pile sent successfully'),
      (error) => console.error('Error sending log pile', error)
    );

    // Clear the log pile after sending
    this.logPile = [];
  }

  getLogs(): Observable<any>  {
    return this.http.get<any[]>(Globals.apiUrl + '/Admin/logs');
  }
}
