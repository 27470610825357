import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {Document} from '../../_models/document';
import {DocumentControl} from "../../_models/documentControl";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient) {
  }

  addDocument(document: Document): Observable<any> {
    return this.http.post(Globals.apiUrl + '/Document', document);
  }
  delDocument(id: number) {
    return this.http.delete(Globals.apiUrl + '/Document/' + id);
  }
  updateDocument(documentId, document: any): Observable<any> {
    return this.http.put(Globals.apiUrl + '/Document/' + documentId, document);
  }

  getAllDocuments(idAdmin) {
    return this.http.get<Document[]>(Globals.apiUrl + '/Document' + '?idAdmin=' + idAdmin);

  }
  getDocument(id: number) {
    return this.http.get<Document>(Globals.apiUrl + '/Document/' + id);
  }

  getDocumentControls() {
    return this.http.get<DocumentControl[]>(Globals.apiUrl + '/DocumentControls');
  }
}
