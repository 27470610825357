

<div
  *ngIf="router.url === '/login' || router.url === ''||router.url === '/superAdmin'|| router.url === '/test';else Else"
  style="width: 100%;">
  <router-outlet></router-outlet>

</div>
<ng-template #Else>
  <nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
      [nzTrigger]="null">
      <div class="sidebar-logo">
        <a href="/dashboard">
          <img  src="./assets/logo.png"  alt="logo"  style="
            width: 100px;">


        </a>
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <ng-containter *ngIf="user && user.role === 'RSSI'">
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/dashboard">
          <i nz-icon nzType="dashboard"></i>
          <span >{{'Dashboard / Home'| translate}}</span>
        </li>
        </ng-containter>

        <ng-containter *ngIf="user && user.role === 'RSSI'">
          <ul>
            <li nz-submenu nzOpen="false" nzTitle="Administration" nzIcon="security-scan">
              <ul>

                <ul>
                  <li nz-submenu nzOpen="false" nzTitle="Setup / Config" nzIcon="security-scan">
                      <ul>
                        <li nz-menu-item nzMatchRouter>
                          <a routerLink="/myorganization/risk-matrix">{{'Risk Matrix template'| translate}}</a>
                        </li>

                        <ul>
                          <li nz-menu-item nzMatchRouter>
                            <a routerLink="/myorganization">{{'Organization setup'| translate}}</a>
                          </li>
                      </ul>
                      </ul>
                  </li>

                  <li nz-menu-item nzMatchRouter>
                    <a routerLink="/organization-context">{{'Organization Context'| translate }}</a>
                  </li>
                  <li nz-menu-item nzMatchRouter>
                    <a routerLink="/isms" i18n="isms">{{'Scope and Boundaries'| translate }}</a>
                  </li>
                </ul>

                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/log">
                  <span nz-icon nzType="control" nzTheme="outline"></span>
                  <span i18n="log">{{'Log applicatif'| translate}}</span>
                </li>

                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/home">
                  <i nz-icon nzType="user" nzTheme="outline"></i>
                  <span i18n="users">{{'Users'| translate}}</span>
                </li>
              </ul>
            </li>

          </ul>
        </ng-containter>
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/gapAnalysis">
          <i nz-icon nzType="diff" nzTheme="outline"></i>
          <span i18n="gapAnalysis">{{'Gap Analysis'| translate }}</span>
        </li>
        <li nz-submenu nzOpen="false"  nzTitle="{{'Risk Management'| translate }}"
          nzIcon="security-scan">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/inventory-of-assets" i18n="inventoryOfAsset">{{'My Assets'| translate }}</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/riskAssessment" i18n="riskAssessment&treatment">{{'Risk Analysis'| translate }}</a>
            </li>

            <li nz-menu-item nzMatchRouter>
              <a routerLink="/reviews" i18n="reviews">{{' Risk Treatment and Review\n'| translate }}</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/controls" i18n="controls">{{'Controls'| translate }}</a>
            </li>
          </ul>
        </li>
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/SoA">
          <i nz-icon nzType="api" nzTheme="outline"></i>
          <span i18n="SoA">SoA</span>
        </li>
        <li nz-submenu nzOpen="false"  nzTitle="{{'Security Operations'| translate }}"
          nzIcon="property-safety">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/securityIncident" i18n="securityIncident">Security incident</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/vulnerabilityMangement" i18n="vulnerabilityManagement">Vulnerability Mangement</a>
            </li>

          </ul>
        </li>
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/audits">
          <i nz-icon nzType="audit" nzTheme="outline"></i>
          <span i18n="internalAudit">{{'Internal Audit'|translate }}</span>
        </li>
        <li nz-submenu nzOpen="false"  nzTitle="{{'Document Management'|translate }}"
          nzIcon="property-safety">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/mandatoryDocument" i18n="requiredDocument">{{'Required Documents'| translate }}</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/policies" i18n="policies&procedures">{{'Policies & Procedures'| translate }}</a>
            </li>

          </ul>
        </li>
        <!-- <li nz-submenu nzOpen="false" nzTitle="Form" nzIcon="form">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a>Basic Form</a>
            </li>
          </ul>
        </li> -->
        <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/internalAudit">
          <i nz-icon nzType="mail"></i>
          <span>Audit</span>
        </li>
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/issues">
          <i nz-icon nzType="mail"></i>
          <span>Issues</span>
        </li>
        <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/maintenance">
          <i nz-icon nzType="mail"></i>
          <span>Maintenance</span>
        </li> -->
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header">

          <div class="logo"></div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span>
          <span style="float: right">
          <button mat-button [matMenuTriggerFor]="menu" class="btn btn-outline-primary mt-0 dropdown-toggle">
            {{ this.user?.firstName }}
          </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="logOut()"><mat-icon>logout</mat-icon>Log out</button>
              <a routerLink="/account" mat-menu-item><mat-icon>account_circle</mat-icon>Account</a>
            </mat-menu>
          </span>
          <span style="float: right">
            <mat-icon style="margin-right: 20px;">language</mat-icon>
            <mat-icon [matBadge]="countRiskPending" matBadgeColor="warn" style="margin-right: 50px;" matTooltip="Pending risk not treated">notifications</mat-icon>
          </span>
        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>

      </nz-content>
    </nz-layout>
  </nz-layout>
</ng-template>
<app-footer></app-footer>
