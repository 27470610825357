import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../_globals/Globals';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {
  }

  getCVE() {
    return this.http.get<any[]>(Globals.apiUrl + '/Cve');
  }
}
