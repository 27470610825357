import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbarsup',
  templateUrl: './toolbarsup.component.html',
  styleUrls: ['./toolbarsup.component.scss']
})
export class ToolbarsupComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }
  toDashboard() {
    this.route.navigate(['superAdminDashboard'])

  }
  toClients() {}
  logOut() {
    localStorage.removeItem("jwt");
    location.reload();

  }

}
