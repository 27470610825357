<div class="container-fluid page scrollable">
  <div class="d-flex page__box p-4 mt-1 testtest" i18n>Dashboard</div>
  <div class="col-lg-12">
    <div class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">

              <form class="d-flex" style="align-self:flex-end;">
                <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                  <button class="btn btn-outline-primary mt-0 " type="submit">{{date}}</button>
                </div>
                <button class="btn btn-outline-success mt-0 " type="submit" (click)="onTest()" i18n>Search</button>
                <span><a class="btn btn-outline-primary mt-0" i18n><span>
                      <mat-icon style="color: grey; font-size: 15px;">download</mat-icon>
                    </span>Download Report</a></span>
              </form>

              <div class="">
                <div class="card" style="margin-bottom: 20px;margin-top: 10px;">
                  <div class="card-header" i18n>
                    Users
                  </div>
                  <div class="row ">
                    <div class="col-xl-6 grid-margin stretch-card">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body" matTooltip={{this.adminService.users[0]}}>
                          <h6 class="card-title" i18n>Total Number of Users<span
                              class="number">{{this.nbrTotalUsers}} </span>
                          </h6>
                          <!-- <span style="color: blue;">500</span> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-xl-4 grid-margin  ">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body" matTooltip={{this.adminService.sensitizedUsers}}>
                          <h6 class="card-title">Number of Users Sensitized<span
                              class="number">{{this.adminService.numberOfSensitizedUsers}}/{{this.adminService.numberOfUsers}} </span></h6>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-xl-6 grid-margin ">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body">
                          <span>
                            <h6 class="card-title " i18n>Online Users <span
                                class="number">{{this.nbrOnLine}}/{{this.nbrTotalUsers}}
                              </span></h6>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="card">
                  <div class="card-header" i18n>
                    Assets
                  </div>
                  <div class="row">
                    <div class="col-sm-6 grid-margin ">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body">
                          <h6 class="card-title">Criticality Classification</h6>

                          <div style="display: block;">
                            <canvas baseChart [chartType]="assetCriticalityType" [labels]="assetCriticalityLabels"
                              [datasets]="assetCriticalityData" [options]="assetCriticalityOption">
                            </canvas>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 grid-margin ">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body">
                          <h6 class="card-title" i18n>Classification By Type</h6>

                          <div style="display: block;">
                            <canvas baseChart [chartType]="assetType" [labels]="assetLabels" [datasets]="assetData"
                              [options]="assetOption">
                            </canvas>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 grid-margin ">
                      <div class="card rcorners2 border-dark shadow">
                        <div class="card-body">
                          <h6 class="card-title" i18n>Top Vulnerable Assets</h6>

                          <div style="display: block;">
                            <div class="table-responsive " style="overflow-y: scroll;
                            height: 500px;">
                              <table class="table align-middle">
                                <thead>
                                  <tr>
                                    <th i18n>Assets </th>
                                    <th i18n>Common Vulnerabilities and Exposures</th>
                                    <th>Last Modified Date</th>
                                    <th>Severity V2</th>
                                    <th class="largeTH">Description</th>
                                    <th>Action</th>
                                  </tr>

                                </thead>

                                <tbody class="smallTab" *ngFor="let cve of this.cves">
                                  <tr *ngIf="cve.severityV2==='HIGH' || cve.severityV2==='VERY HIGH'">
                                    <td>{{this.splitString(cve.assetName)}}</td>
                                    <td><a href={{cve.referenceData}} target="_blank">{{cve.id}}</a> </td>
                                    <td>{{cve.lastModifiedDate}}</td>
                                    <td>{{cve.severityV2}}</td>
                                    <td>{{cve.description}}</td>
                                    <td><span class="badge bg-danger">Patch</span></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>





<app-footer></app-footer>
