import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Document} from 'src/app/_models/document';
import {AdminService} from 'src/app/_services/admin.service';
import {LocalService} from 'src/app/_services/local.service';
import {AddMandatoryDocumentComponent} from '../add-mandatory-document/add-mandatory-document.component';
import {ViewDocumentComponent} from '../view-document/view-document.component';
import {DocumentService} from '../../../_services/document-services/document.service';
import {Role} from 'src/app/_models/Role';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {User} from 'src/app/_models/user';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ConfirmDialogComponent} from '../../../_shared/confirm-dialog/confirm-dialog.component';
import {ParagraphService} from '../../../_services/document-services/paragraph.service';
import {SnackbarService} from "../../../_services/snack-bar.service";

@Component({
  selector: 'app-mandatory-document',
  templateUrl: './mandatory-document.component.html',
  styleUrls: ['./mandatory-document.component.scss']
})
export class MandatoryDocumentComponent implements OnInit {
  searchTerm: string;
  documents: Document[];
  user: User;
  displayedColumns: string[] = ['title', 'reference', 'shortDescription', 'createdBy', 'reviewer' ,
    'reviewDate', 'classification', 'type', 'version', 'actions'];
  originalDocuments: any[];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog,
              public adminService: AdminService,
              private localStorage: LocalService,
              public documentService: DocumentService,
              public paragraphService: ParagraphService,
              private snackBarService: SnackbarService,
              private authenticationService: AuthenticationService) {
    this.authenticationService.user.subscribe(x => this.user = x);

  }

  ngOnInit(): void {
    this.initGetDocument();
  }

  initGetDocument(): Promise<void> {
    return  this.documentService.getAllDocuments(this.localStorage.getData("id")).toPromise()
      .then((data: any) => {
        this.processData(data);
        // Stockez les données originale
        this.originalDocuments = data;
        // Bind paginator to the data source
        this.dataSource.paginator = this.paginator;
        // Display only 5 rows initially
        this.dataSource.paginator.pageSize = 5;
      })
      .catch((error: any) => {
        console.error('Error in listing document:', error);
        throw error; // Propagate the error to the caller
      });
  }

  processData(data: any[]) {
    this.dataSource = new MatTableDataSource<any>(data);
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddMandatoryDocumentComponent, {
      height: '800px',
      width: '100%',
      data: {
        action: 'Add',
        document: null,
      }
    });

    // refresh the table after add
    dialogRef.afterClosed().subscribe(() => {
      this.initGetDocument();
    });

  }
  openUpdateDialog(document: Document): void {
    const dialogRef = this.dialog.open(AddMandatoryDocumentComponent, {
      height: '800px',
      width: '100%',
      data: {
        action: 'Edit',
        document,
      }
    });
    // refresh the table after add
    dialogRef.afterClosed().subscribe(() => {
      this.initGetDocument();
    });
  }
  viewDocument(id: number) {
    this.dialog.open(ViewDocumentComponent, {
      width: 'auto',
      data: {
        idDocument: id,
        isView: true,
        isApprove: false,
      }
    });
  }

  approveDocument(id: number) {
    console.log(id);
    this.dialog.open(ViewDocumentComponent, {
      width: 'auto',
      data: {
        idDocument: id,
        isView: false,
        isApprove: true
      }
    });
  }

  deleteDocument(id: number): any {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you want to delete this document?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentService.delDocument(id).subscribe(() => {
          this.paragraphService.deleteParagraphWithDocumentId(id).subscribe(data => {
            this.documents = this.documents.filter(control => control.id !== id);
            this.initGetDocument();
          });
          this.snackBarService.openSnackBar('Document is deleted successfully', 'Close',
            'blue', 'white');
        },
          error => {
            this.snackBarService.openSnackBar('Error when deleting document', 'Close',
              'blue', 'white');
          }
          );
      }
    });
  }

  filterItems(): void {
    if (this.searchTerm !== '') {
      const searchTermLower = this.searchTerm.toLocaleLowerCase();

      this.dataSource.data = this.originalDocuments.filter(doc => {

        return doc.paragraphs.some((paragraph: { documentControl: { interpretation: string; }; }) => {
          return paragraph.documentControl?.interpretation.toLocaleLowerCase().includes(searchTermLower);
        });
      });
    } else {
      this.dataSource.data = this.originalDocuments; // Restore original data
    }
  }


  isUserAllowed() {
    const roles = [Role.RSSI, Role.CISO];
    return roles.includes(this.user?.role);
  }

}
