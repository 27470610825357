import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Asset} from 'src/app/_models/asset';
import {Incident} from 'src/app/_models/incident';
import {AssignControlComponent} from '../assign-control/assign-control.component';
import {ImpactComponent} from '../impact/impact.component';
import {LikelihoodComponent} from '../likelihood/likelihood.component';
import {Router} from '@angular/router';
import {RiskService} from '../../../../_services/risque-management-services/risk.service';
import {MatrixService} from "../../../../_services/administration-services/matrix.service";

@Component({
  selector: 'app-risk-list-treatment',
  templateUrl: './risktreatment.component.html',
  styleUrls: ['./risktreatment.component.scss']
})
export class RisktreatmentComponent implements OnInit {
  incidents: Incident[];
  valueNotCompleted: boolean = false;
  previousEmpList: Array<Incident> = [];
  previousLevelList: Array<{ id: number, threat: any, vulnerability: any, incidentScenario: any, param1: any, param2: any, param3: any }> = [];
  currentMessage = "You didn't select any risk";
  threats: string = 'confidentiality';
  empList: Array<Incident> = [];
  levelList: Array<{ id: number, threat: any, vulnerability: any, incidentScenario: any, param1: any, param2: any, param3: any }> = [];
  listIsEmpty: boolean;
  likelihoodValue: number = 0;
  impactValue: number = 0;
  id: number; threat: any; vulnerability: any; incidentScenario: any;
  activeTab: string = 'Risk Identification'; // Set default active tab
  disabledTab2 = true;
  disabledTab3 = true;


  protected matrixSize: number;
  protected cellValue: string[][] = [] ;
  selectedLikelihood: number | null = null;
  selectedImpact: number | null = null;
  result = 'Not completed';
  constructor(private dialoge: MatDialogRef<any>,
              private dialog: MatDialog,
              private router: Router,
              public riskService: RiskService,
              private matrixService: MatrixService,
              @Inject(MAT_DIALOG_DATA) public data: { test: string, list: Array<Asset> }) {}

  ngOnInit(): void {
    this.getAllIncident();
    this.getMatrixData();
  }

  getMatrixData() {
     this.matrixService.getMatrixData().subscribe(data => {
       if (data.length > 0 && JSON.parse(data[0].cellValue).length > 0) {
         this.matrixSize = data[0].matrixSize;
         this.cellValue = JSON.parse(data[0].cellValue);
       }
    });
  }

  generateOptionsImpactAndProba(size: number): number[] {
    return Array.from({ length: size }, (_, i) => i + 1);
  }

  onSelect(type: 'probabilty' | 'impact', value: string): void {
    if (type === 'probabilty') {
      this.selectedLikelihood = +value;
    } else if (type === 'impact') {
      this.selectedImpact = +value;
    }

    this.updateResult();
  }

  // set result to residual risk
  updateResult(): void {
    if (this.selectedLikelihood !== null && this.selectedImpact !== null) {
      this.result = this.cellValue[this.selectedLikelihood - 1][this.selectedImpact - 1];
    }
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  getAllIncident() {
    this.riskService.getIncidents().subscribe(data => {
      this.incidents = data;
    });
  }
  closeModal(){

    this.dialoge.close();
    this.dialog.closeAll();
  }
  onSubmit() {
    this.dialog.closeAll();
  }

  onNext1() {
      this.previousEmpList = [...this.empList];
      this.previousLevelList = [...this.levelList];
      // Rest of your code

      this.activeTab = 'Risk Identification';
  }
  onNext2() {
    this.previousEmpList = [...this.empList];
    this.previousLevelList = [...this.levelList];

    if (this.empList.length == 0) {
      this.listIsEmpty = true;
    } else {
      this.listIsEmpty = false;
      this.disabledTab2 = false;
      this.activeTab = 'Inherent Risk';
    }
  }

  onNext3() {
    this.previousEmpList = [...this.empList];
    this.previousLevelList = [...this.levelList];

    if (this.empList.length !== this.levelList.length) {
      this.valueNotCompleted = true;
    } else {
      this.disabledTab3 = false;
      this.activeTab = 'Risk Treatment';
    }
  }

  dialogLikelihood(): void {
    this.dialog.open(LikelihoodComponent);
  }

  dialogImpact(): void {
    this.dialog.open(ImpactComponent);
  }

  showThreats(cia: string) {
    this.threats = cia;
  }

  dialogControl(incidentScenario: any, category: any, likelihood: any, impact: any) {
    this.dialog.open(AssignControlComponent, {

      data: {
        listOfApplicableAssets: this.data.list,
        riskName: incidentScenario,
        level: category,
        impactXlikelihood: likelihood * impact,
      }
    });

  }

  addToList(event, item: any) {
    const index = this.incidents.findIndex(incident => incident.id === item.id);

    if (event.target.checked) {
      this.empList.push(item);
      this.levelList.push({ id: item.id, threat: item.threat, vulnerability: item.vulnerability, incidentScenario: item.incidentScenario, param1: '', param2: '', param3: 'Not Completed' });
      if (index !== -1) {
        this.incidents[index].checked = true; // Mark the corresponding incident as checked
      }
    } else {
      this.empList.splice(this.empList.indexOf(item), 1);
      if (index !== -1) {
        this.incidents[index].checked = false; // Mark the corresponding incident as unchecked
      }
    }
  }

  getBadgeClass(): string {
    switch (this.result) {
      case 'VERY LOW':
        return 'badge bg-very-low';
      case 'LOW':
        return 'badge bg-low';
      case 'MEDIUM':
        return 'badge bg-medium';
      case 'HIGH':
        return 'badge bg-high';
      case 'VERY HIGH':
        return 'badge bg-very-high';
      default:
        return 'badge bg-dark';
    }
  }
}

