import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './_services/auth.service';
import { TestComponent } from './test/test.component';

import { SuperadminComponent } from './Views/SuperAdmin/Clients/superadmin.component';
import { VulmanagementComponent } from './Views/ClientAdmin/vulmanagement/vulmanagement.component';
import { ScopeandboundariesComponent } from './Views/ClientAdmin/scopeandboundaries/scopeandboundaries.component';
import { GapanalysisComponent } from './features/gapanalysis/gapanalysis.component';
import { RiskComponent } from './features/risque-management/risk-assement/risk-list/risk.component';
import { RiskreviewComponent } from './features/risque-management/risk-assement/riskreview/riskreview.component';
import { IssueComponent } from './Views/ClientAdmin/issue/issue.component';
import { SoaComponent } from './Views/ClientAdmin/soa/soa.component';
import { SecincidentComponent } from './Views/ClientAdmin/secincident/secincident.component';
import { InternalauditComponent } from './Views/ClientAdmin/internalaudit/internalaudit.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AddassetComponent } from './features/risque-management/inventory-assets/addasset/addasset.component';
import { SupDashboardComponent } from './Views/SuperAdmin/sup-dashboard/sup-dashboard.component';
import { StandardComponent } from './Views/SuperAdmin/standard/standard.component';
import { PoliciesAndProceduresComponent } from './Views/ClientAdmin/policies-and-procedures/policies-and-procedures.component';
import { MandatoryDocumentComponent } from './features/document-mandatory/mandatory-document/mandatory-document.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/Role';
import {AccountComponent} from "./features/Layouts/account/account.component";
import {ApplicatifLogComponent} from "./features/Administration/applicatif-log/applicatif-log.component";
import {LogGuard} from "./_helpers/log.guard";
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./features/Administration/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard, LogGuard],
    data: { roles: [Role.RSSI] }
  },

  {
    path: 'login',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'myorganization',
    loadChildren: () => import('./features/Administration/my-organization/my-organization.module').then(m => m.MyOrganizationModule),
    canActivate: [AuthGuard, LogGuard],
    data: { roles: [Role.RSSI] }

  },
  {
    path: 'organization-context',
    loadChildren: () => import('./features/Administration/organization-context/org-context.module').then(m => m.OrgContextModule),
    canActivate: [AuthGuard, LogGuard],
    data: { roles: [Role.RSSI] }
  },
  {
    path: 'isms', component: ScopeandboundariesComponent,

  },
  { path: 'gapAnalysis', component: GapanalysisComponent,
    canActivate: [AuthGuard, LogGuard] },
  {
    path: 'inventory-of-assets',
    loadChildren: () => import('./features/risque-management/inventory-assets/inventory-assets.module').then(m => m.InventoryAssetsModule),
    canActivate: [AuthGuard, LogGuard]
  },
  {
    path: 'riskAssessment', component: RiskComponent,
    canActivate: [AuthGuard, LogGuard],

  },
  {
    path: 'reviews', component: RiskreviewComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'controls',
    loadChildren: () => import('./features/risque-management/controls/controls.module').then(m => m.ControlsModule),
    canActivate: [AuthGuard, LogGuard]
  },
  {
    path: 'audits',
    loadChildren: () => import('./features/Audits/audit.module').then(m => m.AuditModule),
    canActivate: [AuthGuard, LogGuard]
  },
  {
    path: 'issues', component: IssueComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'maintenance', component: RiskComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'SoA', component: SoaComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'securityIncident', component: SecincidentComponent,
    canActivate: [AuthGuard, LogGuard],
  },

  {
    path: 'vulnerabilityMangement', component: VulmanagementComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'internalAudit', component: InternalauditComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'superAdmin', component: SuperadminComponent,
    canActivate: [AuthService, LogGuard],
    data: {
      roles: [Role.RSSI]
    }
  },
  { path: 'test', component: TestComponent },
  {
    path: 'add', component: AddassetComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'superAdminDashboard', component: SupDashboardComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'standards', component: StandardComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'policies', component: PoliciesAndProceduresComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'mandatoryDocument', component: MandatoryDocumentComponent,
    canActivate: [AuthGuard, LogGuard],
  },
  {
    path: 'log', component: ApplicatifLogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'account', component: AccountComponent,
    canActivate: [AuthGuard],
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
