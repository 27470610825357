import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Audit} from '../_models/audit';
import {Risk} from '../_models/risk';
import {Globals} from '../_globals/Globals';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient) {
  }

  addAudit(audit: Audit) {
    return this.http.post(Globals.apiUrl + '/Audit', audit);
  }
  getAllAudits(idAdmin: string) {
    return this.http.get<Audit[]>(Globals.apiUrl + '/Audit' + '?idAdmin=' + idAdmin);
  }
  getAuditByStatus(status: string, idAdmin: string) {
    return this.http.get<Audit[]>(Globals.apiUrl + '/Audit/' + status + '?idAdmin=' + idAdmin);
  }
  getAuditById(id: number): Observable<any> {
    return this.http.get<Risk>(Globals.apiUrl + '/Audit' + '/' + id);
  }
  updateAudit(audit: any): Observable<any> {
    return this.http.put(Globals.apiUrl + '/Audit' + '/' + audit.id, audit);
  }
}
