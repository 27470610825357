import {Injectable} from '@angular/core';
import {Control} from '../../_models/control';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {ControlANSI} from "../../_models/controlANSI";

@Injectable({
  providedIn: 'root'
})
export class AssignControlService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient, private router: Router) {
  }

  getANSIControlsByType(type: string) {
    return this.http.get<ControlANSI[]>(Globals.apiUrl + '/AnsiControl/' + type);
  }

  getANSIControls() {
    return this.http.get<ControlANSI[]>(Globals.apiUrl + '/AnsiControl');
  }
}
