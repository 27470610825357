import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scopeandboundaries',
  templateUrl: './scopeandboundaries.component.html',
  styleUrls: ['./scopeandboundaries.component.scss']
})
export class ScopeandboundariesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  sideBarOpen = true;

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
}

}
