<div class="container-fluid page"  style="background: #1e293b;">

    <div class="d-flex page__box p-4 mt-1"  style="color: white;fontSize: 36px;">Vulnerability management</div>
</div>
<div class="col-lg-12">
    <div class="card">
      <div class="card-header" style="background-color: #06375e;">
        <h4 i18n> {{'Goals'| translate}}</h4>
        <p i18n>the goals of vulnerability management are to identify, assess, and remediate vulnerabilities to reduce risks, protect systems and data, comply with regulations, and maintain a strong security posture in the face of evolving cyber threats.</p>
      </div>
