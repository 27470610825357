export class Risk {
    constructor(
        public id: string,
        public applicableAsset: string,
        public riskName: string,
        public riskOwner: string,
        public impactXlikelihood: number,
        public level: string,
        public controlTitle: string,
        public controlDescription: string,
        public risidualRisk: string,
        public actionPlan: string,
        public status: string,
        public reviews: number,
        public reviewDate: string,
        public idAdmin: string,
        public idUser: string,
        public idOrg: string,
    ) { }
}
