import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {Asset} from '../../_models/asset';

@Injectable({
  providedIn: 'root'
})
export class InventoryAssetsService {
  controlsByType: any[] = [];
  constructor(private http: HttpClient) {
  }


  addAsset(asset: Asset): Observable<any> {
    return this.http.post(Globals.apiUrl + '/Asset/', asset);

  }

  updateAsset(asset: any): Observable<any> {
    return this.http.put(Globals.apiUrl + '/Asset/' + asset.id, asset);
  }
  delAsset(id: number) {
    return this.http.delete(Globals.apiUrl + '/Asset/' + id);
  }

  getAllAssets(idAdmin: any) {
    return this.http.get<Asset[]>(Globals.apiUrl + '/Asset' + '?idAdmin=' + idAdmin);
  }


  getCriticalAssets(level: string, idAdmin: string) {
    this.http.get<Asset[]>(Globals.apiUrl + '/Asset/' + level + '?idAdmin=' + idAdmin);
  }
}
