
  <mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
      <!-- <mat-drawer mode="side" opened="true"> -->
      <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
      <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
      <div class="container-fluid page">
        <div class="d-flex page__box p-4 mt-1">Risk Assessment & Risk Treatment</div>
        <div class="col-lg-12">
            <div class="card">
              <div class="card-header">
                  <h4>Asset Risks</h4>
                <p>Manage Asset based Risks lifecycle.</p>
              </div>
              <div class="card-body">
                
                <div class="main-content">
                  <div class="container-fluid">
                      <div class="row">
                          <div class="col-md-12">
                              <div class="card">
                                  <div class="card-header card-header-danger">
                                      <h4>All items</h4>
                                      <p> 3 Results - Filter that shows everything</p>
                                  </div>
                                  <form class="d-flex">
                                    <input class="form-control mt-0 me-2 " type="search" placeholder="Search" aria-label="Search">
                                    <button class="btn btn-outline-success mt-0 " type="submit">Search</button>
                                    <span><button class="btn btn-outline-primary mt-0">Add item</button></span>
                                  </form>
                                  <div class="card-body">
                                      <div class="table-responsive">
                                          <table class="table mat-elevation-z8 table-spacing">
                                            <thead>
                                              <tr>
                                                <th>Actions</th>
                                                <th>Status</th>
                                                <th>Reviews</th>
                                                <th>Review Date</th>
                                                <th>Title</th>
                                                <th>Applicable Assets</th>
                                                <th>Risk Owner</th>
                                                <th>Risk Treatment</th>
                                                <th>Project</th>
                                                <th>Control</th>
                                                
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td><mat-icon>edit</mat-icon> <span><mat-icon>delete</mat-icon></span></td>
                                                <td><span class="badge bg-danger">DANGEROUS RISK</span></td>
                                                <td>4</td>
                                                <td><span class="badge bg-secondary">02/02/2022</span></td>
                                                <td>Complete loss of customer data if Demo Enviroment and Website Backups fail</td>
                                                <td>Download Contacts</td>
                                                <td>Farouk RHIMI</td>
                                                <td>Mitigate</td>
                                                <td></td>
                                                <td>Backups</td> 
                                              </tr>
                                              <tr>
                                                <td><mat-icon>edit</mat-icon> <span><mat-icon>delete</mat-icon></span></td>
                                                <td><span class="badge bg-warning">CONTROL LAST AUDIT EXPIRED </span></td>
                                                <td>4</td>
                                                <td><span class="badge bg-secondary">02/02/2022</span></td>
                                                <td>IP Disputes due loss or theft of Contracts</td>
                                                <td>Employee Contacts</td>
                                                <td>Farouk RHIMI</td>
                                                <td>Mitigate</td>
                                                <td></td>
                                                <td> Employee contract reviews </td> 
                                              </tr><tr>
                                                <td><mat-icon>edit</mat-icon> <span><mat-icon>delete</mat-icon></span></td>
                                                <td><span class="badge bg-success">RISK DISCARDED</span></td>
                                                <td>4</td>
                                                <td><span class="badge bg-secondary">02/02/2022</span></td>
                                                <td>Software Bugs prevent customers from using its features</td>
                                                <td> Software / Products Built</td>
                                                <td>Farouk RHIMI</td>
                                                <td>Mitigate</td>
                                                <td></td>
                                                <td>Code Reviews</td> 
                                              </tr>
                                            </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <app-footer></app-footer>

    </mat-drawer-content>
  </mat-drawer-container>