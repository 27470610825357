<app-toolbarsup></app-toolbarsup>


<div class="parent">
  <div class="card">
    <div class="card-header">
      <i class="fa fa-align-justify"></i> List of Clients
    </div>

    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Password</th>
            <th>Country</th>
            <th>Activity</th>
            <th>Expiration</th>
            <th>Actions</th>


          </tr>
        </thead>
        <tbody *ngFor="let admin of superAdminService.admins">
          <tr>
            <td>{{admin.id}}</td>
            <td>
              <div>{{admin.username}}</div>
              <div class="small text-muted">
                <span>New</span> | Registered: Jan 1, 2015
              </div>
            </td>
            <td class="small">{{admin.password}}</td>
            <td>Tunisia</td>
            <td>
              <div class="small text-muted">Last login</div>
              <strong>1 hour ago</strong>
            </td>
            <td>
              <div class="clearfix">
                <div class="float-left">
                  <strong>74%</strong>
                </div>
                <div class="float-right">
                  <small class="text-muted">Jun 11, 2015 - Jul 10, 2015</small>
                </div>
              </div>
              <div class="progress progress-xs">
                <div class="progress-bar bg-warning" role="progressbar" style="width: 74%" aria-valuenow="74"
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </td>
            <td>
              <mat-icon (click)="openUpdateDialog(admin)">edit</mat-icon>
              <mat-icon (click)="delAdmin(admin.id)">delete</mat-icon>

            </td>
            <td>
            </td>

          </tr>


        </tbody>
      </table>
      <button type="button" class="btn btn-primary" (click)="openAddDialog()">Add client</button>

    </div>


  </div>