import {Injectable} from '@angular/core';
import {Control} from '../../_models/control';
import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {Incident} from "../../_models/incident";
import {Risk} from "../../_models/risk";
import {Asset} from "../../_models/asset";

@Injectable({
  providedIn: 'root'
})
export class RiskService {
  constructor(private http: HttpClient, private router: Router) {
  }

  getIncidents() {
    return this.http.get<Incident[]>(Globals.apiUrl + '/Incident');
  }
  addRisk(risk: Risk): Observable<any> {
    return this.http.post(Globals.apiUrl + '/Risk', risk);

  }
  getAllRisks(idAdmin: string) {
    return this.http.get<Risk[]>(Globals.apiUrl + '/Risk' + '?idAdmin=' + idAdmin);
  }

  getPendingRisks() {
    return this.http.get<Risk[]>(Globals.apiUrl + '/Risk/getPendingRisk');
  }

  getRiskById(id: string): Observable<any> {
    return this.http.get<Risk>(Globals.apiUrl + '/Risk/'  + id);
  }
  updateRisk(risk: any): Observable<any> {
    return this.http.put(Globals.apiUrl + '/Risk/'  + risk.id, risk);
  }

  getCriticalAssets(level: string, idAdmin: string) {
    return this.http.get<Asset[]>(Globals.apiUrl + /Asset/ + level + '?idAdmin=' + idAdmin);
  }

  //Number of Assets:
  getNbrOfAssets(idAdmin: string) {
    return this.http.get<number>(Globals.apiUrl + '/Asset/nbrOfAssets' + '?idAdmin=' + idAdmin);
  }

  getNbrOfAssetsWihLevel(level: string, idAdmin: string) {
    return this.http.get<number>(Globals.apiUrl + '/Asset/nbrOfAssetsByLevel?level=' + level + '&idAdmin=' + idAdmin);
  }
}
