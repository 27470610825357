import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { AdminService } from '../_services/admin.service';
import { ExcelService } from '../_services/excel.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { LocalService } from '../_services/local.service';
import { HttpClient } from "@angular/common/http";
import { itemList } from '../../assets/data';





type AOA = any[][];
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})


export class TestComponent implements OnInit {
  [x: string]: any;
  list = this.adminService.gapAnalysis;
  ipAdresse: any;
  result: any;
  inputValue?: string;
  filteredOptions: string[] = [];
  options = ['Burns Bay Road', 'Downing Street', 'Wall Street', 'Burns Bay ', 'Downing ', 'Wall '];
  listaa: any[] = [];
  clickMessage = 'Click me!';
  dataa: any[] = [];
  @ViewChild('content') content: ElementRef;
  tooltip: string = 'Click to edit';
  name = 'Angular ' + VERSION.major;
  nbrOfAssets: number = 0;
value?: string;
searchQuery: string = '';
  searching: boolean = false;

  search() {
    this.searching = true;

    // Perform your search logic or API call here
    // Once the search is complete, set searching to false
    // For example, using a setTimeout to simulate asynchronous operation
    setTimeout(() => {
      this.searching = false;
    }, 5000);
  }

  onItemSelected() {
    console.log('Selected item:');
  }

  constructor(public adminService: AdminService, private excelService: ExcelService, public localService: LocalService) {
    console.log("111111");

    //this.adminService.getCPE();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

  }
  onsubmitValidation(){
    var forms = document.querySelectorAll('.needs-validation')

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    })
  }

  getStorage() {
    console.log("test" + this.localService.getData("farouk"));

  }
  getLength() {
    //console.log("lenght of list: " + this.adminService.listCPE.length);

  }

  test() {
    this.clickMessage = 'You are my hero!';
  }
  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.adminService.assets, 'sample');
  }
  onChange(value: string): void {
    this.filteredOptions = this.options.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnInit(): void {
    console.log("test" + this.localService.getData("farouk"));
  }

  finalL = [
    ["Team1", "Description1", "Application1", "e"],
    ["Team1", "Description2", "Application2", "e"],
    ["Team1", "Description3", "Application3", "e"],
    ["Team2", "Description1", "Application1", "e"],
    ["Team2", "Description2", "Application2", "e"],
    ["Team2", "Description3", "Application3", "e"],
  ];

  daTA() {
    // First find distinct teams and then filter information about him
    return this.finalL.map(x => x[0])
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(x => ({
        name: x,
        data: this.finalL.filter(y => y[0] === x)
      }));
  }



  lineType: ChartType = 'pie';

  lineLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  lineData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],
    }
  ];

  lineOption: ChartOptions = {}

  onApishodan(x: any) {
    this.ipAdresse = x.target.value;

  }



  //--------------------Import file excel---------------------
  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  //-----------------------------------------------------


  onClick() {
    var str = "cpe:2.3:o:microsoft:windows_10:1809:*:*:*:*:*:x64:*";
    var splitted = str.split(":");
    console.log(splitted[3], splitted[4])
  }

  //-----------------------------------------------------Download text file
  onSubmit() {
    //this.download();
  }

  /* download() {
    var file = new Blob([this.myForm.form.value.myText], {type: '.txt'});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, this.myForm.form.value.filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = this.myForm.form.value.fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
  } */
  //------------------------- Download pdf
  USERS = [
    {
      "id": 1,
      "name": "Leanne Graham",
      "email": "sincere@april.biz",
      "phone": "1-770-736-8031 x56442"
    },
    {
      "id": 2,
      "name": "Ervin Howell",
      "email": "shanna@melissa.tv",
      "phone": "010-692-6593 x09125"
    },
    {
      "id": 3,
      "name": "Clementine Bauch",
      "email": "nathan@yesenia.net",
      "phone": "1-463-123-4447",
    },
    {
      "id": 4,
      "name": "Patricia Lebsack",
      "email": "julianne@kory.org",
      "phone": "493-170-9623 x156"
    },
    {
      "id": 5,
      "name": "Chelsey Dietrich",
      "email": "lucio@annie.ca",
      "phone": "(254)954-1289"
    },
    {
      "id": 6,
      "name": "Mrs. Dennis",
      "email": "karley@jasper.info",
      "phone": "1-477-935-8478 x6430"
    }
  ];


  public onExport() {
    const doc = new jsPDF("p", "pt", "a4");
    var num = doc.internal.pages.length;
    const source = document.getElementById("content");
    doc.text("Test", 0, 0);
    doc.setFontSize(12),
      doc.html(source, {
        callback: function (pdf) {
          doc.output("dataurlnewwindow"); // preview pdf file when exported
        }
      });
    /* autotable(doc, {
      html: '#content',
      useCss: true
    }) */
    console.log("length: " + num);
  }
  levelNum: number;
  levels: Array<Object> = [
    { num: 0, name: "AA" },
    { num: 1, name: "BB" }
  ];

  toNumber() {
    this.levelNum = +this.levelNum;
    console.log(this.levelNum);
  }


  generatePdf() {
    console.log(pdfMake);
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();

  }





  getDocumentDefinition() {
    // sessionStorage.setItem('resume', JSON.stringify(this.resume));
    return {
      header: 'test',
      footer: function (currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
      content: [
        {
          text: 'RESUME',
          bold: true,
          fontSize: 20,
          alignment: 'center',
          margin: [0, 0, 0, 20],
        },

        {
          columns: [
            [
              {
                text: "this.resume.name",
                style: 'name',
              },
              {
                text: "this.resume.address",
              },
              {
                text: 'Email : 1' + "this.resume.email",
              },
              {
                text: 'Contant No : ' + "this.resume.contactNo",
              },
            ],
          ],
        },
        {
          text: 'Skills',
          style: 'header',
        },

      ],

    };
  }

}


