import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../_services/administration-services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Role} from "../../../_models/Role";
import {AuthenticationService} from "../../../_services/authentication.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  roleValues = Object.values(Role);
  user: any;
  userId: number;
  profileForm: FormGroup;
  passwordForm: FormGroup;
  isEditMode: boolean = false;
  isEditModePassword: boolean = false;
  activeTabIndex: number = 0;
  StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
  isPasswordValid = false;
  showNewPasswordFields = false;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private _snackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
              private datePipe: DatePipe) {
    this.authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
    console.log(this.isUserAllowed());
    this.userId = JSON.parse(localStorage.getItem('user')).id;
    this.profileForm = this.fb.group({
      firstName: [{ value: '', disabled: !this.isEditMode }, Validators.required],
      lastName: [{ value: '', disabled: !this.isEditMode }, Validators.required],
      email: [{ value: '', disabled: !this.isEditMode }, [Validators.required, Validators.email]],
      birthDate: [{ value: '', disabled: !this.isEditMode }, Validators.required],
      role: [{ value: '', disabled: !this.isEditMode || !this.isUserAllowed() }, Validators.required],
    });

    this.passwordForm = this.fb.group({
      password: ['', Validators.minLength(6)],
      newPassword: ['', [Validators.minLength(6), Validators.pattern(this.StrongPasswordRegx)]],
      confirmNewPassword: ['']
    }, {
      validator: [this.passwordMatchValidator.bind(this)]
    });
    this.loadUserProfile();
  }

  onTabChange(index: number) {
    this.activeTabIndex = index;
  }

  // activer le mode edition du formulaire
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    // Activer ou désactiver les champs en fonction du mode d'édition
    const formControls = ['firstName', 'lastName', 'email', 'birthDate', 'role'];
    formControls.forEach(controlName => {
      if (this.isEditMode) {
        this.profileForm.controls[controlName].enable();
      } else {
        this.profileForm.controls[controlName].disable();
      }
    });
  }


  getFormattedBirthdate(birthdate: string): string {
    console.log(birthdate);
    return this.datePipe.transform(birthdate, 'yyyy-MM-dd') || '';
  }


  loadUserProfile() {
    this.userService.getInfoUserById(this.userId).subscribe(
      (userData) => {
        console.log(userData);
        this.user = userData;
        this.profileForm.patchValue({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          birthDate: this.user.birthDate,
          role: this.user.role,
        });
      },
      (error) => {
        console.error('Erreur lors du chargement du profil utilisateur', error);
      }
    );
  }

  saveProfil() {
    if (this.profileForm.valid) {
      const updatedData = this.profileForm.value;
      console.log(updatedData);
      updatedData.id = this.userId;
      this.userService.updateUser(updatedData).subscribe(
        () => {
          console.log('Profil utilisateur mis à jour avec succès');
          this.loadUserProfile();
          this.toggleEditMode(); // Désactivez le mode d'édition après la mise à jour
        },
        (error) => {
          console.error('Erreur lors de la mise à jour du profil utilisateur', error);
        }
      );
    }
  }

  // verification du passowrd
  verifyPassword() {
    const passwordValue = this.passwordForm.get('password').value;
    if (passwordValue.trim().length !== 0) {
      // Call your service to verify the password
      this.userService.verifyHashedPassword(this.userId, passwordValue)
        .subscribe(isValid => {
          this.isPasswordValid = isValid;
          if (!isValid) {
            // Handle invalid password, for example, set a validation error
            this.passwordForm.get('password').setErrors({ invalidPassword: true });
          } else {
            this.showNewPasswordFields = true;
          }
        });
    }
  }

  // Fonction de validation personnalisée pour la correspondance des mots de passe
  passwordMatchValidator(group: FormGroup) {
    const newPassword = group.get('newPassword').value;
    const confirmNewPassword = group.get('confirmNewPassword').value;

    return newPassword === confirmNewPassword ? null : { passwordMismatch: true };
  }

  changePassword(): void {
    const newPassword = this.passwordForm.get('newPassword').value;
    this.userService.changePassword(this.userId, newPassword)
      .subscribe(
        () => {
          // Gérer le succès ici, par exemple, afficher un message à l'utilisateur
          this.openSnackBar('Password changed successfully', 'Success');
        },
        (error) => {
          // Gérer les erreurs ici, par exemple, afficher un message d'erreur à l'utilisateur
          console.error('Error changing password', error);
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000, // Durée d'affichage en millisecondes
      verticalPosition: 'top', // Position verticale
      horizontalPosition: 'center', // Position horizontale
      panelClass: ['snackbar-success'], // Classe CSS personnalisée pour le style
    });
  }

  // fonction pour activer et disactiver le bouton de submit
  isChangePasswordButtonEnabled(): boolean {
    const newPassword = this.passwordForm.get('newPassword').value;
    const confirmNewPassword = this.passwordForm.get('confirmNewPassword');

    // Vérifiez ici tous les critères et retournez true s'ils sont tous satisfaits
    const hasUppercase = /^(?=.*[A-Z])/.test(newPassword);
    const hasLowercase = /^(?=.*[a-z])/.test(newPassword);
    const hasDigit = /.*[0-9].*/.test(newPassword);
    const hasSpecialCharacter = /^(?=.*[!@#$%^&*])/.test(newPassword);
    const isMinLength = newPassword.length >= 8;

    // Vérifiez si le champ confirmNewPassword est valide
    const isConfirmNewPasswordValid = !confirmNewPassword.errors && confirmNewPassword.touched;

    // Vérifiez si les mots de passe correspondent
    const doPasswordsMatch = newPassword === confirmNewPassword.value;

    return hasUppercase && hasLowercase && hasDigit && hasSpecialCharacter && isMinLength &&
      isConfirmNewPasswordValid && doPasswordsMatch;
  }

  isUserAllowed() {
    const roles = [Role.RSSI, Role.CISO];
    return roles.includes(this.user?.role);
  }
}
