import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Admin } from 'src/app/_models/admin';
import { AdminService } from 'src/app/_services/admin.service';
import { SuperAdminService } from 'src/app/_services/super-admin.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {
  id: number = 0;
  username: string;
  email: string;
  password: string;
  phone:number;
  dateRegistred: string;
  dateToday: number = Date.now();

  constructor(private superAdminService: SuperAdminService, private datePipe: DatePipe,
    private router: Router,
    public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      action: string;
      admin: any;
    },) { }
  ngOnInit(): void {
    console.log(this.data.admin);
  }


  onSubmite(action: string) {
    console.log(this.data.action);
    var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    const admin = new Admin("Admin-"+this.randomNumber(19999, 9999999).toString(), this.username, this.email, this.password, this.phone.toString(), this.datePipe.transform(this.dateToday, "MMM d, y, h:mm:ss a"));
    if (action == "Add") {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        console.log("false");
      } else {
        this.superAdminService.addAdmin(admin).subscribe(data => {
          this.router.navigate(['superAdmin']).then(() => { })
        })
        console.log("true");
        this.superAdminService.admins.push(admin);
        this.dialogRef.close();
      }
      form.classList.add('was-validated');
    } else if (action == "Edit") { }
  }
  // onSubmite(){
  //   console.log(this.username,this.email,this.password,this.role,this.datePipe.transform(this.dateToday, "MMM d, y, h:mm:ss a"),this.status);
  // }



  writeUsername(x: any) {
    this.username = x.target.value;
  }
  writeEmail(x: any) {
    this.email = x.target.value;
  }
  writePassword(x: any) {
    this.password = x.target.value;
  }
  writePhone(x: any) {
    this.phone = x.target.value;
  }
  writeDateRegistred(x: any) {
    this.dateRegistred = x.target.value;
  }
  writeDateExp(x: any) {
    this.dateRegistred = x.target.value;
  }

  randomNumber(min, max) {
    return Number(Math.random() * (max - min) + min) | 0;
  }
}
