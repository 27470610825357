import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Admin } from "../_models/admin";
import { SuperAdmin } from "../_models/superAdmin";
import {Globals} from '../_globals/Globals';

@Injectable()
export class SuperAdminService {
    url: string;
    admins: any[] = [];
    superadmins: any[] = [];
    connectedAdmin: Admin;

    constructor(private httpClient: HttpClient) {
        this.url = "https://localhost:7086/api/Admin";
    }
    getAllAdmins() {
        this.httpClient
            .get<Admin[]>(Globals.apiUrl + '/Admin')
            .subscribe(
                (response) => {
                    console.log(response)
                    this.admins = response;
                    console.log(this.admins)

                },
                (error) => {
                    console.log('Erreur ! : ' + error);
                }
            );
    }
    getAdminById(id: number): Observable<any> {

        return this.httpClient
            .get<Admin>(Globals.apiUrl + '/Admin' + '/' + id);
    }
    getAdminByCreds(username: string){
        this.httpClient.get<Admin[]>(Globals.apiUrl + '/Admin' + '/GetAdminByCreds?username=' + username).subscribe(
            (response) => {
                this.connectedAdmin = response[0];
            },
            (error) => {
                    console.log("Error ! "+error)
            }
        );
    }
    addAdmin(admin: Admin): Observable<any> {
       return this.httpClient.post(Globals.apiUrl + '/Admin', admin);
    }
    delAdmin(id: number) {
        this.httpClient.delete(Globals.apiUrl + '/Admin' + '/' + id).subscribe(data => {
            console.log(data);
        });
    }
    updateAdmin(admin: any): Observable<any> {
        return this.httpClient.put(Globals.apiUrl + '/Admin' + '/' + admin.id, admin);
    }

    isAdmin(name: string, password: string) {
        this.getAllAdmins();

        for (let admin of this.admins) {
            if (admin.name == name) {
                return true;
            }
        }
        return false
    }

    getAllSuperAdmins() {
        this.httpClient
            .get<SuperAdmin[]>(Globals.apiUrl + '/Admin')
            .subscribe(
                (response) => {
                    console.log(response)
                    this.superadmins = response;
                    console.log(this.admins)

                },
                (error) => {
                    console.log('Erreur ! : ' + error);
                }
            );
    }


}

