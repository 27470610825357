import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { HttpClient } from '@angular/common/http';
import { GapAnalysis } from 'src/app/_models/gapanalysis';
import * as XLSX from 'xlsx';
import { GapAnalysisService } from '../../_services/gap-analysis.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Role } from 'src/app/_models/Role';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';


@Component({
  selector: 'app-gapanalysis',
  templateUrl: './gapanalysis.component.html',
  styleUrls: ['./gapanalysis.component.scss']
})
export class GapanalysisComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('importDialogTemplate') importDialogTemplate!: TemplateRef<any>;
  gapAnalyses: GapAnalysis[];
  id: string;
  date: any;
  searchTerm: string;
  isLoading = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['idReference', 'reference', 'controlType', 'requirement', 'currentState', 'comment'];
  selectedOption = '';
  referenceInput = '';
  selectedReferences: any;
  options: string[] = [
    'Organizational Controls',
    'People Controls',
    'Physical Controls',
    'Technological Controls'
  ];

  states = [
    { value: 'compliant', viewValue: 'Compliant' },
    { value: 'minor', viewValue: 'Minor Non-Conformity' },
    { value: 'major', viewValue: 'Major Non-Conformity' },
    { value: 'non-applicable', viewValue: 'Non-Applicable' },
  ];

  references: string[] = []; // To store unique references

  user: User;
  constructor(private http: HttpClient,
    public adminService: AdminService,
    public gapAnalysisService: GapAnalysisService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService) {

    this.authenticationService.user.subscribe(x => this.user = x);

  }

  ngOnInit() {
    this.getAllGapAnalysis();
  }

  processData(data: any[]) {
    this.dataSource = new MatTableDataSource<any>(data);
  }

  getAllGapAnalysis(): Promise<void> {
    return this.gapAnalysisService.getGapAnalysis().toPromise()
      .then((data: any) => {
        this.processData(data);
        this.dataSource.paginator = this.paginator; // Bind paginator to the data source
        // Display only 5 rows initially
        this.dataSource.paginator.pageSize = 5;
        this.getUniqueReferences();
      })
      .catch((error: any) => {
        console.error('Error in getAllGapAnalysis:', error);
        throw error; // Propagate the error to the caller
      });
  }


  onFileChange(event: any) {
    /* wire up file reader */
    const target: DataTransfer = (event.target) as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      this.isLoading = true;
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.gapAnalyses = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

      // Define a mapping of old keys to new keys
      const keyMap = {
        NO: 'idReference',
        REQUIREMENT: 'requirement',
      };

      // Function to create a new object with empty fields
      const addEmptyFields = (item) => {
        const newItem = { ...item };
        newItem.controlType = this.selectedOption;
        newItem.reference = this.referenceInput;
        newItem.applicability = 'NO';
        newItem.currentState = '';
        newItem.comment = '';
        return newItem;
      };

      // Rename keys in each object
      const renamedData = this.gapAnalyses.map(item => {
        const renamedItem = {};
        for (const key in item) {
          const newKey = keyMap[key] || key;
          renamedItem[newKey] = item[key];
        }
        return addEmptyFields(renamedItem);
      });
      this.gapAnalysisService.addGapAnalysis(renamedData).subscribe((data: any) => {
        // close option dialog
        this.closeImportOptionsDialog();
        this.isLoading = false;
        // put the data in data table
        this.dataSource.data = data;
      });
    };
  }


  exportToExcel(data: any[], fileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display:none');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  exportData(event: any): void {
    console.log(this.dataSource.data);
    // Get current date (YYYY-MM-DD format)
    const currentDate = new Date().toISOString().slice(0, 10);
    this.exportToExcel(this.dataSource.data, `gap-analysis-${currentDate}`);
    this.onFileChange(event);
  }

  saveChanges(item: any) {
    if (!this.isUserAllowed()) {
      console.error('Attempt to save with non-allowed user role');
      return;
    }
    this.gapAnalysisService.updateGapAnalysis(item).subscribe(
      (response: any) => {
      },
      (error) => {
        console.error('Error saving changes for item:', item, 'Error:', error);
      }
    );
  }

  openImportDialog() {
    this.dialog.open(this.importDialogTemplate);
  }

  closeImportOptionsDialog() {
    this.dialog.closeAll(); // Close the dialog
  }

  filterItems() {
    if (this.searchTerm != "") {
      this.getAllGapAnalysis().then(() => {
          this.dataSource.data = this.dataSource.data.filter(res => {
          return res.controlType.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
        });
      });
    } else if (this.searchTerm === "") {
      this.getAllGapAnalysis();
    }
  }
  isUserAllowed() {
    const roles = [Role.RSSI, Role.CISO];
    return roles.includes(this.user?.role);
  }

  applyReferenceFilter() {
    let accumulatedData = [];

    if (this.selectedReferences.length > 0) {
      const observables = this.selectedReferences.map((selectedRef: string) =>
        this.gapAnalysisService.getGapAnalysisByReference(selectedRef)
      );

      for (const observable of observables) {
        observable.subscribe(
          data => {
            accumulatedData = accumulatedData.concat(data);
            this.dataSource.data = accumulatedData;
          },
          error => {
            console.error('Error fetching data:', error);
          }
        );
      }
    } else {
      this.getAllGapAnalysis();
    }
  }

  getUniqueReferences() {
    this.references = Array.from(new Set(this.dataSource.data.map(item => item.reference)));
  }

}



