import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalService } from 'src/app/_services/local.service';
import {UserService} from "../../_services/administration-services/user.service";
import {DashboardService} from "../../_services/dashboard.service";
import {Cve} from "../../_models/cve";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dateToday: number = Date.now();
  date: string = this.datePipe.transform(this.dateToday, "MMM d, y");
  cveDetailURL: string = "https://nvd.nist.gov/vuln/detail/CVE-2021-43228"
  tooltip: string = "ok!!!!!!";
  nbrOnLine: any;
  nbrTotalUsers: number;
  cves: Cve[];
  obj = {
    primary: "#6571ff",
    secondary: "#7987a1",
    success: "#05a34a",
    info: "#66d1d1",
    warning: "#fbbc06",
    danger: "#ff3366",
    light: "#e9ecef",
    dark: "#060c17",
    muted: "#7987a1",
    gridBorder: "rgba(77, 138, 240, .15)",
    bodyColor: "#000",
    cardBg: "#fff",
    fontFamily: "'Roboto', Helvetica, sans-serif",
    green: "#238823",
    red: "#FF0000",
    blue: "#0000FF",
  }

  constructor(private datePipe: DatePipe,
              public adminService: AdminService,
              private localStorage: LocalService,
              private userService: UserService,
              private dashboardService: DashboardService
  ) {
    this.adminService.getSensitizedUsers();
    this.adminService.getnbrOfAssetsByType(this.localStorage.getData("id"));
  }
  list: number =  this.adminService.nbrOfInformationAssets;

  ngOnInit() {
    this.getNbrOfOnLineUsers();
    this.getNbrOfTotalUsers();
    this.getCve();
    this.doughnutChartData.forEach(ds => {
      ds.backgroundColor = ['red', 'black', 'green', "purple", "yellow", "blue", "grey"];
      ds.hoverBackgroundColor = ["lightgrey", "grey", "lightgrey", "grey", "lightgrey", "grey", "lightgrey"];
    });
  }

  getNbrOfOnLineUsers() {
    this.userService.getOnlineUsers().subscribe(data => {
      this.nbrOnLine = data.length;
    });
  }

  getNbrOfTotalUsers() {
    this.userService.getAllUsers(this.localStorage.getData("id")).subscribe(data => {
      this.nbrTotalUsers = data.length;
    });
  }

  getCve() {
    this.dashboardService.getCVE().subscribe(data => {
      this.cves = data;
    });
  }

  sideBarOpen = true;

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  splitString(str: string):string {

     var splitted = str.split(":");
    return splitted[3] +" "+ splitted[4]+" "+ splitted[5];
  }



  onTest() {
    this.list= this.adminService.nbrOfInformationAssets;
    console.log("dashboard:", [this.adminService.nbrOfAssets_Negligible, this.adminService.nbrOfAssets_Low, this.adminService.nbrOfAssets_Moderate, this.adminService.nbrOfAssets_High, this.adminService.nbrOfAssets_VeryHigh]);
    console.log("tttt  "+this.adminService.nbrOfInformationAssets+ "list:"+this.list);

  }
  /* getCveSeverity(cve:string):string{
    this.adminService.getCveInformations(cve);
    return this.adminService.cveInformations.result.CVE_Items[0].cve.data_format;

  } */

  // NATIONAL VULNERABILITY DATABASE
  openCveDetails(cveId: string) {
    console.log(cveId);
  }

  doughnutChartType: ChartType = 'doughnut';

  doughnutChartLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  doughnutChartData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],

    }
  ];

  doughnutChartOption: ChartOptions = { tooltips: { enabled: true, titleFontSize: 16, bodyFontSize: 14, } };

  lineType: ChartType = 'polarArea';

  lineLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  lineData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'Dataset B',
      data: [35, 20, 10, 10, 15, 15, 10],
    }, {
      label: 'Dataset C',
      data: [40, 15, 5, 15, 20, 5, 20],
    }
  ];

  lineOption: ChartOptions = {}
  lineType2: ChartType = 'line';

  lineLabels2: Label[] = ["1500", "1600", "1700", "1750", "1800", "1850", "1900", "1950", "1999", "2050"];

  lineData2: ChartDataSets[] = [
    {
      data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478],
      label: "Parameter 1",
      borderColor: this.obj.info,
      backgroundColor: "transparent",
      fill: true,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderColor: this.obj.info,
      pointHoverBorderColor: this.obj.info,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }, {
      data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267],
      label: "Parameter 2",
      borderColor: this.obj.danger,
      backgroundColor: "transparent",
      fill: true,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderColor: this.obj.danger,
      pointHoverBorderColor: this.obj.danger,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }
  ];

  lineOption2: ChartOptions = {
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 2000,
      easing: 'easeInOutElastic',
    }
  }



  //Risk Assessment Status
  doughnutChartType1: ChartType = 'doughnut';

  doughnutChartLabels1: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  doughnutChartData1: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    },
  ];

  doughnutChartOption1: ChartOptions = { plugins: { datalabels: { tooltips: { enabled: true, titleFontSize: 26, bodyFontSize: 34, } } } };

  doughnutChartType2: ChartType = 'doughnut';

  doughnutChartLabels2: Label[] = ['Assessed', 'Not Assessed'];

  doughnutChartData2: ChartDataSets[] = [
    {
      label: 'Data',
      data: [33, 17],
      fill: true,
      backgroundColor: [this.obj.green, this.obj.red],

    },
  ];

  doughnutChartOption2: ChartOptions = {
    responsiveAnimationDuration: 20000,
  };
  //legend: { display: true,labels:{fontSize:38},position:'bottom' } : legend on the bottom



  lineType1: ChartType = 'polarArea';

  lineLabels1: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  lineData1: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [33, 21, 9, 9, 11, 13, 5],
    },
  ];

  lineOption1: ChartOptions = { scales: { yAxes: [{ ticks: { lineHeight: 10 } }] } }

  areaType: ChartType = 'line';

  areaLabels: Label[] = ['01-01-2022', '01-03-2022', '01-05-2022', "01-07-2022", "01-09-2022", "01-11-2022", "01-01-2023"];

  areaData: ChartDataSets[] = [
    {
      label: 'Critical',
      data: [33, 21, 9, 9, 11, 13, 5],
    }, {
      label: 'High',
      data: [16, 38, 20, 10, 1, 15, 10],
    },
  ];

  areaOption: ChartOptions = { animation: { duration: 1000, easing: 'easeInOutQuad', } }

  pieType: ChartType = 'pie';

  pieLabels: Label[] = ["User 1", "User 2", "User 3", "User 4", "User 5", "User 6", "User 7"];

  pieData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [8, 19, 14, 10, 12, 15, 10],
      backgroundColor: [this.obj.primary, this.obj.danger, this.obj.info, this.obj.secondary, this.obj.success, this.obj.warning, this.obj.light],
      hoverBackgroundColor: [this.obj.primary, this.obj.danger, this.obj.info],
      borderColor: this.obj.cardBg,
      hoverBorderColor: [this.obj.primary, this.obj.danger, this.obj.info],

    },
  ];

  pieOption: ChartOptions = {}

  // Asset Type---------------------------
  assetType: ChartType = 'pie';

  assetLabels: Label[] = ["Paper", "Software", "Hardware", "Service", "People", "Information"];

  assetData: ChartDataSets[] = [
    {
      label: 'Dataset A',
      data: [8, 19, 14, 10, 12, 25],
      backgroundColor: [this.obj.primary, this.obj.danger, this.obj.info, this.obj.secondary, this.obj.success, this.obj.warning, this.obj.light],
      hoverBackgroundColor: [this.obj.primary, this.obj.danger, this.obj.info],
      borderColor: this.obj.cardBg,
      hoverBorderColor: [this.obj.primary, this.obj.danger, this.obj.info],

    },
  ];

  assetOption: ChartOptions = {}
  //Asset Criticality---------------------------
  assetCriticalityType: ChartType = 'bar';

  assetCriticalityLabels: Label[] = ['Negligible', 'Low', 'Moderate', "High", "Very High"];

  assetCriticalityData: ChartDataSets[] = [
    {
      label: 'Number of Assets',
      backgroundColor: this.obj.blue,
      hoverBackgroundColor: this.obj.blue,
      borderColor: this.obj.blue,
      hoverBorderColor: this.obj.blue,
      data: [33, 21, 9, 9, 11, 13, 5],

    }
  ];

  assetCriticalityOption: ChartOptions = {}
  //Risk Assessed---------------------------
  riskAssessedType: ChartType = 'bar';

  riskAssessedLabels: Label[] = [];

  riskAssessedData: ChartDataSets[] = [
    {
      label: 'Updated',
      backgroundColor: this.obj.green,
      hoverBackgroundColor: this.obj.green,
      borderColor: this.obj.green,
      hoverBorderColor: this.obj.green,
      data: [20, 10],

    },
    {
      label: 'Not Updated',
      backgroundColor: this.obj.red,
      hoverBackgroundColor: this.obj.red,
      borderColor: this.obj.red,
      hoverBorderColor: this.obj.red,
      data: [15, 10],

    }
  ];

  riskAssessedOption: ChartOptions = {}

  radarType: ChartType = 'radar';

  radarLabels: Label[] = ["Critical", "High", "Moderate", "Low", "Negligible"];

  radarData: ChartDataSets[] = [
    {
      label: "Updated",
      fill: true,
      backgroundColor: "rgba(255,51,102,.3)",
      borderColor: this.obj.danger,
      pointBorderColor: this.obj.danger,
      pointHoverBorderColor: this.obj.danger,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
      data: [8, 55, 21, 6, 6]
    }, {
      label: "Not Updated",
      fill: true,
      backgroundColor: "rgba(102,209,209,.3)",
      borderColor: this.obj.info,
      pointBorderColor: this.obj.info,
      pointHoverBorderColor: this.obj.info,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
      data: [25, 54, 7, 8, 4]
    }
  ];

  radarOption: ChartOptions = {}


  polarType: ChartType = 'polarArea';

  polarLabels: Label[] = ["OK Audits %", "Missing Audits %", "Failed Audits %", "Future Audits %"];

  polarData: ChartDataSets[] = [
    {
      label: "Population (millions)",
      backgroundColor: [this.obj.primary, this.obj.danger, this.obj.success, this.obj.info],
      hoverBackgroundColor: [this.obj.primary, this.obj.danger, this.obj.success, this.obj.info],
      borderColor: this.obj.cardBg,
      hoverBorderColor: [this.obj.primary, this.obj.danger, this.obj.success, this.obj.info],
      data: [60, 8, 22, 10]
    }
  ];

  polarOption: ChartOptions = { scales: { yAxes: [{ ticks: { fontSize: 10 } }] } }


  groupedBarType: ChartType = 'horizontalBar';

  groupedBarLabels: Label[] = ['INFORMATION SECURITY POLICY', 'ORGANIZATION OF INFORMATION SECURITY', 'HUMAN RESOURCES SECURITY', "ASSET MANAGEMENT", "ACCESS CONTROL", "CRYPTOGRAPHY", "PHYSICAL AND ENVIRONMENTAL SECURITY", "OPERATION SECURITY", "COMMUNICATION SECURITY", "SYSTEM ACQUISITION", "SUPPLIER RELATIONSHIP", "INFORMATION SECURITY INCIDENT MANAGEMENT", "INFORMATION SECURITY INCIDENT MANAGEMENT", "INFORMATION SECURITY ASPECTS OF BUSINESS CONTINUITY", "COMPLIANCE"];

  groupedBarData: ChartDataSets[] = [
    {
      label: "Non-compliance",
      backgroundColor: this.obj.red,
      hoverBackgroundColor: this.obj.red,
      borderColor: this.obj.red,
      hoverBorderColor: this.obj.red,
      data: [90, 75, 15, 23, 80, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    }, {
      label: "Compliance",
      backgroundColor: this.obj.green,
      hoverBackgroundColor: this.obj.green,
      borderColor: this.obj.green,
      hoverBorderColor: this.obj.green,
      data: [10, 25, 85, 77, 20, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0],
    }
  ];

  groupedBarOption: ChartOptions = { responsiveAnimationDuration: 10000, scales: { yAxes: [{ ticks: { fontSize: 7 } }] } }

  mixedType: ChartType = 'bar';

  mixedLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  mixedData: ChartDataSets[] = [
    {
      label: "Europe",
      type: "line",
      borderColor: this.obj.danger,
      backgroundColor: "transparent",
      data: [408, 547, 675, 734],
      fill: false,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderColor: this.obj.danger,
      pointHoverBorderColor: this.obj.danger,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }, {
      label: "Africa",
      type: "line",
      borderColor: this.obj.primary,
      backgroundColor: "transparent",
      data: [133, 221, 783, 2478],
      fill: false,
      pointBackgroundColor: this.obj.cardBg,
      pointHoverBackgroundColor: this.obj.cardBg,
      pointBorderColor: this.obj.primary,
      pointHoverBorderColor: this.obj.primary,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 3,
    }, {
      label: "Europe",
      type: "bar",
      backgroundColor: this.obj.danger,
      hoverBackgroundColor: this.obj.danger,
      borderColor: '',
      data: [408, 547, 675, 734],
    }, {
      label: "Africa",
      type: "bar",
      backgroundColor: this.obj.primary,
      hoverBackgroundColor: this.obj.primary,
      borderColor: '',
      data: [133, 221, 783, 2478]
    }
  ];

  mixedOption: ChartOptions = {}

  bubbleType: ChartType = 'bubble';

  bubbleLabels: Label[] = ['SPD', 'CDU', 'Grüne', "Linke", "FDP", "AFD", "Andere"];

  bubbleData: ChartDataSets[] = [
    {
      label: "China",
      backgroundColor: 'rgba(102,209,209,.3)',
      hoverBackgroundColor: 'rgba(102,209,209,.3)',
      borderColor: this.obj.info,
      hoverBorderColor: this.obj.info,
      data: [{
        x: 21269017,
        y: 5.245,
        r: 15
      }]
    }, {
      label: "Denmark",
      backgroundColor: "rgba(255,51,102,.3)",
      hoverBackgroundColor: "rgba(255,51,102,.3)",
      borderColor: this.obj.danger,
      hoverBorderColor: this.obj.danger,
      data: [{
        x: 258702,
        y: 7.526,
        r: 10
      }]
    }, {
      label: "Germany",
      backgroundColor: "rgba(101,113,255,.3)",
      hoverBackgroundColor: "rgba(101,113,255,.3)",
      borderColor: this.obj.primary,
      hoverBorderColor: this.obj.primary,
      data: [{
        x: 3979083,
        y: 6.994,
        r: 15
      }]
    }, {
      label: "Japan",
      backgroundColor: "rgba(251,188,6,.3)",
      hoverBackgroundColor: "rgba(251,188,6,.3)",
      borderColor: this.obj.warning,
      hoverBorderColor: this.obj.warning,
      data: [{
        x: 4931877,
        y: 5.921,
        r: 15
      }]
    }
  ];

  bubbleOption: ChartOptions = {}


  test() {
    console.log("test");
  }

}
