<ul class="nav justify-content-center ">
    
    <li class="nav-item ">
      <a class="title" i18n>Likelihood</a>
    </li>
    
  </ul>
<table class="styled-table">
    <thead>
        <tr>
            <th></th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="active-row" i18n>Exploitation of vulnerability</td>
            <td i18n>Very complex: User with unlimited capabilities</td>
            <td i18n>Complex: User with significant capacities</td>
            <td i18n>Moderately complex: User with average capacities</td>
            <td i18n>Easy: User with limited capabilities</td>
            <td i18n>Very Easy: User with no capacity</td>
            
        </tr>
        <tr>
            <td  class="active-row" i18n>Facility of discovery of the vulnerability</td>
            <td i18n>Practically impossible</td>
            <td i18n>Difficult</td>
            <td i18n>Moderately easy</td>
            <td i18n>Easy</td>
            <td i18n>Very Easy</td>
            
        </tr>
        <tr>
            <td class="active-row" i18n>Probability of occurrence of the incident scenario </td>
            <td i18n>Not likely</td>
            <td i18n>Unlikely: May occur infrequently</td>
            <td i18n>Moderately probable: Could occur once a year</td>
            <td i18n>Likely: Could occur more than once a year (2-3 times a year)</td>
            <td i18n>Very likely (more than 3 times a year)</td>
        </tr>
        <tr>
            <td class="active-row" i18n>Motivation</td>
            <td i18n>No rewards</td>
            <td i18n>Few rewards</td>
            <td i18n>Possible rewards</td>
            <td i18n>High profitability</td>
            <td i18n>Very high profitability</td>
        </tr>
        <!-- and so on... -->
    </tbody>
</table>