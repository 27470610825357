<h2 i18n>Add New Asset</h2>

<ul class="nav nav-tabs">
  <li class="nav-item" style="padding-right: 10px;"> <!-- Adjust the padding as needed -->
    <a class="nav-link active" [ngStyle]="{'color': test1 ? 'blue' : 'black'}" (click)="activateTab(1)" i18n>General Information</a>

  </li>
  <li class="nav-item">
    <a class="nav-link active" [ngStyle]="{'color': test2 ? 'blue' : 'black'}" (click)="activateTab(2)" i18n>Classification</a>
  </li>
</ul>

<form [formGroup]="assetForm" class="needs-validation">
  <div *ngIf="test1">
    <div class="select me-2">
      <label for="exampleFormControlInput1" class="form-label " i18n>Type </label>
        <select class="form-select mt-0 " aria-label="Default select example" formControlName="type" name="type">
          <option value="" disabled selected hidden i18n>Choose the type of asset</option>
          <option value="Information" i18n>Information</option>
          <option value="Paper" i18n>Paper</option>
          <option value="People" i18n>People</option>
          <option value="Software" i18n>Software</option>
          <option value="Service" i18n>Service</option>
          <option value="Hardware" i18n>Hardware</option>
        </select>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n> Category</label>
      <input type="text" class="form-control" placeholder="category" formControlName="category">
    </div>


    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n>Vendor </label>
      <input placeholder="Search vendor" class="form-control" nz-input  [(ngModel)]="vendor" formControlName="vendor"
        (ngModelChange)="onChangeVendor($event) "
             [nzAutocomplete]="auto" />
        <span *ngIf="searching" class="search">
          <mat-icon class="mt-2">search</mat-icon>        Searching...
        </span>
      <nz-autocomplete [nzDataSource]="filteredOptions1" #auto></nz-autocomplete>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n>CPE </label>
      <input [disabled]="product_input_disabled" class="form-control" formControlName="name" placeholder='Search CPE' nz-input [(ngModel)]="name"
        (ngModelChange)="onChange($event)" [nzAutocomplete]="autocomp" />
      <nz-autocomplete [nzDataSource]="filteredOptions" #autocomp></nz-autocomplete>
    </div>


    <div class="select me-2">
      <label for="exampleFormControlInput1" class="form-label " i18n>Status </label>
        <select class="form-select mt-0 " aria-label="Default select example" formControlName="status" name="status">
          <option value="Default" disabled selected hidden></option>
          <option value="Active" i18n="active">Active</option>
          <option value="Not Active" i18n="notActive">Not Active</option>
        </select>

    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n> Quantity</label>
      <input
        type="number"
        class="form-control"
        id="exampleFormControlInput1"
        placeholder="Quantity"
        formControlName="quantity"
        min="1"
      />
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label"

      i18n> Description</label>
      <textarea type="text" class="form-control" id="exampleFormControlInput1" placeholder="Description" formControlName="description"></textarea>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n> Location</label>
      <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Location" formControlName="location">
    </div>

    <div class="select">
      <label for="exampleFormControlInput1" class="form-label" i18n>Custodian </label>
      <select class="form-select" aria-label="Default select example" formControlName="custodian" name="custodian">
        <option value="" disabled selected hidden i18n="choose">Choose one..</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
    <div class="select">
      <label for="exampleFormControlInput1" class="form-label" i18n>User </label>
      <select class="form-select " aria-label="Default select example" formControlName="user" name="user">
        <option value="" disabled selected hidden i18n="choose">Choose one..</option>
        <option *ngFor="let user of users" [value]="user.id">{{user.firstName}} - {{user.lastName}}</option>
      </select>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label" i18n> Reviews</label>
      <input type="number" class="form-control" placeholder="Reviews" formControlName="reviews">
    </div>

    <div class="select">
      <label for="exampleFormControlInput1" class="form-label" i18n>Owner </label>
      <select class="form-select " aria-label="Default select example" formControlName="owner" name="owner">
        <option value="" disabled selected hidden i18n="choose">Choose one..</option>
        <option *ngFor="let user of users" [value]="user.id">{{user.firstName}} - {{user.lastName}}</option>
      </select>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onNext()" i18n="next">Next</button>
    </div>

  </div>
  <div *ngIf="test2">
    <div>
      <label for="exampleFormControlInput1" class="form-label"  i18n>Confidentiality </label>
      <span><i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="help"
          (click)="infoConfidentiality()"></i></span>
      <select class="form-select" aria-label="Default select example" formControlName="confidentiality" name="confidentiality">
        <option value="" disabled selected hidden i18n="choose">Choose one..</option>
        <option value="Negligible" i18n>Negligible</option>
        <option value="Low" i18n>Low</option>
        <option value="Moderate" i18n>Moderate</option>
        <option value="High" i18n>High</option>
        <option value="Very High" i18n>Very High</option>
      </select>
    </div>
    <div>
      <label for="exampleFormControlInput1" class="form-label"  i18n>Integrity </label>
      <span><i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="help"
          (click)="infoIntegrity()"></i></span>
      <select class="form-select" aria-label="Default select example" formControlName="integrity" name="integrity" >
        <option value="" disabled selected hidden i18n="choose">Choose one..</option>
        <option value="Negligible" i18n>Negligible</option>
        <option value="Low" i18n>Low</option>
        <option value="Moderate" i18n>Moderate</option>
        <option value="High" i18n>High</option>
        <option value="Very High" i18n>Very High</option>
      </select>
    </div>
    <div>
      <label for="exampleFormControlInput1" class="form-label">Availability </label>
      <span><i class="bi bi-info-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="help"
          (click)="infoAvailability()"></i></span>
      <select class="form-select" aria-label="Default select example" formControlName="availability" name="availability" >
        <option value="N/A" disabled selected hidden i18n="choose">Choose one..</option>
        <option value="Negligible" i18n>Negligible</option>
        <option value="Low" i18n>Low</option>
        <option value="Moderate" i18n>Moderate</option>
        <option value="High" i18n>High</option>
        <option value="Very High" i18n>Very High</option>
      </select>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onPrevious()" i18n="next">Previous</button>
      <button type="submit" class="btn btn-primary" i18n="submit" (click)="onSubmit()">Submit</button>
    </div>

  </div>
</form>
