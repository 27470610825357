import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent implements OnInit {
  @Input() errorMessage = '';

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.openSnackBar();
  }

  openSnackBar() {
    this.snackBar.open(this.errorMessage, 'Close', {
      duration: 3000, // Adjust duration in milliseconds as needed
      horizontalPosition: 'center', // Modify position as per your design
      verticalPosition: 'top' // Modify position as per your design
    });
  }
}
