import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/_models/Role';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-riskreview',
  templateUrl: './riskreview.component.html',
  styleUrls: ['./riskreview.component.scss']
})
export class RiskreviewComponent implements OnInit {
  user: User;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.user.subscribe(x => this.user = x);

   }

  ngOnInit(): void {
  }
  sideBarOpen = true;

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
}

  openDialog(): void {
    //this.dialog.open(AdduserComponent);

}
canAdd() {
  const roles = [Role.RSSI, Role.CISO, Role.CyberAnalyst, Role.AssetOwner];
  return roles.includes(this.user?.role);
}
canEdit() {
  return this.canAdd();
}
}
