<div class="container-fluid page">
  <div class="d-flex page__box p-4 mt-1" i18n>Risk Assessment & Treatment</div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header" style="background-color: #06375e;">
        <h4 i18n>Reviews</h4>
        <p i18n>Each Risk will be reviewed regularly to ensure its accuracy. Reviews are stored in this section.</p>
      </div>
      <div class="card-body">

        <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                  <form class="d-flex">
                    <input class="form-control mt-0 me-2 " type="search" placeholder="Search" aria-label="Search">
                    <button class="btn btn-outline-success mt-0 " type="submit" i18n>Search</button>
                    <ng-container *ngIf="canAdd()">
                    <span><button class="btn btn-outline-primary mt-0" i18n>Add item</button></span>
                  </ng-container>
                  </form>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table mat-elevation-z8 table-spacing">
                        <thead>
                          <tr>
                            <ng-container *ngIf="canEdit()">
                            <th i18n>Actions</th>
                            </ng-container>
                            <th i18n>Status</th>
                            <th i18n>Review Date</th>
                            <th i18n>Reviewer</th>
                            <th i18n>Description</th>
                            <th i18n>Next planned Review Date</th>
                            <th i18n>Next planned reviewer</th>


                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <ng-container *ngIf="canEdit()">
                            <td>
                              <mat-icon>edit</mat-icon> <span>
                                <mat-icon>delete</mat-icon>
                              </span>
                            </td>
                          </ng-container>
                            <td><span class="badge bg-danger">Not Completed</span></td>
                            <td><span class="badge bg-secondary">02/02/2022</span></td>
                            <td>Farouk RHIMI</td>
                            <td>...</td>
                            <td><span class="badge bg-secondary">02/02/2023</span></td>
                            <td>Johnny</td>
                          </tr>
                          <tr>
                            <ng-container *ngIf="canEdit()">
                              <td>
                                <mat-icon>edit</mat-icon> <span>
                                  <mat-icon>delete</mat-icon>
                                </span>
                              </td>
                            </ng-container>
                            <td><span class="badge bg-success">Completed </span></td>
                            <td><span class="badge bg-secondary">02/02/2022</span></td>
                            <td>Farouk RHIMI</td>
                            <td>...</td>
                            <td><span class="badge bg-secondary">02/02/2023</span></td>
                            <td> Employee A </td>
                          </tr>
                          <tr>
                            <ng-container *ngIf="canEdit()">
                              <td>
                                <mat-icon>edit</mat-icon> <span>
                                  <mat-icon>delete</mat-icon>
                                </span>
                              </td>
                            </ng-container>
                            <td><span class="badge bg-success">Completed</span></td>
                            <td><span class="badge bg-secondary">02/02/2022</span></td>
                            <td>Farouk RHIMI</td>
                            <td>--</td>
                            <td><span class="badge bg-secondary">02/02/2022</span></td>
                            <td>Reviewer</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
