<mat-toolbar color = "primary">
    <span class="fw-bolder fs-3"><mat-icon>eco</mat-icon> Trust Bulletin</span>
    <div class="spacer"></div>
    <a  class="btn fw-bold fs-5" (click)="toDashboard()">Dashbord</a>
    <a href="#" class="btn fw-bold fs-5">Clients</a>
    <a href="#" class="btn fw-bold fs-5">Standards</a>

    <span class = "filler"></span>
    <span><mat-icon (click)="logOut()" style="cursor: pointer;">logout</mat-icon></span>
 </mat-toolbar>
