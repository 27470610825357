import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../_globals/Globals';
import {DocumentUpdate} from '../../_models/documentUpdate';

@Injectable({
  providedIn: 'root'
})
export class DocumentUpdateService {
  constructor(private http: HttpClient) {
  }

  addDocumentUpdate(documentUpdate: DocumentUpdate): Observable<any> {
    return this.http.post(Globals.apiUrl + '/DocumentUpdate', documentUpdate);
  }
  delDocumentUpdate(id: number) {
    return this.http.delete(Globals.apiUrl + '/DocumentUpdate' + '/' + id);
  }
  updateDocumentUpdate(documentUpdate: any): Observable<any> {
    return this.http.patch(Globals.apiUrl + '/DocumentUpdate' + '/' + documentUpdate.id, documentUpdate);
  }

  getDocumentUpdate(id: number) {
    this.http.get<DocumentUpdate>(Globals.apiUrl + '/DocumentUpdate' + '/' + id);
  }
  getUpdateOfDocument(id_document: number) {
    return this.http.get<DocumentUpdate[]>(Globals.apiUrl + '/DocumentUpdate' + '/GetUpdatesOfDocument?id_document=' + id_document);
  }
}
