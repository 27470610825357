import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Admin } from 'src/app/_models/admin';
import { SuperAdminService } from 'src/app/_services/super-admin.service';
import { AddClientComponent } from '../add-client/add-client.component';
import { AddclientComponent } from '../addclient/addclient.component';



@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.scss']
})
export class SuperadminComponent implements OnInit {

  constructor(private route:Router,public dialog:MatDialog,public superAdminService: SuperAdminService) {
    this.superAdminService.getAllAdmins();
  }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialog.open(AddclientComponent);
  }
  openAddDialog(): void {
    this.dialog.open(AddClientComponent,{
      data:{
        action:'Add',
        admin:null,
      }
    });
  }
  openUpdateDialog(admin:Admin): void {
    this.dialog.open(AddClientComponent,{
      data:{
        action:'Edit',
        admin:admin,
      }
    });
  }

  delAdmin(id:number){
    for (const key in this.superAdminService.admins) {
      if (id == this.superAdminService.admins[key].id) {
        const index = this.superAdminService.admins.indexOf(key, 0);
        this.superAdminService.admins.splice(index, 1);
        this.superAdminService.delAdmin(id);
      }
    }
  }


}
