import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/_services/admin.service';
import { LocalService } from 'src/app/_services/local.service';
import { RiskassessmentComponent } from '../risk-assessment/riskassessment.component';
import {RiskService} from '../../../../_services/risque-management-services/risk.service';
import {Risk} from '../../../../_models/risk';
import { Role } from 'src/app/_models/Role';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-risk-list',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss']
})
export class RiskComponent implements OnInit {
  risk: Risk[];
  searchTerm: string;
  user: User;
  constructor(private dialog: MatDialog,
              public riskService: RiskService,
              private localStorage: LocalService,
              private authenticationService: AuthenticationService) {
                this.authenticationService.user.subscribe(x => this.user = x);

   }

  ngOnInit(): void {
    this.getAllRiskList();
  }

  getAllRiskList() {
    this.riskService.getAllRisks(this.localStorage.getData("id")).subscribe(data => {
      this.risk = data;
    });
  }

  openDialog(): void {
    this.dialog.open(RiskassessmentComponent , {
      height: '800px',
      width: '900px',
    });
  }


  filterItems(): void{
    if (this.searchTerm !== '') {
      this.risk = this.risk.filter(res => {
        return res.applicableAsset.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase());
      });
    }else if (this.searchTerm === ''){
      this.getAllRiskList();
    }
  }
  canAdd() {
    const roles = [Role.RSSI, Role.CISO, Role.CyberAnalyst, Role.AssetOwner];
    return roles.includes(this.user?.role);
  }
  canEdit() {
    return this.canAdd();
  }
}
