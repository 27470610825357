<app-toolbarsup></app-toolbarsup>

<div class="marginpage">
<div class="row centre">
  <div class="col-md-3 card-header card-header-danger border  border-dark shadow p-3 mb-5 bg-body rounded">
    <h6 class="card-title">Area chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="lineType2" [labels]="lineLabels2"
                    [datasets]="lineData2" [options]="areaOption">
                  </canvas>
                </div>
  </div>
  <div class="col-md-3 card-header card-header-danger">
    <h4>All items</h4>
    <p> 3 Results - Filter that shows everything</p>
  </div>
  <div class="col-md-3 card-header card-header-danger">
    <h4>All items</h4>
    <p> 3 Results - Filter that shows everything</p>
  </div>
</div>
 
<div class="container-fluid page">
    <div class="d-flex page__box p-3 mt-2">Dashboard</div>
    <div class="page__content shadow p-3 position-relative">

        <div class="row ">
          <div class="col-xl-4 grid-margin stretch-card">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Area chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="lineType2" [labels]="lineLabels2"
                    [datasets]="lineData2" [options]="areaOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-4 grid-margin  ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="doughnutChartType" [labels]="doughnutChartLabels"
                    [datasets]="doughnutChartData" [options]="doughnutChartOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-4 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="lineType" [labels]="lineLabels" [datasets]="lineData"
                    [options]="lineOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="radarType" [labels]="radarLabels"
                    [datasets]="radarData" [options]="areaOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-6 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="polarType" [labels]="polarLabels" [datasets]="polarData"
                    [options]="lineOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
        </div>


       

        <div class="row">
          <div class="col-xl-6 grid-margin stretch-card">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Area chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="areaType" [labels]="areaLabels"
                    [datasets]="areaData" [options]="areaOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-6 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Pie chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="pieType" [labels]="pieLabels" [datasets]="pieData"
                    [options]="lineOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
        </div>

        

        <div class="row">
          <div class="col-xl-6 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="groupedBarType" [labels]="groupedBarLabels"
                    [datasets]="groupedBarData" [options]="areaOption">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-6 grid-margin ">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="mixedType" [labels]="mixedLabels" [datasets]="mixedData"
                    [options]="lineOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-xl-4 grid-margin stretch-card">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="doughnutChartType1" [labels]="doughnutChartLabels1"
                    [datasets]="doughnutChartData1" [options]="doughnutChartOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-4 grid-margin stretch-card">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="bubbleType" [labels]="bubbleLabels" [datasets]="bubbleData"
                    [options]="lineOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
          <div class="col-xl-4 grid-margin stretch-card">
            <div class="card rcorners2">
              <div class="card-body">
                <h6 class="card-title">Bar chart</h6>

                <div style="display: block;">
                  <canvas baseChart [chartType]="lineType1" [labels]="lineLabels1" [datasets]="lineData1"
                    [options]="lineOption1">
                  </canvas>
                </div>

              </div>
            </div>
          </div>
        </div>

        
    
    </div>
  </div>
</div>