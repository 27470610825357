<div class=" scroll">
    <div id="content">
      <p>
        <mat-toolbar>
          <span>Document informations</span>
        </mat-toolbar>
      </p>

      <div class="row mt-2">
        <h5 class="col" i18n>
              ORGANIZATION NAME
          </h5>

          <h5 class="col" i18n>
              DOCUMENT NAME <span class="info">{{this.document?.title}}</span>
          </h5>
          <h5 class="col" i18n>
              DESCRIPTION <span class="info">{{this.document?.shortDescription}}</span>
          </h5>
      </div>
        <div>
            <mat-divider [inset]="false"></mat-divider>

            <form>
                <div class="row mt-2">
                    <h5 class="col" i18n>
                        AUTHORIZED BY<span class="info"> {{this.document?.authBy}}</span>
                    </h5>
                    <h5 class="col" i18n>
                        REVIEWER<span class="info">{{this.document?.reviewer}}</span>
                    </h5>
                    <h5 class="col" i18n>
                        REFERENCE<span class="info"> {{this.document?.reference}}</span>
                    </h5>
                </div>
            </form>
            <form>
                <div class="row mt-0 mb-2">
                    <h5 class="col" i18n>
                        REVIEWER <span class="info">{{this.document?.reviewer}}</span>
                    </h5>
                    <h5 class="col" i18n>
                        REVIEW DATE<span class="info">{{this.document?.reviewDate}}</span>
                    </h5>
                    <h5 class="col" i18n>
                        CLASSIFICATION <span class="info">{{this.document?.classification}}</span>
                    </h5>
                </div>
            </form>

        </div>
      <mat-divider [inset]="false"></mat-divider>

      <p>
        <mat-toolbar>
          <span>Document updates</span>
        </mat-toolbar>
      </p>

      <div class="text-muted">
        <table mat-table [dataSource]="documentUpdates" class="mat-elevation-z8" style="width: 100%;">
          <!-- Version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let update"> {{ update.version }} </td>
          </ng-container>

          <!-- Updated By Column -->
          <ng-container matColumnDef="updatedBy">
            <th mat-header-cell *matHeaderCellDef> Updated by </th>
            <td mat-cell *matCellDef="let update"> {{ update.updatedBy }} </td>
          </ng-container>

          <!-- Updated On Column -->
          <ng-container matColumnDef="updateDate">
            <th mat-header-cell *matHeaderCellDef> Updated on </th>
            <td mat-cell *matCellDef="let update"> {{ update.updateDate }} </td>
          </ng-container>

          <!-- Update Notes Column -->
          <ng-container matColumnDef="updateNotes">
            <th mat-header-cell *matHeaderCellDef> Update notes </th>
            <td mat-cell *matCellDef="let update"> {{ update.updateNotes }} </td>
          </ng-container>

          <!-- Define columns to display -->
          <tr mat-header-row *matHeaderRowDef="['version', 'updatedBy', 'updateDate', 'updateNotes']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['version', 'updatedBy', 'updateDate', 'updateNotes']"></tr>
        </table>
      </div>

        <mat-divider [inset]="false"></mat-divider>

        <div style="margin-top: 3%">
          <p>
            <mat-toolbar>
              <span>Document content</span>
            </mat-toolbar>
          </p>
          <div *ngFor="let paragraph of paragraphs">
                <h2>{{paragraph.title}} </h2>
                <h5>{{paragraph.documentControl.interpretation}} </h5>
            </div>
        </div>
    </div>

</div>

<div class="modal-footer">
  <div class="btn-toolbar">
    <button type="button" class="btn btn-secondary me-2" (click)="onClose()" data-bs-dismiss="modal" i18n="close|close">Close</button>
    <button type="button" class="btn btn-primary me-2" (click)="generatePdf()" i18n>Download PDF</button>
    <button *ngIf="data.isApprove" type="button" class="btn btn-primary" (click)="approveDocument()" i18n>Approve Document</button>
  </div>
</div>
