import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Admin } from "../_models/admin";
import { Asset } from "../_models/asset";
import { Audit } from "../_models/audit";
import { Control } from "../_models/control";
import { ControlANSI } from "../_models/controlANSI";
import { Cve } from "../_models/cve";
import { Document } from "../_models/document";
import { DocumentControl } from "../_models/documentControl";
import { DocumentUpdate } from "../_models/documentUpdate";
import { GapAnalysis } from "../_models/gapanalysis";
import { Incident } from "../_models/incident";
import { Paragraph } from "../_models/paragraph";
import { Risk } from "../_models/risk";
import { User } from "../_models/user";
import { LocalService } from "./local.service";

import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AdminService {
  url: string = '';
  url2: string;
  url3: string;
  url10: string;
  urlCVE: string;
  urlCPE: string;
  urlCVEDefault: string;
  url20: string;
  url21: string;
  url22: string;
  //urlProducts:string;
  urlNbrOfAssets: string;
  urlNbrOfAssetsByLevel: string;
  urlNbrOfAssetsByType: string;
  //Updateuser: any = null;
  invalidLogin: boolean = false;
  nbrOfAssets: number;
  nbrOfAssets_VeryHigh: number;
  nbrOfAssets_High: number;
  nbrOfAssets_Moderate: number;
  nbrOfAssets_Low: number;
  nbrOfAssets_Negligible: number;
  numberOfSensitizedUsers: number;
  numberOfOnlineUsers: number;
  numberOfUsers: number;
  risk: Risk;
  urlVulnerabilities: string;
  private selectedLanguage: string = 'en';
  constructor(private httpClient: HttpClient, private localStorage: LocalService, private translateService: TranslateService) {
    this.url = "https://localhost:7086/api/User/";
    this.url10 = "https://localhost:7086/api/GapAnalysis";
    this.urlCPE = "https://services.nvd.nist.gov/rest/json/cpes/1.0?resultsPerPage=2000";
    this.urlCVE = "https://services.nvd.nist.gov/rest/json/cpes/1.0?addOns=cves&cpeMatchString=";
    this.urlVulnerabilities = "https://services.nvd.nist.gov/rest/json/cves/1.0?cpeName=";
    this.url20 = "https://localhost:7086/api/User/getSensitizedUsers";
    this.url21 = "https://localhost:7086/api/User/getOnlineUsers";
    this.url22 = "https://localhost:7086/api/Cve";

  }
  users: any[] = [];
  organizations: any[] = [];
  orgContext: any[] = [];
  assets: any[] = [];
  criticalAssets: any[] = [];
  incidents: any[] = [];
  risks: any[] = [];
  ansiControls: any[] = [];
  ansiControlsByType: any[] = [];
  controlsByType: any[] = [];
  audits: any[] = [];
  auditsByStatus: any[] = [];
  gapAnalysis: any[] = [];
  vulnerabilities: any;
  products: any;
  assetVulnerabilities: any[] = [];
  listProducts: any[] = [];
  listVendors: any[] = [];
  listVersions: any[] = [];
  documentControls: any[] = [];
  documents: any[] = [];
  paragraphs: any[] = [];
  paragraphsOfDocument: any[] = [];
  documentUpdates: any[] = [];
  sensitizedUsers: any[] = [];
  onlineUsers: any[] = [];
  cves: any[] = [];
  cveInformations: any;
  doc: Document;
  documentUpdate: DocumentUpdate;
  nbrOfInformationAssets: number;
  nbrOfPaperAssets: number;
  nbrOfSoftwareAssets: number;
  nbrOfHardwareAssets: number;
  nbrOfServiceAssets: number;
  nbrOfPeopleAssets: number;

  setArabic() {
    this.translateService.onLangChange.subscribe(lang => {
      if (lang.lang == 'ar') {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      } else {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
      }

    })
  }



  switchlanguage(language: string) {
    this.translateService.use(language);
  }

  translate(key: string): string {
    return this.translateService.instant(key)
  };

  logOut() {
    localStorage.removeItem("jwt");

  }
  setLanguageInLocalStorage(language: string): void {
    localStorage.setItem('language', language);
  }

  getLanguage(): string {
    // Check if language is stored in localStorage
    /* const storedLanguage = localStorage.getItem('selectedLanguage');
    console.log("lang: "+ storedLanguage)
    if (storedLanguage) {
      return storedLanguage; // Return the stored language if available
    } else {
      return 'default'; // Return the default language if not found
    } */
    return 'en';
  }

  setLanguage(language: string): void {
    localStorage.setItem('selectedLanguage', language); // Store the selected language in localStorage
  }
   setSelectedLanguage(language: string): void {
    localStorage.setItem('selectedLanguage', language);
  }

  getSensitizedUsers() {
    this.httpClient
      .get<User[]>(this.url20)
      .subscribe(
        (response) => {
          console.log(response)
          this.sensitizedUsers = response;
          this.numberOfSensitizedUsers = this.sensitizedUsers.length;
        });
  }

  getnbrOfAssetsByType(idAdmin: string) {
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "Information" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log("Information Asset: " + response)
          this.nbrOfInformationAssets = response;

        },
        (error) => {
          console.log('Erreur ! : ' + error);
        });
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "Software" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log(response)
          this.nbrOfSoftwareAssets = response;

        },
        (error) => {
          console.log('Erreur ! : ' + error);
        });
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "Hardware" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log(response)
          this.nbrOfHardwareAssets = response;

        }
        ,
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "People" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log(response)
          this.nbrOfPeopleAssets = response;

        }
        ,
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "Paper" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log(response)
          this.nbrOfPaperAssets = response;

        }
        ,
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );
    this.httpClient
      .get<number>(this.urlNbrOfAssetsByType + "Service" + "&idAdmin=" + idAdmin)
      .subscribe(
        (response) => {
          console.log(response)
          this.nbrOfServiceAssets = response;

        }
        ,
        (error) => {
          console.log('Erreur ! : ' + error);
        }
      );

  }

  update(id: number, data: any): Observable<any> {
    const url = `${this.url}/${id}`;
    return this.httpClient.put(this.url10, data);
  }

  //----------------------------------------------------------GAP Analysis:
  getAllGapAnalysis() {


    return this.httpClient
      .get<GapAnalysis[]>("https://localhost:7086/api/GapAnalysis/get")

  }
  getGapAnalysisById(id: number): Observable<any> {
    return this.httpClient
      .get<GapAnalysis>(this.url10 + '/' + id)
  }
  updateGapAnalysis(gapAnalysis: any): Observable<any> {
    return this.httpClient.put(this.url10 + '/' + gapAnalysis.id, gapAnalysis);
  }
  //----------------------------------------------------------API Products:
  listCPE: any[] = []
  totalResults: number;
  resultsPerPage: number;
  startIndex: number;
  search_is_finished: boolean = false;
  async getCPE_keywordSearch(url: string, keywordSearch: string): Promise<any> {

    await this.httpClient.get<any[]>(url + "?keywordSearch=" + keywordSearch).toPromise().then(
      (response) => {
        this.products = response;
        this.resultsPerPage = this.products.resultsPerPage;
        this.totalResults = this.products.totalResults;
        (error) => {
          console.log('Error : cannot get cpe with keyword' + error);
        }
      }
    );
    console.log(this.resultsPerPage + "...." + this.totalResults);
    let loop = Math.ceil(this.totalResults / this.resultsPerPage);
    let page = 0;

    for (let i = 0; i < loop; i++) {
      console.log(i);
      try {
        await this.httpClient.get<any[]>(url + "?keywordSearch=" + keywordSearch + "&startIndex=" + page.toString()).toPromise().then(
          (response) => {
            this.products = response;
            for (let index = 0; index < this.resultsPerPage; index++) {
              if (this.products.products[index]){
                this.listCPE.push(this.products.products[index].cpe.cpeName);
              }
            }

            (error) => {
              console.log('Error : cannot get cpe with keyword' + error);
            }
          }
        );
        page = page + 10000;
      } catch (error) {
        console.error(error)
        throw error
      }
      this.search_is_finished = true;
      console.log(this.listCPE.length)

    }
    console.log(this.listCPE)

    console.log("last Response");

  }

  //API Vulnerabilities: for each CPE, get the vulnerabilities associated to it
  getVulnerabilities(cpeID: string, cpeName: string) {
    this.assetVulnerabilities = [];
    this.httpClient.get<any[]>(this.urlVulnerabilities + cpeName).subscribe(
      (response) => {
        console.log(response);
        this.vulnerabilities = response;
        for (let index = 0; index < this.vulnerabilities.resultsPerPage; index++) {
          var element = this.vulnerabilities.result.CVE_Items[index];
          console.log(element.cve.references.reference_data[0].url);
          console.log(element.cve.references.reference_data);

          var cve = new Cve(element.cve.CVE_data_meta.ID, cpeID, cpeName, element.cve.CVE_data_meta.ASSIGNER, element.cve.references.reference_data[0].url, element.cve.description.description_data[0].value, element.impact.baseMetricV2.severity, "baseSeverity", element.publishedDate, element.lastModifiedDate, false, false, "idUser", "idOrg", this.localStorage.getData("id"));
          this.addCVE(cve).subscribe(data => {
            console.log(data);
          },
            (error) => {
              console.log('Error : CVE exist in the database.' + error);
            }
          );

        }
      }
    );


  }

  addCVE(cve: Cve): Observable<any> {

    console.log(cve);
    return this.httpClient
      .post(this.url22, cve)

  }
  getCVE() {
    this.httpClient.get<any[]>(this.url22).subscribe(
      (response) => {
        console.log(response);
        this.cves = response;
      }
    );
  }



  //----------------------------------------------------------Test:

  useShodanApi(keyword: string): Observable<any> {
    return this.httpClient
      .get<any>('https://services.nvd.nist.gov/rest/json/cpes/1.0?keyword=' + keyword);
  }
  getDefaultLanguage(): string {
    return 'en'; // Remplacez 'en' par la langue par défaut de votre choix
  }
}

