import { DatePipe } from '@angular/common';
import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Document } from 'src/app/_models/document';
import { DocumentUpdate } from 'src/app/_models/documentUpdate';
import { Paragraph } from 'src/app/_models/paragraph';
import { LocalService } from 'src/app/_services/local.service';
import {DocumentControl} from "../../../_models/documentControl";
import {DocumentService} from "../../../_services/document-services/document.service";
import {ParagraphService} from "../../../_services/document-services/paragraph.service";
import {UserService} from "../../../_services/administration-services/user.service";
import {User} from "../../../_models/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DocumentUpdateService} from "../../../_services/document-services/document-update.service";
import {log} from "ng-zorro-antd/core/logger";
import {forkJoin, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {version} from "xlsx";

@Component({
  selector: 'app-add-mandatory-document',
  templateUrl: './add-mandatory-document.component.html',
  styleUrls: ['./add-mandatory-document.component.scss']
})
export class AddMandatoryDocumentComponent implements OnInit {
  @Output() documentAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output() documentEdited: EventEmitter<void> = new EventEmitter<void>();

  documentControl: DocumentControl[];
  document: Document;
  paragraphs: Paragraph[];
  paragraphsDocument: any[];
  users: User[];

  documentForm: FormGroup;
  paragraphForm: FormGroup;
  paragraphFormEdit: FormGroup;
  paragraphsFormArray: FormGroup[] = [];
  paragraphsFormArrayAddMode: FormGroup[] = [];

  documentUpdateForm: FormGroup;
  addedParagraphs: any[] = []; // Array to hold added paragraphs


  general: boolean = true;
  policyContent: boolean = false;
  relatedDocuments: boolean = false;
  useContent: boolean = true;
  useAttachment: boolean = false;
  useURL: boolean = false;
  title: string;
  paragraphTitle: string;
  paragraphSubtitle: string;
  shortDescription: string;
  type: string;
  classification: string;
  effectiveDate: string;
  reviews: number;
  reviewer: string;
  reviewDate: string;
  reference: string;
  authBy: string;
  version: string;
  status: string;
  documentBody: string;
  fileToUpload: File | null = null;
  requirement: string;
  id: string;
  interpretation: string;
  updateLevel: string = "minor";
  myDate = new Date();
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  step = 0;
  constructor(public documentUpdateService: DocumentUpdateService,
              private fb: FormBuilder,
              public documentService: DocumentService,
              public paragraphService: ParagraphService,
              public userService: UserService,
              private datePipe: DatePipe,
              private _snackBar: MatSnackBar,
              private localStorage: LocalService,
              private router: Router,
              public dialogRef: MatDialogRef<AddMandatoryDocumentComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                action: string;
                document: any;
    }) {}

  ngOnInit(): void {
    //this.getAllParagraph();
    this.getAllUsers();
    this.getDocumentControl();
    this.initDocumentForm();
    if (this.data.action == "Add") {
      this.initParagraphForm();
    }

    if (this.data.action == "Edit") {
      this.initDocumentUpdateForm();
      this.getDocumentById();
      this.getParagraphOfDocument();
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  // -----------GETTERS-------------//

  getDocumentControl() {
    this.documentService.getDocumentControls().subscribe(documentControl => {
      this.documentControl = documentControl;
    });
  }

  getAllUsers() {
    this.userService.getAllUsers(this.localStorage.getData("id")).subscribe(users => {
      this.users = users;
    });
  }

  getParagraphOfDocument() {
    this.paragraphService.getParagraphOfDocument(this.data.document.id).subscribe(paragraph => {
      this.paragraphsDocument = paragraph;

      //init paragraph form for edit
      this.paragraphsDocument.forEach((addedParagraph, i) => {
        const paragraphFormGroup = this.fb.group({
          title: [addedParagraph.title, Validators.required],
          subtitle: [addedParagraph.subtitle],
          control: [addedParagraph.documentControl.interpretation],
          idDocumentControl: [addedParagraph.documentControl.id]
        });
        this.paragraphsFormArray.push(paragraphFormGroup);
      });
    });
  }

  getUserNameByUserId(idUser: any): Observable<string> {
    return this.userService.getInfoUserById(idUser).pipe(
      map((userData: any) => userData.firstName + ' ' + userData.lastName)
    );
  }

  // Init data of document
  getDocumentById() {
    this.documentService.getDocument(this.data.document.id).pipe(
      switchMap(document => {
        return forkJoin({
          reviewerName: this.getUserNameByUserId(document.reviewer),
          authByName: this.getUserNameByUserId(document.authBy)
        }).pipe(
          map(({ reviewerName, authByName }) => {
            document.reviewer = reviewerName;
            document.authBy = authByName;
            return document;
          })
        );
      })
    ).subscribe(document => {
      this.document = document;
    });
  }


  // -----------INIT FORMS-------------//
  initDocumentUpdateForm() {
    this.documentUpdateForm = this.fb.group({
      updateLevel: ['', Validators.required],
      version: [''],
      updateNotes: [''],
    });
  }

  initParagraphForm() {

    const paragraphFormGroup = this.fb.group({
      title: ['', Validators.required],
      subtitle: [''],
      control: [''],
      idDocumentControl: [''] // Add a FormControl for 'requirement'
    });
    this.paragraphsFormArray.push(paragraphFormGroup);
  }

  initDocumentForm() {
    if (this.data.action === 'Edit' && this.data.document) {
      this.documentForm = this.fb.group({
        id : [this.data.document.id],
        title : [this.data.document.title],
        shortDescription : [this.data.document.shortDescription],
        type : [this.data.document.type],
        classification : [this.data.document.classification],
        reviewer : [this.data.document.reviewer],
        reviewDate : [this.data.document.reviewDate],
        reference : [this.data.document.reference],
        authBy : [this.data.document.authBy],
        creationDate: [this.data.document.creationDate],
        version : [this.data.document.version],
      });
    } else {
      this.documentForm = this.fb.group({
        title : [''],
        shortDescription : [''],
        type : [''],
        classification : [''],
        reviewer : [''],
        reviewDate : [''],
        reference : [''],
        authBy : [''],
        version : [''],
      });
    }
  }

  updateUseFlags(content: boolean, attachment: boolean, url: boolean) {
    this.useContent = content;
    this.useAttachment = attachment;
    this.useURL = url;
  }

  writeDocumentBody(x: any) {
    this.documentBody = x.target.value;
    switch (this.documentBody) {
      case "content":
        this.updateUseFlags(true, false, false);
        break;
      case "attachment":
        this.updateUseFlags(false, true, false);
        break;
      case "url":
        this.updateUseFlags(false, false, true);
        break;
      default:
        this.updateUseFlags(false, false, false);
        break;
    }
  }


  // -----------SUBMIT FORMS-------------//
  addUpdatedDocument(idDocument) {
    let updatedDocumentData = this.documentUpdateForm.value;
    updatedDocumentData.version = this.version;
    updatedDocumentData.updatedBy = this.localStorage.getData("id");
    updatedDocumentData.updateDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    updatedDocumentData.idDocument = idDocument;
    this.documentUpdateService.addDocumentUpdate(updatedDocumentData).subscribe(data => {
      this.addParagraph(idDocument, 'edit');
    });
  }

  updateDocument() {
    let documentData = this.documentForm.value;
    documentData.version = this.version;
    documentData.idAdmin = this.localStorage.getData('id');
    documentData.idUser = 'idUser';
    documentData.idOrg = 'idOrg';
    this.documentService.updateDocument(documentData.id, documentData).subscribe(data => {
      this.addUpdatedDocument(data.id);
      this.documentEdited.emit();
    });
  }
  submitForm(action: string) {
    if (action == "Add") {
      if (this.documentForm.invalid) {
        return;
      }

      const doc = this.documentForm.value; // Get the user object from the form
      doc.version = '1.0';
      doc.idAdmin = this.localStorage.getData('id');
      doc.idUser = 'idUser';
      doc.idOrg = 'idOrg';
      doc.authBy = this.localStorage.getData('id');
      doc.creationDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.documentService.addDocument(doc).subscribe(data => {
        if (this.useContent) {
          this.addParagraph(data.id, 'add');
        }
        this.openSnackBar('Document added successfully', "Close");

        // emit the event to parent component
        this.documentAdded.emit();
      });
      this.dialogRef.close();
    } else if (action === "Edit") {
      this.updateDocument();
      this.dialogRef.close();

    }
  }

  // -----------paragraph functionnalities-------------//

  //TODO: adapter la fonction pour la partie ajout
  onRequirementChange(requirementId: number, index) {
    const selectedControl = this.documentControl.find(control => control.id == requirementId);
    if (selectedControl) {
      // Update interpretation based on the selected requirement
      const formGroup = this.paragraphsFormArray[index];

      // Mettre à jour la valeur du contrôle 'interpretationEdit' avec selectedControl.interpretation
      formGroup.patchValue({
        control: selectedControl.interpretation
      });
    }
  }

  getValueFromFormArray() {
    let formDataArray = [];
    this.paragraphsFormArray.forEach(formGroup => {
      const formData = formGroup.value;
      formDataArray.push(formData);
    });
    return formDataArray;
  }

  addNewParagraph() {
    const newParagraphFormGroup = this.fb.group({
      title: ['', Validators.required],
      subtitle: [''],
      control: [''],
      idDocumentControl: ['']
    });
    this.paragraphsFormArray.push(newParagraphFormGroup);
  }

  addParagraph(documentId, action: string) {
    let paragraphs = this.getValueFromFormArray();
    //formater l'objet avant l'envoie
    paragraphs.forEach(paragraph => {
      delete paragraph.control;
      paragraph.idDocumentControl = parseInt(paragraph.idDocumentControl, 10);
      // Get the user object from the form
      paragraph.idDocument = documentId;
      paragraph.idAdmin = this.localStorage.getData("id");
      paragraph.idUser = "idUser";
      paragraph.idOrg = "idOrg";
    });
    if (action === 'add') {
      this.paragraphService.addParagraph(paragraphs).subscribe(data => {
      });
    } else {
      this.paragraphService.updateParagraph(documentId, paragraphs).subscribe(data => {
        console.log(data);
      });
    }
  }

  deleteParagraph(index: number): void {
    this.paragraphsFormArray.splice(index, 1);
  }
  onClose() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: this.verticalPosition,
    });
  }


  upgradeVerion(level: any): any {
    let version = this.documentForm.value.version;
    console.log(version);
    if (level == "major") {
      version =  (Number(version.split(".")[0]) + 1).toString() + ".0";
    }
    else if (level == "minor") {
      version = version.split(".")[0] + "." + (Number(version.split(".")[1]) + 1).toString();
    }
    return this.version = version;
  }

  //make the previous date from today disabled
  minDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    let month = (today.getMonth() + 1).toString();
    month = month.length === 1 ? '0' + month : month; // Add leading zero if needed
    let day = today.getDate().toString();
    day = day.length === 1 ? '0' + day : day; // Add leading zero if needed
    return `${year}-${month}-${day}`;
  }

}
